import { useMsal } from "@azure/msal-react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logoutIcon from "../../assets/images/Logout.png";
import { SignOutButton } from "../signoutbutton/SignOutButton";
// Style
import "./style.css";
// Constant
export const WelcomeName = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [logOut, setLogout] = useState(false);
  const { instance } = useMsal();
  let logoutRef = useRef();
  useEffect(() => {
    const logoutButtonHide = (event) => {
      if (!logoutRef?.current?.contains(event.target)) {
        setLogout(false);
      } else {
        setLogout(true);
      }
    };
    const currentAccount = instance.getActiveAccount();
    const name = currentAccount.username.substring(
      0,
      currentAccount.username.indexOf(".")
    );
    if (currentAccount) {
      if (name.includes(".")) {
        setUsername(name.charAt(0).toUpperCase() + name.slice(1));
      } else {
        setUsername(name.charAt(0).toUpperCase() + name.slice(1));
      }
    }
    document.addEventListener("mousedown", logoutButtonHide);
    return () => {
      document.removeEventListener("mousedown", logoutButtonHide);
    };
  }, [instance]);
  const handleSignOut = () => {
    console.log("Success logout");
    navigate("/");
    sessionStorage.clear();
    localStorage.clear();
    instance.logout();
  };
  const onCancel = () => {
    setLogout(false);
  };
  return (
    <div className="logout-modal">
      <div className="profileUserName">{username}</div>
      <div
        onClick={() => {
          setLogout(true);
        }}
        className="logout"
      >
        <div className="logout_icon">
          <img src={logoutIcon} width={"250%"} height={"100%"} alt="Logo" />
        </div>
      </div>
      <div ref={logoutRef}>
        {logOut && (
          <SignOutButton
            noButton={onCancel}
            yesButton={handleSignOut}
            text={"Are you sure you want to logout ?"}
          />
        )}
      </div>
    </div>
  );
};
