// Dependency
import React, { useRef } from "react";
import Sidebar from "../../components/sidebar/SideBar";
import CandidateForm from "./CandidateForm";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Button from "../../components/Button";
import { Colors } from "../../components/constants/constants";
// stylesheet
import { useSelector } from "react-redux";
import "./addCandidateStyle.css";

const AddCandidate = () => {
  const childRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleClick = () => {
    childRef.current.onClick();

    // childRef.current.childFunction2();
  };
  const addSuccess = useSelector(
    (state) => state?.NewCandidatesDataReducer?.status
  );
  return (
    <div className="addCandidate">
      {/* <---------SideBar---------> */}
      <Sidebar listName="Candidates"></Sidebar>
      <div className="candidateForm">
        <div className="action">
          <div className="titleContainer">
            {state === null ? (
              <div className="title">Add New Candidate</div>
            ) : (
              <div className="title">Edit Candidate Profile</div>
            )}
          </div>
          <div className="saveandcancle">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <Button name={"Cancel"} backGroundColor={Colors.blue} />
            </div>
            <div onClick={handleClick}>
              <Button
                name={addSuccess ? "Saving...." : "Save"}
                backGroundColor={Colors.blue}
              />
            </div>
          </div>
        </div>
        <div className="Inputfield">
          <CandidateForm candidateDate={state} ref={childRef} />
        </div>
      </div>
    </div>
  );
};
export default AddCandidate;
