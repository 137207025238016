import React from "react";
import "../candidates/candidates.css";
import CandidatesDetails from "../../components/candidates-details/CandidatesDetails";
import NavBar from "../../components/navbar/NavBar";
import Sidebar from "../../components/sidebar/SideBar";
import { useLocation } from "react-router-dom";
const Candidates = () => {
  const { state } = useLocation();
  const location = useLocation();
  return (
    <div>
      <Sidebar></Sidebar>
      {/* <div style={{ position: "fixed", left: "0" }}>
        <NavBar
          // title="Candidates"
          page="/"
        ></NavBar>
      </div> */}
      <CandidatesDetails type={state} />
    </div>
  );
};
export default Candidates;
