import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  loading: false,
  getCustomizeQuiz: false,
  getCustomizeQuizData: [],
  totalNoOfQuestion: null,
  totaltimeallocation: null,
  postCustomizeQuiz: false,
  questionLoading: false,
  userData: [],
  questionTypeLoading: false,
  questionTypeData: [],
  questionList: [],
  questionActivated: false,
  questionDeactivated: false,
  questionAddedStatus: false,
  questionEditedStatus: false,
};
// Reducer for Types of Question in Peritculer catogery--------------->
const loadTestCategoryStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionTypeLoading: true,

    // errorMessage: action.payload.errorMessage,
  };
};
const loadTestCategorySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionTypeLoading: false,

    questionTypeData: action.payload.questionTypeData,
  };
};
const loadTestCategoryFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionTypeLoading: false,
  };
};
// Bulk Upload Test Question
const loadBulkQuestionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
  };
};
const loadBulkQuestionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
  };
};
const loadBulkQuestionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
  };
};
// Reducer to load QuestionTable
const loadQuestionTableStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionLoading: true,
  };
};
const loadQuestionTableSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionLoading: false,
    questionList: action.payload.data,
  };
};
const loadQuestionTableFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionLoading: false,
  };
};
// Reducer for get Custmize question's
const loadGetCustomizeQuizStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getCustomizeQuiz: true,
  };
};
const loadGetCustomizeQuizSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getCustomizeQuiz: false,
    getCustomizeQuizData: action.payload.data,
    totalNoOfQuestion: action.payload.data?.totalQuestions,
    totaltimeallocation: action.payload.data?.quizTime,
  };
};
const loadGetCustomizeQuizFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    getCustomizeQuiz: false,
  };
};
// Reducer for post Custmize question's
const loadPostCustomizeQuizStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    postCustomizeQuiz: false,
  };
};
const loadPostCustomizeQuizSuccess = (state = INITIAL_STATE, action) => {
  console.log(action.payload, "Success", loadPostCustomizeQuizSuccess);
  return {
    ...state,
    postCustomizeQuiz: true,
  };
};
const loadPostCustomizeQuizFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    postCustomizeQuiz: false,
  };
};
// Reducer for Activate Question
const loadActivateQuestionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionActivated: true,
  };
};
const loadActivateQuestionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionActivated: false,
  };
};
const loadActivateQuestionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionActivated: false,
  };
};
// Reducer for De activate Question
const loadDeactivateQuestionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionDeactivated: true,
  };
};
const loadDeactivateQuestionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionDeactivated: false,
  };
};
const loadDeactivateQuestionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionDeactivated: false,
  };
};
// Reducer for Adding new Question----------------->
const addNewQuestionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionAddedStatus: false,
  };
};
const addNewQuestionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionAddedStatus: true,
  };
};
const addNewQuestionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionAddedStatus: false,
  };
};
// Reducer for Editing existing Question--------------------->
const editQuestionStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionEditedStatus: false,
  };
};
const editQuestionSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionEditedStatus: true,
  };
};
const editQuestionFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    questionEditedStatus: false,
  };
};
const HANDLERS = {
  [Types.LOAD_TEST_CATEGORY_START]: loadTestCategoryStart,
  [Types.LOAD_TEST_CATEGORY_SUCCESS]: loadTestCategorySuccess,
  [Types.LOAD_TEST_CATEGORY_FAILURE]: loadTestCategoryFailure,
  // Handler for Bulk upload File
  [Types.LOAD_BULK_QUESTION_START]: loadBulkQuestionStart,
  [Types.LOAD_BULK_QUESTION_SUCCESS]: loadBulkQuestionSuccess,
  [Types.LOAD_BULK_QUESTION_FAILURE]: loadBulkQuestionFailure,
  // load QuestionTable
  [Types.LOAD_QUESTION_TABLE_START]: loadQuestionTableStart,
  [Types.LOAD_QUESTION_TABLE_SUCCESS]: loadQuestionTableSuccess,
  [Types.LOAD_QUESTION_TABLE_FAILURE]: loadQuestionTableFailure,
  // Get Custmize Qustion type
  [Types.LOAD_GET_CUSTOMIZE_QUIZ_START]: loadGetCustomizeQuizStart,
  [Types.LOAD_GET_CUSTOMIZE_QUIZ_SUCCESS]: loadGetCustomizeQuizSuccess,
  [Types.LOAD_GET_CUSTOMIZE_QUIZ_FAILURE]: loadGetCustomizeQuizFailure,
  // Post Custmize Qustion type
  [Types.LOAD_POST_CUSTOMIZE_QUIZ_START]: loadPostCustomizeQuizStart,
  [Types.LOAD_POST_CUSTOMIZE_QUIZ_SUCCESS]: loadPostCustomizeQuizSuccess,
  [Types.LOAD_POST_CUSTOMIZE_QUIZ_FAILURE]: loadPostCustomizeQuizFailure,
  // Handler for Activate Question
  [Types.LOAD_ACTIVATE_QUESTION_START]: loadActivateQuestionStart,
  [Types.LOAD_ACTIVATE_QUESTION_SUCCESS]: loadActivateQuestionSuccess,
  [Types.LOAD_ACTIVATE_QUESTION_FAILURE]: loadActivateQuestionFailure,
  // Handler for De Activate Question
  [Types.LOAD_DEACTIVATE_QUESTION_START]: loadDeactivateQuestionStart,
  [Types.LOAD_DEACTIVATE_QUESTION_SUCCESS]: loadDeactivateQuestionSuccess,
  [Types.LOAD_DEACTIVATE_QUESTION_FAILURE]: loadDeactivateQuestionFailure,
  // Handler for Adding new Question
  [Types.ADD_NEW_QUESTION_START]: addNewQuestionStart,
  [Types.ADD_NEW_QUESTION_SUCCESS]: addNewQuestionSuccess,
  [Types.ADD_NEW_QUESTION_FAILURE]: addNewQuestionFailure,
  // Handler for edit the Question
  [Types.EDIT_QUESTION_START]: editQuestionStart,
  [Types.EDIT_QUESTION_SUCCESS]: editQuestionSuccess,
  [Types.EDIT_QUESTION_FAILURE]: editQuestionFailure,
};

export const testDataReducer = createReducer(INITIAL_STATE, HANDLERS);
