import React, { useState, useRef } from "react";
// Component
import Sidebar from "../../components/sidebar/SideBar";
import Button from "../../components/Button";
import { Creators } from "./store/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
// Constant

import { Colors } from "../../components/constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faChevronLeft,
  faEdit,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
// Upload Bulk candidate
import {
  AddQuestionContainer,
  AddQuestionTopBar,
  AddQuestionHeader,
  AddQuestionButtonContainer,
  UploadfileContainer,
  UploadfileHeading,
  InputUploadFile,
  UploadButton,
  Tamplate,
  InputFileSelector,
} from "./AddQuestion.Styled";
const AddBulkQuestion = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(true);
  const dispatch = useDispatch();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // handleSubmission();
  };
  // const handleSubmission = () => {
  //   const formData = new FormData();
  //   formData.append("File", selectedFile);
  // };

  const onSave = () => {
    const fileUpload = new FormData();
    fileUpload.append("csvFile", selectedFile);
    fileUpload.append("negativePoints", "0.0");

    // return;
    const payload = {
      fileUpload,
    };
    if (fileUpload) {
      setIsFileSelected(true);
      dispatch(Creators.loadBulkQuestion(payload));
    } else {
      setIsFileSelected(false);
    }
  };
  return (
    <AddQuestionContainer>
      <Sidebar />
      <AddQuestionTopBar>
        <AddQuestionHeader>
          <FontAwesomeIcon
            style={{ cursor: "pointer", paddingRight: "10px" }}
            icon={faChevronLeft}
            onClick={() => {
              navigate(-1);
            }}
          ></FontAwesomeIcon>
          Add Bulk Question
        </AddQuestionHeader>
        <Tamplate>
          <a
            className="Resume-viewer-container"
            href={
              "https://hrportal.panorbitprojects.com/media_cdn/Bulk_Question_Upload_sample.xlsx"
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button
              // icon={faDownload}
              name={"Download format"}
              backGroundColor={Colors.blue}
              width={"180px"}
              height={"40px"}
            />
          </a>
        </Tamplate>
      </AddQuestionTopBar>
      <UploadfileContainer>
        <UploadfileHeading>
          {" "}
          Select XLSX <span style={{ color: "red" }}>*</span>
        </UploadfileHeading>
        <InputUploadFile>
          <InputFileSelector
            type="file"
            accept={".xlsx"}
            // onChange={onFileChange}
            onChange={changeHandler}
          ></InputFileSelector>
        </InputUploadFile>
        <div
          style={{
            display: isFileSelected ? "none" : "",
            color: "red",
            fontSize: "14px",
          }}
        >
          Required
        </div>
        <UploadButton onClick={onSave}>
          <Button
            name={"Upload"}
            // icon={faPlus}
            backGroundColor={Colors.buttonColor}
            width={"500px"}
            height={"40px"}
          />
        </UploadButton>
      </UploadfileContainer>
    </AddQuestionContainer>
  );
};

export default AddBulkQuestion;
