import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  candidateData: [],
  loading: false,
  errorMessage: "",
};
// Load the Candidate Profile data
const loadCandidatesProfileDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
// Update the state after getting successfully response from API
const loadCandidatesProfileDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    candidateData: action.payload.data,
  };
};
// Update the state after getting bad response from API
const loadCandidatesProfileDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
// Put Assignment1 Data
const loadAssignmentFirstDetailsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
// Update the state after getting successfully response from API
const loadAssignmentFirstDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // candidateData: action.payload.data,
  };
};
// Update the state after getting bad response from API
const loadAssignmentFirstDetailsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
//
const loadAssignmentSecondDetailsStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
// Update the state after getting successfully response from API
const loadAssignmentSecondDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // candidateData: action.payload.data,
  };
};
// Update the state after getting bad response from API
const loadAssignmentSecondDetailsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
const HANDLERS = {
  // For Candidate data
  [Types.LOAD_CANDIDATES_PROFILE_DATA_START]: loadCandidatesProfileDataStart,
  [Types.LOAD_CANDIDATES_PROFILE_DATA_SUCCESS]:
    loadCandidatesProfileDataSuccess,
  [Types.LOAD_CANDIDATES_PROFILE_DATA_FAILURE]:
    loadCandidatesProfileDataFailure,
  // Assignment1
  [Types.LOAD_ASSIGNMENT_FIRST_DETAILS_START]: loadAssignmentFirstDetailsStart,
  [Types.LOAD_ASSIGNMENT_FIRST_DETAILS_SUCCESS]:
    loadAssignmentFirstDetailsSuccess,
  [Types.LOAD_ASSIGNMENT_FIRST_DETAILS_FAILURE]:
    loadAssignmentFirstDetailsFailure,
  // Assignment2
  [Types.LOAD_ASSIGNMENT_SECOND_DETAILS_START]:
    loadAssignmentSecondDetailsStart,
  [Types.LOAD_ASSIGNMENT_SECOND_DETAILS_SUCCESS]:
    loadAssignmentSecondDetailsSuccess,
  [Types.LOAD_ASSIGNMENT_SECOND_DETAILS_FAILURE]:
    loadAssignmentSecondDetailsFailure,
};
export const CandidatesProfileReducer = createReducer(INITIAL_STATE, HANDLERS);
