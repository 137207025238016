import { createActions } from "reduxsauce";
const { Types, Creators } = createActions({
  loadCandidatesProfileData: ["payload"],
  loadCandidatesProfileDataStart: ["payload"],
  loadCandidatesProfileDataSuccess: ["payload"],
  loadCandidatesProfileDataFailure: ["payload"],
  // Assignment1
  loadAssignmentFirstDetails: ["payload"],
  loadAssignmentFirstDetailsStart: ["payload"],
  loadAssignmentFirstDetailsSuccess: ["payload"],
  loadAssignmentFirstDetailsFailure: ["payload"],
  // Assignment 2
  loadAssignmentSecondDetails: ["payload"],
  loadAssignmentSecondDetailsStart: ["payload"],
  loadAssignmentSecondDetailsSuccess: ["payload"],
  loadAssignmentSecondDetailsFailure: ["payload"],
});

export { Types, Creators };
