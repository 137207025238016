// dependency
import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

// Component and Pages
import NewUser from "./pages/newuser/NewUser";
import { PageLayout } from "./pages/pagelayout/PageLayout";
import Users from "./pages/users/Users";
import NotFound from "./pages/notfound/notFound";
import Candidates from "./pages/candidates/Candidates";
import CandidateProfile from "./components/CandidateProfile/CandidateProfile";
import AddCandidate from "./pages/addCandidate/addCandidate";
import { SignInButton } from "./components/signinbutton/SignInButton";
import FileUploader from "./pages/fileupload/FileUploader";
import TestHomeScreen from "./pages/tests/TestHomeScreen";
import TestQuestionType from "./pages/tests/TestQuestionType";
import CustomizeQuiz from "./pages/tests/CustomizeQuiz";
import TestQuestionTable from "./pages/tests/TestQuestionTable";
import AddBulkQuestion from "./pages/tests/AddBulkQuestion";
import AddNewQuestions from "./pages/tests/AddNewQuestions";
// Main Function start
export default function AllRoute() {
  const isAdmin = sessionStorage.getItem("admin");
  const isAuthenticated = useIsAuthenticated();
  const isActive = sessionStorage.getItem("isActive");
  const isStaff = sessionStorage.getItem("isStaff");

  return (
    <div>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<PageLayout />} />
            {isAdmin === "true" && isActive === "true" && (
              <>
                <Route path="/" element={<PageLayout />} />
                {/* <------------------------------Test Routing------------------------------> */}
                <Route path="/test" element={<TestHomeScreen />} />
                {/* <----------------Test Question Type------------------> */}
                <Route
                  path="/test/question-type"
                  element={<TestQuestionType />}
                />
                {/* <-----------------Test Question Table----------------> */}
                <Route
                  path="/test/question-type/question-table"
                  element={<TestQuestionTable />}
                />
                {/* <----------------CustomizeQuiz----------------> */}
                <Route
                  path="/test/question-type/customize-quiz"
                  element={<CustomizeQuiz />}
                ></Route>
                {/* <---------------------Test Bulk upload-------------------> */}
                <Route
                  path="/test/question-type/bulk-upload"
                  element={<AddBulkQuestion />}
                ></Route>
                {/* <---------------------Test Single Question-------------------> */}
                <Route
                  path="/test/question-type/add-new-question"
                  element={<AddNewQuestions />}
                ></Route>
                {/* <---------------------Edit Single Question--------------------> */}
                <Route
                  path="/test/question-type/edit-question"
                  element={<AddNewQuestions />}
                ></Route>
                {/* <-----------------User Routing-------------------> */}
                <Route path="/users" element={<Users />} />
                <Route path="/users/create" element={<NewUser />} />
                <Route path="/users/edit" element={<NewUser />} />
              </>
            )}
            {isStaff === "true" && isActive === "true" && (
              <>
                <Route path="/" element={<PageLayout />} />
                <Route
                  path="/candidates/new-candidate"
                  element={<Candidates />}
                />
                <Route
                  path="/candidates/old-candidate"
                  element={<Candidates />}
                />
                <Route path="/candidates/upload" element={<FileUploader />} />
                <Route
                  path="/candidates/in-progress"
                  element={<Candidates />}
                />
                <Route
                  path="/candidates/new-candidate/candidate-profile/edit-candidate/"
                  element={<AddCandidate />}
                />
                <Route
                  path="/candidates/in-progress/candidate-profile/edit-candidate/"
                  element={<AddCandidate />}
                />
                <Route
                  path="/candidates/old-candidate/candidate-profile/edit-candidate/"
                  element={<AddCandidate />}
                ></Route>
                <Route
                  path="/candidates/new-candidate/candidate-profile/"
                  element={<CandidateProfile />}
                />
                <Route
                  path="/candidates/in-progress/candidate-profile/"
                  element={<CandidateProfile />}
                />
                <Route
                  path="/candidates/old-candidate/candidate-profile/"
                  element={<CandidateProfile />}
                />
                <Route path="/candidates" element={<Candidates />} />
                <Route
                  path="/candidates/upload"
                  element={<FileUploader />}
                ></Route>
                <Route
                  path="/candidates/new-candidate/add-candidate"
                  element={<AddCandidate />}
                />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <>
            {" "}
            <Route path="/" element={<SignInButton />}></Route>
            <Route path="*" element={<Navigate to="/" />}></Route>
          </>
        )}
      </Routes>
    </div>
  );
}
