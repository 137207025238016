export const Colors = {
  white: "#FFFFFF",
  black: "#000000",
  red: "#FF0000",
  gray: "#C7C4C0",
  blue: "#093a58",
  activeColor: "#FFFFFF",
  filterBackgroundColor: "#f8f8f8",
  tableTextColor: "#595959",
  tableHeadingColor: "#6e6e6e",
  filterAndModalColor: "#f8f8f8",
  buttonColor: "#093a58",
  tableBorderColor: "rgb(237, 237, 240)",
};
