import { useMsal } from "@azure/msal-react";
import "../signoutbutton/signoutbutton.css";

// Component
import Card from "../card/Card";
import Button from "../Button";
// Constant
import { Colors } from "../constants/constants";
import { useNavigate } from "react-router";
import { useState } from "react";
import ConfomCard from "../card/ConfomCard";
export const SignOutButton = ({ yesButton, noButton, text }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  // const handleSignOut = () => {
  //   navigate("/");
  //   sessionStorage.clear();
  //   localStorage.clear();
  //   instance.logout();
  // };
  const [logout, setLogout] = useState(false);
  const handleNoSignOut = () => {
    // navigate("/");
    // setLogout(true);
  };
  return (
    <div
      className="logout-pop-up-container"
      // style={{ display: logout ? "none" : "" }}
    >
      <div className="logout-pop-up">
        <ConfomCard
          cardText={text}
          listNo={"2"}
          width={"500px"}
          borderRadius={"10px"}
          height={"200px"}
          topBorder={"4px"}
          bottomBorder={"0px"}
          leftBorder={"0px"}
          rightBorder={"0px"}
          backgroudColor={Colors.white}
        />
        <div className="confirmation-button">
          <div className="confirm-no" onClick={noButton}>
            <Button
              name={"No"}
              backGroundColor={Colors.blue}
              width={"100px"}
              height={"30px"}
            />
          </div>
          <div className="confirm-yes" onClick={yesButton}>
            <Button
              name={"Yes"}
              backGroundColor={Colors.blue}
              width={"100px"}
              height={"30px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
