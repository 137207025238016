export const baseURL = "https://hrportal.panorbitprojects.com/api";
// export const baseURL = "http://192.168.0.148/api";
export const apiEndPoints = {
  login: `${baseURL}/adminlogin/`,
  readUser: `${baseURL}/ReadUser`,
  readCandidates: `${baseURL}/listcandidates/`,
  createUser: `${baseURL}/createUser`,
  activateUser: `${baseURL}/ActivateUser`,
  deactivateUser: `${baseURL}/DeactivateUser`,
  editUser: `${baseURL}/UpdateUser`,
  newUser: `${baseURL}/createuser/`,
  addNewCandidate: `${baseURL}/addsinglecandidate/`,
  CandidateDetails: `${baseURL}/candidatedetails`,
  updateCandidateDetails: `${baseURL}/updatecandidate`,
  sendMailL: `${baseURL}/sendmail/`,
  downloadFile: `${baseURL}/export`,
  assignment1: `${baseURL}/assignment1details`,
  assignment2: `${baseURL}/assignment2details`,
  testCategory: `${baseURL}/questiontype/`,
  bulkTestQuestion: `${baseURL}/importQuestions/`,
  qustionList: `${baseURL}/questionDetails/`,
  custamizeQuiz: `${baseURL}/createquestioncollection/`,
  getCustamize: `${baseURL}/getquestioncollection`,
  postCustamize: `${baseURL}/setquestioncollection/`,
  activateQuestion: `${baseURL}/activatequestion/`,
  deactivateQuestion: `${baseURL}/deactivatequestion/`,
  addNewQuestion: `${baseURL}/AddQuestion/`,
  updateQuestion: `${baseURL}/updatequestion/`,
};
