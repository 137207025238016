import React, { useState, useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import setAccessTokenToHeader from "./axios/helper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./pages/pagelayout/style.css";
import { Creators as loginCreators } from "./pages/pagelayout/store/action";
import AllRoutes from "./routes";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import { loginClientID, loginRedirectURL, loginSystemID } from "./appconstant";
function App() {
  const isAdmin = sessionStorage.getItem("admin");
  const [loginDetails, setLoginDetails] = useState({});
  const [width, setWidth] = useState(window.innerWidth);
  const details = useSelector((state) => state.loginReducer);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const token = sessionStorage.getItem("token");
  setAccessTokenToHeader(token);
  const dispatch = useDispatch();
  useEffect(() => {
    //
    dispatch(loginCreators.login(loginDetails));
  }, [loginDetails]);
  const pca = new PublicClientApplication({
    auth: {
      clientId: loginClientID,
      authority: loginSystemID,
      redirectUri: loginRedirectURL,
    },
  });

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_START) {
      sessionStorage.setItem("logInStart", true);
    }
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      setLoginDetails({
        id_token: event.payload.idToken,
        audience: event.payload.idTokenClaims.aud,
        tenant_id: event.payload.idTokenClaims.tid,
        email_id: event.payload.idTokenClaims.preferred_username,
      });

      pca.setActiveAccount(event.payload.account);
    }
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div>
      {width < 1024 ? (
        <div></div>
      ) : (
        <MsalProvider instance={pca}>
          <AllRoutes></AllRoutes>
          <ToastContainer />
        </MsalProvider>
      )}
    </div>
  );
}

export default App;
