import React, { useEffect, useState } from "react";
// Components
import Sidebar from "../../components/sidebar/SideBar";
import Card from "../../components/card/Card";
import Button from "../../components/Button";
import { Creators } from "./store/action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import UseClickOutside from "../../components/clickoutside/ClickOutside";
// Styled comoponent
import {
  QuestionTableContainer,
  QuestionTableTopHeading,
  TestQuestionTableContainer,
  QuestionTable,
  QuestionTableRow,
  QuestionTableHeading,
  QuestionTableDateHeading,
  QuestionTableViewHeading,
  QuestionTableActionHeading,
  QuestionTableQuestionHeading,
  //
  QuestionTableData,
  QuickView,
  //
  QuestionQustionTableData,
  QuestionDateTableData,
  QuestionActionTableData,
  //
  TestQuestionContainer,
  TestQuestionHeading,
  TestQuestionAction,
  Modal,
} from "./TestQuestionTable.Styled";
// Styled component for Topbar
import { TabContainer } from "../../components/candidates-details/InProgress.styled";

//FontAwesomeIcon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faChevronLeft,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
// Constant
import { Colors } from "../../components/constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { QuickViewContainer } from "../../components/quickviewmodal/QuickViewModal.Styled";
import QuickViewModal from "../../components/quickviewmodal/QuickViewModal";

//
const actions = [
  { id: 1, value: "Edit" },
  {
    id: 2,
    value: "Deactivate",
  },
];
//

const TestQuestionTable = () => {
  const [activeQuestion, setActiveQuestion] = useState(true);
  const [localId, setLocalId] = useState(null);
  const [showActionModal, setShowActionModal] = useState(false);
  const [isQuickView, setIsQuickView] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quickViewQuestion, setQuickViewQuestion] = useState("");
  const [quickViewAnswer, setQuickViewAnswer] = useState();
  // log(state, "location in Table");
  const addSuccess = useSelector(
    (state) => state?.testDataReducer?.questionList
  );
  const loading = useSelector(
    (state) => state?.testDataReducer?.questionLoading
  );
  // Re Rander the data after getting sucesses while activating or deactivating data
  const questionActivated = useSelector(
    (state) => state?.testDataReducer?.questionActivated
  );
  const questionDeactivated = useSelector(
    (state) => state?.testDataReducer?.questionDeactivated
  );
  // Payload
  const payload = {
    quizName: state.quizName,
    status: activeQuestion ? "Active" : "Inactive",
    catagory: state.type,
  };

  useEffect(() => {
    dispatch(Creators.loadQuestionTable(payload));
  }, [activeQuestion, questionActivated, questionDeactivated]);

  // Deactivate Question
  const deactivate = (quizId) => {
    dispatch(Creators.loadDeactivateQuestion(quizId));
  };
  // Activate Question
  const activate = (quizId) => {
    dispatch(Creators.loadActivateQuestion(quizId));
  };
  // Toggle popup
  const handleMouseOver = (question, ans) => {
    setIsQuickView(true);
    setQuickViewQuestion(question);
    setQuickViewAnswer(ans);
  };
  const CanclePopup = (state) => {
    setIsQuickView(false);
  };
  return (
    <div>
      <QuestionTableContainer>
        {/* <------------------Sidebar-----------------------> */}
        <Sidebar />
        {/* <-----------------------Top bar tab------------------> */}
        <TabContainer>
          <div
            onClick={() => {
              setActiveQuestion(true);
            }}
          >
            <Card
              width={"300px"}
              height={"50px"}
              cardText={"Active Questions"}
              borderRadius={"00px 0px 0px 0px;"}
              bottomBorder={"0px"}
              topBorder={activeQuestion ? "4px" : "0px"}
              decoration={activeQuestion ? "underline" : ""}
              leftBorder={"0px"}
              rightBorder={"0px"}
              backgroudColor={
                activeQuestion
                  ? Colors.activeColor
                  : Colors.filterBackgroundColor
              }
              opacity={activeQuestion ? "1" : "0.5"}
            />
          </div>
          <div
            onClick={() => {
              setActiveQuestion(false);
            }}
          >
            <Card
              width={"300px"}
              height={"50px"}
              cardText={"Inactive Questions"}
              borderRadius={"0px 0px 0px 0px;"}
              bottomBorder={"0px"}
              topBorder={activeQuestion ? "0px" : "4px"}
              decoration={activeQuestion ? "" : "underline"}
              leftBorder={"0px"}
              rightBorder={"0px"}
              backgroudColor={
                activeQuestion
                  ? Colors.filterBackgroundColor
                  : Colors.activeColor
              }
              opacity={activeQuestion ? "0.5" : "1"}
            />
          </div>
        </TabContainer>

        <TestQuestionContainer>
          {/* <----------------Create New Questions---------------> */}
          <QuestionTableTopHeading>
            <TestQuestionHeading>
              <FontAwesomeIcon
                style={{ cursor: "pointer", paddingRight: "10px" }}
                icon={faChevronLeft}
                onClick={() => {
                  navigate(-1);
                }}
              ></FontAwesomeIcon>
              Questions Table
            </TestQuestionHeading>
            <TestQuestionAction>
              <div
                // className="add-new-user"
                onClick={() => {
                  navigate("/test/question-type/add-new-question", {
                    state: { type: state.type, QuizID: "" },
                  });
                }}
                style={{ display: activeQuestion ? "" : "none" }}
                // onClick={addNewUser}
              >
                <Button
                  name={"New Question"}
                  icon={faPlus}
                  backGroundColor={Colors.blue}
                  width={"160px"}
                  height={"40px"}
                />
              </div>
            </TestQuestionAction>
          </QuestionTableTopHeading>
          <TestQuestionTableContainer>
            <QuestionTable>
              {/* <-----------------Table Heading---------------------> */}
              <QuestionTableRow>
                <QuestionTableQuestionHeading>
                  QUESTIONS
                </QuestionTableQuestionHeading>
                <QuestionTableDateHeading>
                  CREATED DATE
                </QuestionTableDateHeading>
                {/* <QuestionTableViewHeading>VIEW</QuestionTableViewHeading> */}
                <QuestionTableActionHeading>ACTION</QuestionTableActionHeading>
              </QuestionTableRow>
              {/* <-----------------Table Data---------------------> */}
              {/* {loading ? (
          <div className="loader"></div>
        ) : questionType.length > 0 ? (
          <></>
        )} */}
              {loading ? (
                <div className="loader"></div>
              ) : addSuccess.length === 0 ? (
                <div>No record found</div>
              ) : (
                addSuccess.map((val, key) => {
                  return (
                    <QuestionTableRow key={key}>
                      <QuestionQustionTableData>
                        {val.question}
                      </QuestionQustionTableData>
                      <QuestionDateTableData>
                        {moment(val.createdTime).format("YYYY-MM-DD")}
                      </QuestionDateTableData>

                      <QuestionActionTableData>
                        <div
                          onClick={() => {
                            handleMouseOver(val.question, val.ansOption);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ cursor: "pointer" }}
                          ></FontAwesomeIcon>
                        </div>
                        <div>
                          <div>
                            <FontAwesomeIcon
                              icon={faEllipsisH}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setLocalId(val.questionID);
                                setShowActionModal(!showActionModal);
                              }}
                            ></FontAwesomeIcon>
                            <UseClickOutside
                              onClickOutside={() => {
                                setShowActionModal(false);
                              }}
                            >
                              {showActionModal &&
                                localId === val.questionID && (
                                  <div
                                    className=""
                                    style={{
                                      position: "absolute",
                                      marginLeft: "25px",
                                      zIndex: 1,
                                    }}
                                    onClick={() => {
                                      setShowActionModal(false);
                                    }}
                                  >
                                    {/* <----------------PopUp Modal-------------> */}
                                    <Modal>
                                      {activeQuestion ? (
                                        <div style={{ cursor: "pointer" }}>
                                          {actions.map((option, id) => (
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                if (option.value === "Edit") {
                                                  navigate(
                                                    "/test/question-type/edit-question",
                                                    {
                                                      state: {
                                                        type: val.catagory,
                                                        QuizID: val.questionID,
                                                        question: val.question,
                                                        correctAns:
                                                          val.correctAns,
                                                        ansOption:
                                                          val.ansOption,
                                                        catagory:
                                                          state.quizName,
                                                      },
                                                    }
                                                  );
                                                } else if (
                                                  option.value === "Deactivate"
                                                ) {
                                                  deactivate(val.questionID);
                                                  // updatedData(!updatedDataValue);
                                                  // deactivateUser(item.id);
                                                }
                                              }}
                                            >
                                              {option.value}
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            activate(val.questionID);
                                            // updatedData(!updatedDataValue);
                                            // activateUser(item.id);
                                          }}
                                        >
                                          Activate
                                        </div>
                                      )}
                                    </Modal>
                                    {/* <--------------------^^^^^^^^-------------------> */}
                                  </div>
                                )}
                            </UseClickOutside>
                          </div>
                        </div>
                      </QuestionActionTableData>
                    </QuestionTableRow>
                  );
                })
              )}
            </QuestionTable>
          </TestQuestionTableContainer>
        </TestQuestionContainer>
      </QuestionTableContainer>
      {/* <---------------------Quick View Question-------------------------> */}
      {isQuickView && (
        <QuickView>
          <UseClickOutside
            onClickOutside={() => {
              setIsQuickView(false);
            }}
          >
            <QuickViewModal
              question={quickViewQuestion}
              answer={quickViewAnswer}
              onclickFunc={CanclePopup}
            />
          </UseClickOutside>
        </QuickView>
      )}
    </div>
  );
};

export default TestQuestionTable;
