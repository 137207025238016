import React, { useEffect, useState } from "react";
// Router Dom
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableHead,
  TableBody,
  TableData,
  TableRow,
  TableHeading,
  Modal,
  TableEmailData,
} from "./Table.Styled";
//
import { Creators } from "../../pages/users/store/action";
// FontAwesom Library's
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import UseClickOutside from "../clickoutside/ClickOutside";
// Main Finction
const UserTable = ({ data, updatedData, updatedDataValue, User_Type }) => {
  //
  const activeloading = useSelector(
    (state) => state.userDataReducer.activeLoading
  );
  const deactiveloading = useSelector(
    (state) => state?.userDataReducer?.dactiveLoading
  );

  // SetState variable to Show the modal
  const [showActionModal, setShowActionModal] = useState(false);
  const [localId, setLocalId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // List of Action that Admin or user can take for Active user

  const actions = [
    { id: 1, value: "Edit" },
    {
      id: 2,
      value: "Deactivate",
    },
  ];
  //

  // Function to activate User

  const deactivateUser = (userId) => {
    dispatch(Creators.loadDeactivateUser(userId));
  };
  // Function to Deactive User
  const activateUser = (userId) => {
    dispatch(Creators.loadActivateUser(userId));
  };
  useEffect(() => {}, [activeloading, deactiveloading]);
  return (
    <>
      <Table>
        {/* <------------Table Head------------> */}
        <TableHead>
          <TableRow>
            {/* <----------Table heading-------> */}
            <TableHeading>USER NAME</TableHeading>
            <TableHeading>EMAIL</TableHeading>
            <TableHeading>ROLE</TableHeading>

            <TableHeading>ACTION</TableHeading>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <--------------Table Value------------> */}
          {data.map((item, id) => {
            return (
              <TableRow key={id.toString()}>
                <TableData>{item.fullName}</TableData>
                <TableEmailData>{item.email}</TableEmailData>
                <TableData>{item.userType}</TableData>

                <TableData>
                  <div
                    style={{ position: "relative" }}
                    // onClick={() => {
                    //   setLocalId(item.id);
                    //   setShowActionModal(!showActionModal);
                    // }}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      onClick={() => {
                        setLocalId(item.id);
                        setShowActionModal(!showActionModal);
                      }}
                      style={{ cursor: "pointer" }}
                    ></FontAwesomeIcon>
                    {showActionModal && localId === item.id && (
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          marginLeft: "25px",
                          zIndex: 1,
                        }}
                        // onClick={() => {
                        //   setShowActionModal(false);
                        // }}
                      >
                        {/* <----------------PopUp Modal-------------> */}
                        <UseClickOutside
                          onClickOutside={() => {
                            setShowActionModal(false);
                          }}
                        >
                          <Modal>
                            {User_Type ? (
                              <div style={{ cursor: "pointer" }}>
                                {actions.map((option, id) => (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (option.value === "Edit") {
                                        navigate(`/users/edit`, {
                                          state: { data: item },
                                        });
                                      } else if (
                                        option.value === "Deactivate"
                                      ) {
                                        // updatedData(!updatedDataValue);
                                        deactivateUser(item.id);
                                      }
                                    }}
                                  >
                                    {option.value}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  // updatedData(!updatedDataValue);
                                  activateUser(item.id);
                                }}
                              >
                                Activate
                              </div>
                            )}
                          </Modal>
                        </UseClickOutside>

                        {/* <--------------------^^^^^^^^-------------------> */}
                      </div>
                    )}
                  </div>
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
export default UserTable;
