import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  loading: false,
  message: "",
  errorMessage: "",
};

const updateUserStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const updateUserSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    message: action.payload.message,
  };
};
const updateUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    errorMessage: action.payload.errorMessage,
  };
};
const HANDLERS = {
  [Types.UPDATE_USER_START]: updateUserStart,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
};
export const userUpdateReducer = createReducer(INITIAL_STATE, HANDLERS);
