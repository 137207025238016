import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
// Styled Component
import {
  QuestionTypeContainer,
  CategoryTopBar,
  TestButtonContainer,
  CategoryHeading,
  QuestionTypes,
  NoRecored,
  AddQuestionDropdown,
  OptionIteam,
} from "./TestQuestionType.styled";

// Componenet
import Sidebar from "../../components/sidebar/SideBar";
import CatogeryCard from "../../components/card/CatogeryCard";
import Button from "../../components/Button";
import { Colors } from "../../components/constants/constants";
import UseClickOutside from "../../components/clickoutside/ClickOutside";
// FontsIcon
import { faPlus, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
//FontAwesomeIcons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//
import { Creators } from "./store/action";
//
import { useDispatch, useSelector } from "react-redux";
//mail
const TestQuestionType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  useEffect(() => {
    dispatch(Creators.loadTestCategory(state.type));
  }, []);
  // Data from reducer
  const loading = useSelector(
    (state) => state.testDataReducer.questionTypeLoading
  );
  const questionType = useSelector(
    (state) => state.testDataReducer.questionTypeData
  );
  //  Dropdown Option's
  const DropDownOPtion = [
    { id: 1, name: "Add Single" },
    { id: 2, name: "Add Multiple" },
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <QuestionTypeContainer>
      {/* <-----------SideBar--------------> */}
      <Sidebar />
      {/* <--------------Card Topbar---------------> */}
      <CategoryTopBar>
        <CategoryHeading>
          <div>
            <FontAwesomeIcon
              style={{ cursor: "pointer", paddingRight: "10px" }}
              icon={faChevronLeft}
              onClick={() => {
                navigate(-1);
              }}
            ></FontAwesomeIcon>
          </div>
          <div>{state.type}</div>
        </CategoryHeading>
        {/* <----------------Button container-----------------> */}
        <TestButtonContainer>
          {/* <----------------Customize Button Quiz-----------------> */}
          <div
            onClick={() => {
              navigate("/test/question-type/customize-quiz", {
                state: { type: state.type },
              });
            }}
          >
            <Button
              name={"Customize Quiz"}
              backGroundColor={Colors.buttonColor}
            />
          </div>
          {/* <----------------Adding New Questions Button-----------------> */}
          <UseClickOutside
            onClickOutside={() => {
              setIsDropdownOpen(false);
            }}
          >
            <div
              onClick={() => {
                setIsDropdownOpen(!isDropdownOpen);
              }}
            >
              <Button
                name={"New Questions"}
                icon={faPlus}
                backGroundColor={Colors.buttonColor}
              />

              <AddQuestionDropdown
                style={{ display: isDropdownOpen ? "" : "none" }}
              >
                {DropDownOPtion.map((item, id) => (
                  <OptionIteam
                    // style={{ cursor: "pointer" }}
                    key={id.toString()}
                    onClick={() => {
                      if (item.name === "Add Multiple") {
                        setIsDropdownOpen(false);
                        navigate(`/test/question-type/bulk-upload`);
                      } else if (item.name === "Add Single") {
                        navigate("/test/question-type/add-new-question", {
                          state: { type: state.type, QuizID: "" },
                        });
                      }
                    }}
                  >
                    {item.name}
                  </OptionIteam>
                ))}
              </AddQuestionDropdown>
              {/* </div> */}
            </div>
          </UseClickOutside>
        </TestButtonContainer>
      </CategoryTopBar>
      {/* <----------------Types of Question's-----------------> */}
      <QuestionTypes>
        {loading ? (
          <div className="loader"></div>
        ) : questionType.length > 0 ? (
          questionType.map((name, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  navigate("/test/question-type/question-table", {
                    state: { quizName: name._id, type: state.type },
                  });
                }}
              >
                <CatogeryCard
                  shadow={true}
                  cardText={name._id}
                  listNo={"2"}
                  width={"250px"}
                  borderRadius={"5px"}
                  height={"100px"}
                  leftBorder={"0px"}
                  rightBorder={"0px"}
                  bottomBorder={"0px"}
                  backgroudColor={Colors.white}
                  subText={`Questions :${name.count}`}
                />
              </div>
            );
          })
        ) : (
          <NoRecored>No record found</NoRecored>
        )}
      </QuestionTypes>
    </QuestionTypeContainer>
  );
};

export default TestQuestionType;
