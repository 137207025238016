const isDev = false;

const loginRedirectURL = isDev
  ? "http://localhost:3000/"
  : "https://hrportal-ui.panorbitprojects.com/";

const loginClientID = "1a101eae-d53d-4458-96a6-0217a9431f9f";

const loginSystemID =
  "https://login.microsoftonline.com/45c74288-2629-45b0-8381-2213f992b3f9";

export { isDev, loginClientID, loginRedirectURL, loginSystemID };
