import { createActions } from "reduxsauce";
const { Types, Creators } = createActions({
  loadNewCandidatesData: ["payload"],
  loadNewCandidatesDataStart: ["payload"],
  loadNewCandidatesDataSuccess: ["payload"],
  loadNewCandidatesDataFailure: ["payload"],
  //
  loadEditCandidatesData: ["payload"],
  loadEditCandidatesDataStart: ["payload"],
  loadEditCandidatesDataSuccess: ["payload"],
  loadEditCandidatesDataFailure: ["payload"],
  //
  resetCandaidateStatus: ["payload"],
});

export { Types, Creators };
