import styled from "styled-components";

export const InProgressContainer = styled.div`
  margin-left: 210px;
  margin-right: 5px;
  background-color: white;
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
`;
export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;

  /* background-color: #184e6d; */
  width: calc(100% - 210px);
  height: 50px;
  align-items: center;
  /* padding-left: 15px; */
  gap: 2px;
  background-color: #ecf0f7;
  z-index: 1;
`;

/* // .candidates-details {
//     display: flex;
//     background-color: white;
//     margin-top: 10px;
//     margin-left: 210px;
//     margin-right: 5px;
//     flex-direction: column;
//     height: 100%;
//     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
//     border-top: 3px solid #0AB5A9;
//     border-radius: 8px;
//     height: 100vh;
//   } */
