import React, { useEffect } from "react";
// StyleSheet
import "./style.css";
import { useNavigate, useLocation } from "react-router";
import { useState } from "react";
import Sidebar from "../../components/sidebar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// FontAwsom
import { faCaretDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Creators as newUserCreator } from "./store/action";
// import axios from "axios";
import Button from "../../components/Button";
import { Colors } from "../../components/constants/constants";
import UseClickOutside from "../../components/clickoutside/ClickOutside";
export default function NewUser() {
  const [selectedOption, setSelectedOption] = useState("Hr");
  const [dropDownActive, setDropDownActive] = useState(false);
  const options = ["Hr", "Admin"];
  const { state } = useLocation();

  const dispatch = useDispatch();
  const toggleFilter = () => {
    setDropDownActive(!dropDownActive);
  };
  useEffect(() => {
    setFormValues({
      ...formValues,
      username: state?.data?.fullName,
      email: state?.data?.email,
    });
  }, []);
  const navigate = useNavigate();
  // Function for Edit user
  const editUser = (e) => {
    e.preventDefault();

    const param = {
      name: formValues.username,
      email: formValues.email,
      // active: state?.data?.is_active,
      id: state?.data?.id,
      userType: selectedOption === "Hr" ? "HR" : "Admin",
      // is_staff: true,
      // is_superuser: selectedOption === "Hr" ? false : true,
    };
    dispatch(newUserCreator.editUserData(param));
  };
  // Initial Value
  const initialValues = { username: "", email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // Sava New user data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Sava New user data
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  //Dispatch the data
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const Username = formValues.username;
      const email = formValues.email;

      dispatch(
        newUserCreator.saveUserData({
          Username,
          email,
          selectedOption,
        })
      );
    }
  }, [formErrors]);
  // Form Validation ---------------->
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.username) {
      errors.username = "Username is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    } else if (!values.email.endsWith("@panorbit.in")) {
      errors.email = "Email should be end with @panorbit.in!";
    }
    return errors;
  };

  return (
    <div className="new_user">
      <Sidebar listName="Users"></Sidebar>
      <div className="user_profile">
        <div className="profile-heading">
          <FontAwesomeIcon
            style={{ cursor: "pointer", paddingRight: "10px" }}
            icon={faChevronLeft}
            onClick={() => {
              navigate(-1);
            }}
          ></FontAwesomeIcon>
          User Profile
        </div>
        <div className="save-btn" onClick={state ? editUser : handleSubmit}>
          <Button
            name={"Save"}
            backGroundColor={Colors.blue}
            width={"120px"}
            height={"40px"}
          />
        </div>
      </div>
      <div className="user_form">
        <div className="new-user-form" style={{ width: "50%" }}>
          <div className="label-input-section">
            <label>Username</label>
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={formValues?.username}
              onChange={handleChange}
            />

            <p className="error">{formErrors?.username}</p>
          </div>

          <div className="label-input-section">
            <label>Email</label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={formValues?.email}
              onChange={handleChange}
            />

            <p className="error">{formErrors.email}</p>
          </div>
          {/* <---------------------Admin and HR Dropdown -----------------> */}
          <UseClickOutside
            onClickOutside={() => {
              setDropDownActive(false);
            }}
          >
            <div
              className="label-input-section"
              style={{
                position: "relative",
                cursor: "pointer",
                diaplay: state ? "none" : "",
              }}
              onClick={toggleFilter}
            >
              <label>Active</label>

              <input
                type="text"
                value={selectedOption}
                style={{ cursor: "pointer", paddingLeft: "8px" }}
              ></input>
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{
                  marginRight: "4px",
                  position: "absolute",
                  right: "5px",
                  top: "58%",
                  cursor: "pointer",
                }}
              />
              {dropDownActive && (
                <div
                  className="active-dropdown"
                  // style={{

                  // }}
                >
                  {options.map((item) => {
                    return (
                      <div
                        onClick={(e) => {
                          setSelectedOption(item);
                          setDropDownActive(false);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </UseClickOutside>
        </div>
      </div>
    </div>
  );
}
