import styled from "styled-components";
import { Colors } from "../../components/constants/constants";

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: relative;
  /* background-color: #f8f8f8; */
  background-color: ${(props) => props.backgroudColor};
  border-top: ${(props) => props.topBorder} solid #0ab5a9;
  border-bottom: ${(props) => props.bottomBorder} solid #0ab5a9;
  /*  */
  border-left: ${(props) => props.leftBorder} solid #000000;
  border-right: ${(props) => props.rightBorder} solid #000000;
  /* box-shadow:${(props) => props.shadow} solid #000000; */
  ${({ shadow }) =>
    shadow &&
    `
   box-shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px;
  `}

  border-radius: ${(props) => props.borderRadius};
  cursor: pointer;
`;
export const CardText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.black};
  text-decoration: ${(props) => props.decoration};
  opacity: ${(props) => props.opacity};
  font-size: 1.1rem;
  text-transform: capitalize;
  /* position: relative; */
  /* padding-bottom: 10px; */
  /* padding-top: 10px; */
`;
export const Icon = styled.div`
  padding-right: 10px;
  gap: 1px;
`;
export const CardParagraph = styled.div`
  position: absolute;
  right: 10px;
  bottom: 7px;
  /* display: flex;
  justify-content: center;*/
  font-size: small;
  text-transform: capitalize;
`;
export const ParagrpahValue = styled.div`
  display: flex;
  justify-content: center;
  font-size: small;
  color: ${Colors.blue};
`;
export const CardSubText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.blue};
`;
export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
