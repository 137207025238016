import { put } from "redux-saga/effects";
import { Creators } from "./action";
import { WebService } from "../../../axios/webServices";
import { apiEndPoints } from "../../../axios/endPoints";
import { toast } from "react-toastify";
//saga for fetching the user data and pagination

export function* loadTestCategorysaga(action) {
  try {
    yield put(Creators.loadTestCategoryStart());
    const response = yield WebService.get(apiEndPoints.testCategory, {
      params: {
        catagory: action.payload,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(
        Creators.loadTestCategorySuccess({
          questionTypeData: response?.data?.data,
        })
      );
    } else {
      yield put(Creators.loadTestCategoryFailure());
    }
  } catch (error) {
    yield put(Creators.loadTestCategoryFailure());
    // Creators.loadUserDataFailure({ errorMessage: "saga error" });
  }
}
//saga for Upload Bulk Upload
export function* uploadBulkQuestionSaga(action) {
  const { fileUpload } = action.payload;
  try {
    yield put(Creators.loadBulkQuestionStart());
    const response = yield WebService.post(
      apiEndPoints.bulkTestQuestion,
      fileUpload
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.loadBulkQuestionSuccess());
      toast.success("Quiz updated successfully");
    } else {
      toast.error("Something went wrong");
    }
  } catch (err) {
    // toast.error("Something went wrong", err);
  }
}
// Saga for Question table
export function* loadQuestionTableSaga(action) {
  try {
    yield put(Creators.loadQuestionTableStart());
    const response = yield WebService.get(apiEndPoints.qustionList, {
      params: {
        questionType: action.payload.quizName,
        status: action.payload.status,
        catagory: action.payload.catagory,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.loadQuestionTableSuccess(response.data));
    } else {
      yield put(Creators.loadQuestionTableFailure());
    }
  } catch (err) {}
}
// Saga to Get data for Custmize data
export function* loadGetCustomizeQuizSaga(action) {
  try {
    yield put(Creators.loadGetCustomizeQuizStart());
    const response = yield WebService.get(apiEndPoints.getCustamize, {
      params: {
        catagory: action.payload,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.loadGetCustomizeQuizSuccess(response.data));
    } else {
      yield put(Creators.loadGetCustomizeQuizFailure());
      toast.error("Something went wrong");
    }
  } catch (err) {
    yield put(Creators.loadGetCustomizeQuizFailure());
  }
}
// Saga to update the data for Custmize data
export function* loadPostCustomizeQuizSaga(action) {
  try {
    yield put(Creators.loadPostCustomizeQuizStart());
    const response = yield WebService.post(apiEndPoints.postCustamize, {
      custom_list: action.payload.custom_list,
      quizTime: action.payload.allocationTime,
      catagory: action.payload.type,
      // params: {
      //   catagory: "tech",
      // },
    });
    // console.log(response);
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.loadPostCustomizeQuizSuccess(response));
      toast.success("Quiz updated successfully");
    } else {
      yield put(Creators.loadPostCustomizeQuizFailure());
      toast.error(response.response?.data?.error);
    }
  } catch (err) {
    // toast.error(err);
    yield put(Creators.loadPostCustomizeQuizFailure());
  }
}
//<------------- Saga to Activate the question------------------>
export function* loadActivateQuestionSaga(action) {
  try {
    yield put(Creators.loadActivateQuestionStart());
    const response = yield WebService.post(apiEndPoints.activateQuestion, {
      questionId: action.payload,
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.loadActivateQuestionSuccess());

      toast.success("Question activated successfully");
    } else {
      toast.error("Something went wrong");
    }
  } catch (err) {
    toast.error("Something went wrong");
  }
}
// <-------------------Saga to De Activate the question----------------->
export function* loadDeactivateQuestionSaga(action) {
  try {
    yield put(Creators.loadDeactivateQuestionStart());
    const response = yield WebService.post(apiEndPoints.deactivateQuestion, {
      questionId: action.payload,
    });
    if (response.status >= 200 && response.status < 300) {
      toast.success("Question deactivated successfully");
      yield put(Creators.loadDeactivateQuestionSuccess());
    } else {
      toast.error("Something went wrong");
    }
  } catch (err) {
    toast.error("Something went wrong");
  }
}
// <----------------------------Saga to Add new  question-------------------------->
export function* addNewQuestionSaga(action) {
  const { question, questionTypes, probableAns, correctOptions, catagory } =
    action.payload;
  try {
    yield put(Creators.addNewQuestionStart());
    const response = yield WebService.post(apiEndPoints.addNewQuestion, {
      question: question,
      negativePoints: 0.0,
      ansOption: probableAns,
      correctAns: correctOptions,
      questionType: questionTypes,
      catagory: catagory,
    });
    if (response.status >= 200 && response.status < 300) {
      toast.success("New question added successfully");
      yield put(Creators.addNewQuestionSuccess());
    } else {
      toast.error("Something went wrong");
      yield put(Creators.addNewQuestionFailure());
    }
  } catch (error) {
    yield put(Creators.addNewQuestionFailure());
  }
}
// <----------------------------Saga to Edit exist  question-------------------------->
export function* editQuestionSaga(action) {
  const {
    question,
    questionTypes,
    probableAns,
    correctOptions,
    catagory,
    QuizId,
  } = action.payload;

  try {
    yield put(Creators.editQuestionStart());
    const response = yield WebService.put(apiEndPoints.updateQuestion, {
      questionId: QuizId,
      question: question,
      ansOption: probableAns,
      correctAns: correctOptions,
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.editQuestionSuccess());
      toast.success("Question updated successfully");
      yield put(Creators.editQuestionSuccess());
    } else {
      toast.error("Something went wrong");
      yield put(Creators.editQuestionFailure());
    }
  } catch (error) {
    yield put(Creators.editQuestionFailure());
  }
}
