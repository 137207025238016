import { style } from "@mui/system";
import styled from "styled-components";
import { Colors } from "../constants/constants";
export const QuickViewContainer = styled.div`
  position: relative;
`;
export const QuestionAndAnswer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const Question = styled.div`
  margin-top: 60px;
`;
export const AnswerContainer = styled.div`
  display: flex;
  /* background-color: aquamarine;
  justify-content: space-around; */
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  /* height: calc(100%-50px) */
  margin-top: 10px;
`;
export const Answer = styled.div``;
export const AnswerSubContainer = styled.div`
  /* padding-top: 20px; */
`;
export const QuestionLable = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 18px;
`;
export const OptionLable = styled.div`
  font-size: 16px;
`;
export const CancelFont = styled.div`
  position: absolute;
  top: 10px;
  cursor: pointer;
  right: 10px;
`;
