import { put } from "redux-saga/effects";
import { Creators } from "./action";
import { WebService } from "../../../axios/webServices";
import { apiEndPoints } from "../../../axios/endPoints";
//activate or deactivate user
export function* updateUserSaga(action) {
  try {
    let response;
    yield put(Creators.updateUserStart());
    if (!action.payload.active) {
      response = yield WebService.post(
        `${apiEndPoints.activateUser}/${action.payload.id}`
      );
    } else {
      response = yield WebService.post(
        `${apiEndPoints.deactivateUser}/${action.payload.id}`
      );
    }
    if (response.status >= 200 && response.status < 300) {
      yield put(
        Creators.updateUserSuccess({
          message: response.data.message,
        })
      );
    } else {
      Creators.updateUserFailure({ errorMessage: "api error" });
    }
  } catch (error) {
    Creators.updateUserFailure({ errorMessage: "saga error" });
  }
}
