import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Styled component css
import { ButtonContainer, ButtonText, Icon } from "./Button.styled";
// import "./Button.css";

function Button(props) {
  const { name, icon, backGroundColor, width, height } = props;

  const iconAligned = "10px";

  return (
    <>
      <ButtonContainer
        bg={backGroundColor}
        width={width ? width : "160px"}
        height={height ? height : "40px"}
      >
        <ButtonText>
          {icon ? (
            <Icon iconAligned={iconAligned}>
              <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
            </Icon>
          ) : (
            <></>
          )}
          {name}
        </ButtonText>
      </ButtonContainer>
    </>
  );
}

export default Button;
