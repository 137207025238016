import React from "react";
// import "./card.css";
import { useNavigate } from "react-router";
import { CardContainer, CardText } from "./card.styled";
const Card = (props) => {
  const {
    width,
    height,
    cardText,
    borderRadius,
    bottomBorder,
    topBorder,
    rightBorder,
    leftBorder,
    backgroudColor,
    decoration,
    opacity,
    shadow,
  } = props;

  return (
    <>
      <CardContainer
        shadow={shadow}
        width={width}
        height={height}
        topBorder={topBorder}
        borderRadius={borderRadius}
        bottomBorder={bottomBorder}
        leftBorder={leftBorder}
        rightBorder={rightBorder}
        backgroudColor={backgroudColor}
      >
        <CardText decoration={decoration} opacity={opacity}>
          {cardText}
        </CardText>
      </CardContainer>
    </>
  );
};
export default Card;
