import { takeEvery, takeLatest } from "redux-saga/effects";
import {
  loadUserSaga,
  activateUserSaga,
  loadDeactivateUserSaga,
} from "../pages/users/store/saga";
import { updateUserSaga } from "../modals/activate-deactivate/store/saga";
import { saveUserSaga, editUserSaga } from "../pages/newuser/store/saga";
import { loginSaga } from "../pages/pagelayout/store/saga";
import {
  loadCandidatesSaga,
  downloadCandidatesDataSaga,
  sendMailSaga,
} from "../components/candidates-details/store/saga";

import {
  loadNewCandidatesDataSaga,
  loadEditCandidatesDataSaga,
} from "../pages/addCandidate/store/saga";
import {
  loadCandidatesProfileSaga,
  loadAssignmentFirsteSaga,
  loadAssignmentSecondeSaga,
} from "../components/CandidateProfile/store/saga";
import {
  loadTestCategorysaga,
  uploadBulkQuestionSaga,
  loadQuestionTableSaga,
  loadGetCustomizeQuizSaga,
  loadPostCustomizeQuizSaga,
  loadActivateQuestionSaga,
  loadDeactivateQuestionSaga,
  addNewQuestionSaga,
  editQuestionSaga,
} from "../pages/tests/store/saga";

import { Types } from "./action";

export function* mainSaga() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.LOAD_USER_DATA, loadUserSaga);
  yield takeEvery(Types.UPDATE_USER, updateUserSaga);
  yield takeEvery(Types.SAVE_USER_DATA, saveUserSaga);
  yield takeEvery(Types.EDIT_USER_DATA, editUserSaga);
  yield takeLatest(Types.LOAD_ACTIVATE_USER, activateUserSaga);
  yield takeLatest(Types.LOAD_DEACTIVATE_USER, loadDeactivateUserSaga);
  yield takeEvery(Types.LOAD_CANDIDATES_DATA, loadCandidatesSaga);

  yield takeEvery(Types.LOAD_NEW_CANDIDATES_DATA, loadNewCandidatesDataSaga);
  yield takeEvery(
    Types.LOAD_CANDIDATES_PROFILE_DATA,
    loadCandidatesProfileSaga
  );
  yield takeEvery(Types.LOAD_EDIT_CANDIDATES_DATA, loadEditCandidatesDataSaga);
  yield takeLatest(Types.LOAD_EXPORT_DATA, downloadCandidatesDataSaga);
  yield takeLatest(Types.LOAD_EMAIL_DATA, sendMailSaga);
  yield takeLatest(
    Types.LOAD_ASSIGNMENT_FIRST_DETAILS,
    loadAssignmentFirsteSaga
  );
  yield takeLatest(
    Types.LOAD_ASSIGNMENT_SECOND_DETAILS,
    loadAssignmentSecondeSaga
  );
  yield takeLatest(Types.LOAD_TEST_CATEGORY, loadTestCategorysaga);
  yield takeLatest(Types.LOAD_BULK_QUESTION, uploadBulkQuestionSaga);
  yield takeLatest(Types.LOAD_QUESTION_TABLE, loadQuestionTableSaga);
  yield takeLatest(Types.LOAD_GET_CUSTOMIZE_QUIZ, loadGetCustomizeQuizSaga);
  yield takeLatest(Types.LOAD_POST_CUSTOMIZE_QUIZ, loadPostCustomizeQuizSaga);
  yield takeLatest(Types.LOAD_ACTIVATE_QUESTION, loadActivateQuestionSaga);
  yield takeLatest(Types.LOAD_DEACTIVATE_QUESTION, loadDeactivateQuestionSaga);
  yield takeLatest(Types.ADD_NEW_QUESTION, addNewQuestionSaga);
  yield takeLatest(Types.EDIT_QUESTION, editQuestionSaga);
}
