import { createActions } from "reduxsauce";
const { Types, Creators } = createActions({
  // Loading Table data
  loadCandidatesData: ["payload"],
  loadCandidatesDataStart: ["payload"],
  loadCandidatesDataSuccess: ["payload"],
  loadCandidatesDataFailure: ["payload"],

  // For Send email
  loadEmailData: ["payload"],
  loadEmailDataStart: ["payload"],
  loadEmailDataSuccess: ["payload"],
  loadEmailDataFailure: ["payload"],
  // Download Candidate data in excel
  loadExportData: ["payload"],
  loadExportDataStart: ["payload"],
  loadExportDataSuccess: ["payload"],
  loadExportDataFailure: ["payload"],
  //
});

export { Types, Creators };
