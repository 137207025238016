import { createActions } from "reduxsauce";
const { Types, Creators } = createActions({
  loadUserData: ["payload"],
  loadUserDataStart: ["payload"],
  loadUserDataSuccess: ["payload"],
  loadUserDataFailure: ["payload"],
  // Action to Activate user
  loadActivateUser: ["payload"],
  loadActivateUserStart: ["payload"],
  loadActivateUserSuccess: ["payload"],
  loadActivateUserFailure: ["payload"],
  // Action to Activate user
  loadDeactivateUser: ["payload"],
  loadDeactivateUserStart: ["payload"],
  loadDeactivateUserSuccess: ["payload"],
  loadDeactivateUserFailure: ["payload"],
});

export { Types, Creators };
