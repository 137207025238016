import React from "react";
// import setAccessTokenToHeader from "../../axios/helper";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./style.css";

import Sidebar from "../../components/sidebar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { Creators } from "./store/action";

// Component
import UserTable from "../../components/table/UserTable";
// Button
import Button from "../../components/Button";
import Card from "../../components/card/Card";
import { TabContainer } from "../../components/candidates-details/InProgress.styled";
// Constant
import { Colors } from "../../components/constants/constants";
export default function User() {
  const dispatch = useDispatch();

  // const token = sessionStorage.getItem("token");
  // setAccessTokenToHeader(token);

  const navigate = useNavigate();

  const addNewUser = () => {
    navigate("/users/create");
  };

  const [activeUser, setActiveUser] = useState(true);
  //
  const userData = useSelector((state) => state.userDataReducer.userData);
  const loading = useSelector((state) => state?.userDataReducer?.loading);
  const activeloading = useSelector(
    (state) => state.userDataReducer.activeLoading
  );
  const deactiveloading = useSelector(
    (state) => state?.userDataReducer?.dactiveLoading
  );

  useEffect(() => {
    dispatch(Creators.loadUserData(activeUser));
  }, [activeloading, deactiveloading, activeUser]);

  return (
    <div className="user-container">
      <Sidebar listName="Users"></Sidebar>

      <div className="create-user">
        {/*<-------------Active and InActive Section--------------------> */}
        <div className="tab-container">
          <TabContainer>
            <div
              onClick={() => {
                setActiveUser(true);
              }}
            >
              <Card
                width={"300px"}
                height={"50px"}
                cardText={"Active user"}
                borderRadius={"00px 0px 0px 0px;"}
                bottomBorder={"0px"}
                topBorder={activeUser ? "4px" : "0px"}
                decoration={activeUser ? "underline" : ""}
                leftBorder={"0px"}
                rightBorder={"0px"}
                backgroudColor={
                  activeUser ? Colors.activeColor : Colors.filterBackgroundColor
                }
                opacity={activeUser ? "1" : "0.5"}
              />
            </div>
            <div
              onClick={() => {
                setActiveUser(false);
              }}
            >
              <Card
                width={"300px"}
                height={"50px"}
                cardText={"Inactive user"}
                borderRadius={"0px 0px 0px 0px;"}
                bottomBorder={"0px"}
                topBorder={activeUser ? "0px" : "4px"}
                decoration={activeUser ? "" : "underline"}
                leftBorder={"0px"}
                rightBorder={"0px"}
                backgroudColor={
                  activeUser ? Colors.filterBackgroundColor : Colors.activeColor
                }
                opacity={activeUser ? "0.5" : "1"}
              />
            </div>
            {/* <----------------Create New User---------------> */}
            <div className="user-header">
              <div className="user-heading">Users List</div>
              <div
                className="add-new-user"
                // onClick={() => {
                //   addNewUser;
                // }}
                style={{ display: activeUser ? "" : "none" }}
                onClick={addNewUser}
              >
                <Button
                  name={"New User"}
                  icon={faPlus}
                  backGroundColor={Colors.blue}
                  width={"160px"}
                  height={"40px"}
                />
              </div>
            </div>
          </TabContainer>
        </div>
      </div>
      <div className="users_list">
        {!userData ? (
          "No record found"
        ) : loading ? (
          <div className="loader"></div>
        ) : (
          <UserTable data={userData} User_Type={activeUser} />
        )}
      </div>
    </div>
  );
}
