// dependency
import React from "react";
// Component
import Sidebar from "../../components/sidebar/SideBar";
import Card from "../../components/card/Card";
// Constant
import { Colors } from "../../components/constants/constants";
// Styled component
import {
  TestScreenContainer,
  TestCardContainer,
} from "./TestHomeScreen.styled";
import { useNavigate } from "react-router";

const TestHomeScreen = () => {
  const navigate = useNavigate();
  return (
    <TestScreenContainer>
      <Sidebar />
      <TestCardContainer>
        <div
          onClick={() => {
            navigate("/test/question-type/", {
              state: { type: "tech" },
            });
          }}
        >
          <Card
            shadow={true}
            cardText={"Tech"}
            listNo={"2"}
            width={"10rem"}
            borderRadius={"5px"}
            height={"6rem"}
            leftBorder={"0px"}
            rightBorder={"0px"}
            bottomBorder={"0px"}
            backgroudColor={Colors.white}
          />
        </div>
        <div
          onClick={() => {
            navigate("/test/question-type/", {
              state: { type: "design" },
            });
          }}
        >
          <Card
            shadow={true}
            cardText={"Design"}
            listNo={"2"}
            width={"10rem"}
            borderRadius={"5px"}
            height={"6rem"}
            leftBorder={"0px"}
            rightBorder={"0px"}
            bottomBorder={"0px"}
            backgroudColor={Colors.white}
          />
        </div>
        <div
          onClick={() => {
            navigate("/test/question-type/", {
              state: { type: "film" },
            });
          }}
        >
          <Card
            shadow={true}
            cardText={"Film"}
            listNo={"2"}
            width={"10rem"}
            borderRadius={"5px"}
            height={"6rem"}
            leftBorder={"0px"}
            rightBorder={"0px"}
            bottomBorder={"0px"}
            backgroudColor={Colors.white}
          />
        </div>
        <div
          onClick={() => {
            navigate("/test/question-type/", {
              state: { type: "marketing" },
            });
          }}
        >
          <Card
            shadow={true}
            cardText={"Marketing"}
            listNo={"2"}
            width={"10rem"}
            borderRadius={"5px"}
            height={"6rem"}
            leftBorder={"0px"}
            rightBorder={"0px"}
            bottomBorder={"0px"}
            backgroudColor={Colors.white}
          />
        </div>
        <div
          onClick={() => {
            navigate("/test/question-type/", {
              state: { type: "enablement" },
            });
          }}
        >
          <Card
            shadow={true}
            cardText={"Enablement"}
            listNo={"2"}
            width={"10rem"}
            borderRadius={"5px"}
            height={"6rem"}
            leftBorder={"0px"}
            rightBorder={"0px"}
            bottomBorder={"0px"}
            backgroudColor={Colors.white}
          />
        </div>
      </TestCardContainer>
    </TestScreenContainer>
  );
};

export default TestHomeScreen;
