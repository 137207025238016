import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  candidatesData: [],
  loading: false,
  mailLoading: false,
  errorMessage: "",
};
// Load candidate data Reducer
const loadCandidatesDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const loadCandidatesDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    candidatesData: action.payload.data,
  };
};
const loadCandidatesDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    errorMessage: action.payload.errorMessage,
  };
};

// Send the mail to candidate Reducer
const loadEmailDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mailLoading: true,
  };
};
const loadEmailDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mailLoading: false,
    // candidatesData: action.payload.data,
  };
};
const loadEmailDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    mailLoading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
// Download candidate File Reducer
const loadExportDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const loadExportDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // candidatesData: action.payload.data,
  };
};
const loadExportDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
// Handler
const HANDLERS = {
  //  Candidate data Handlers
  [Types.LOAD_CANDIDATES_DATA_START]: loadCandidatesDataStart,
  [Types.LOAD_CANDIDATES_DATA_SUCCESS]: loadCandidatesDataSuccess,
  [Types.LOAD_CANDIDATES_DATA_FAILURE]: loadCandidatesDataFailure,
  //  Candidate data Handlers

  [Types.LOAD_EMAIL_DATA_START]: loadEmailDataStart,
  [Types.LOAD_EMAIL_DATA_SUCCESS]: loadEmailDataSuccess,
  [Types.LOAD_EMAIL_DATA_FAILURE]: loadEmailDataFailure,

  // //  Candidate data Handlers

  [Types.LOAD_EXPORT_DATA_START]: loadExportDataStart,
  [Types.LOAD_EXPORT_DATA_SUCCESS]: loadExportDataSuccess,
  [Types.LOAD_EXPORT_DATA_FAILURE]: loadExportDataFailure,
};

export const CandidatesDataReducer = createReducer(INITIAL_STATE, HANDLERS);
