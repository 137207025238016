import {
  legacy_createStore as createStore,
  compose,
  applyMiddleware,
} from "redux";
import createSagaMiddleware from "@redux-saga/core";
import rootReducer from "./reducer";
import { mainSaga } from "./sagas";
const composeEnhancer = compose;
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(mainSaga);
export default store;
