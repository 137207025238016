import React from "react";
import {
  CardContainer,
  CardText,
  CardParagraph,
  ParagrpahValue,
  CardSubText,
  CardTextContainer,
} from "./card.styled";
const CatogeryCard = (props) => {
  const {
    width,
    height,
    cardText,
    borderRadius,
    bottomBorder,
    topBorder,
    rightBorder,
    leftBorder,
    backgroudColor,
    decoration,
    opacity,
    shadow,
    subText,
  } = props;
  return (
    <>
      <CardContainer
        shadow={shadow}
        width={width}
        height={height}
        topBorder={topBorder}
        borderRadius={borderRadius}
        bottomBorder={bottomBorder}
        leftBorder={leftBorder}
        rightBorder={rightBorder}
        backgroudColor={backgroudColor}
      >
        <CardTextContainer>
          <CardText decoration={decoration} opacity={opacity}>
            {cardText}
          </CardText>
          <CardSubText>
            <CardParagraph>{subText}</CardParagraph>
            {/* <ParagrpahValue>20</ParagrpahValue> */}
          </CardSubText>
        </CardTextContainer>
      </CardContainer>
    </>
  );
};

export default CatogeryCard;
