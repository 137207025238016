import { put } from "redux-saga/effects";
import { Creators } from "./action";
import { WebService } from "../../../axios/webServices";
import { apiEndPoints } from "../../../axios/endPoints";
import { toast } from "react-toastify";
//saga for fetching the user data and pagination
import setAccessTokenToHeader from "../../../axios/helper";
import { functions } from "lodash";
export function* loadUserSaga(action) {
  try {
    yield put(Creators.loadUserDataStart());
    const response = yield WebService.get(apiEndPoints.readUser, {
      params: {
        active_status: action.payload,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(
        Creators.loadUserDataSuccess({
          userData: { ...response.data },
        })
      );
    } else {
      Creators.loadUserDataFailure({ errorMessage: "api error" });
    }
  } catch (error) {
    Creators.loadUserDataFailure({ errorMessage: "saga error" });
  }
}
// Saga to Activate user
export function* activateUserSaga(action) {
  try {
    yield put(Creators.loadActivateUserStart());
    const response = yield WebService.post(
      `${apiEndPoints.activateUser}/${action.payload}`
    );

    if (response.status >= 200 && response.status < 300) {
      toast.success("User activated successfully");
      yield put(Creators.loadActivateUserSuccess(response));
    } else {
      Creators.loadActivateUserFailure();
    }
  } catch (error) {
    toast.error(error);
    Creators.loadActivateUserFailure();
  }
}
// Saga To deactivate user
export function* loadDeactivateUserSaga(action) {
  try {
    yield put(Creators.loadDeactivateUserStart());
    const response = yield WebService.post(
      `${apiEndPoints.deactivateUser}/${action.payload}`
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.loadDeactivateUserSuccess("lucky"));
      toast.success("User deactivated successfully");
    } else {
      toast.error("Something Went Wrong");
      Creators.loadDeactivateUserFailure();
      throw response;
    }
  } catch (error) {
    toast.error(error);
    Creators.loadDeactivateUserFailure();
  }
}
