import React, { useState } from "react";
import { WebService } from "../../axios/webServices";
import setAccessTokenToHeader from "../../axios/helper";
import "./style.css";
// Component
import NavBar from "../../components/navbar/NavBar";
import Sidebar from "../../components/sidebar/SideBar";
import Button from "../../components/Button";
import { toast } from "react-toastify";
// Redux
import { useNavigate } from "react-router";
//

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
//
import { Colors } from "../../components/constants/constants";
//
const FileUploader = () => {
  //
  const navigate = useNavigate();
  //
  setAccessTokenToHeader(sessionStorage.getItem("token"));
  const [file, setFile] = useState(null);
  const onFileChange = (file) => {
    setFile(file.target.files[0]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    data.append("file", file);
    // if (file.name.endsWith(".xlsx")) {
    WebService.post("https://hrportal.panorbitprojects.com/api/Import/", data)
      .then((res) => {
        if (res.status >= 200 && res.status < 305) {
          toast.success(" File uploaded successfully");
        } else {
          toast.error("File is not supported");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // } else {
    //   toast.error("File type not valid");
    // }
  };

  return (
    <div className="fileuploading">
      <Sidebar listName="Candidates"></Sidebar>
      <div className="bulkCandidate">
        <div className="bulk-heading">
          <FontAwesomeIcon
            // className="title"
            style={{ cursor: "pointer", paddingRight: "10px" }}
            icon={faChevronLeft}
            onClick={() => {
              navigate("/candidates/new-candidate", {
                state: { mail_status: "New" },
              });
            }}
          ></FontAwesomeIcon>
          Add Bulk Candidate
        </div>
        <div className="sample-btn">
          <a
            className="Resume-viewer-container"
            href={
              "https://hrportal.panorbitprojects.com/media_cdn/sample_panhire.xlsx"
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button
              // icon={faDownload}
              name={"Download format"}
              backGroundColor={Colors.blue}
              width={"200px"}
              height={"40px"}
            />
          </a>
        </div>
      </div>
      <div className="filelabel">{/* <p>File Upload</p> */}</div>
      <div className="upload-file">
        <form
          id="file-form"
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <div className="input_file">
            <label>
              Select XLSX <span style={{ color: "red" }}>*</span>
            </label>
            <input type="file" accept={".xlsx"} onChange={onFileChange}></input>
          </div>
        </form>
      </div>
      <button type="submit" form="file-form" className="submit_btn">
        Upload
      </button>
    </div>
  );
};
export default FileUploader;
