import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  SuccessMassage: "",
  loading: false,
  errorMessage: "",
  newCandidateAdded: false,
  candidateedited: false,
  addingNew: false,
  status: false,
};
const resetCandaidateStatus = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    newCandidateAdded: false,
    candidateedited: false,
  };
};
const loadNewCandidatesDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    addingNew: true,
    status: true,
  };
};
const loadNewCandidatesDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    addingNew: true,
    newCandidateAdded: true,
    status: false,
    // SuccessMassage: action.payload.data.message,
  };
};
const loadNewCandidatesDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    newCandidateAdded: false,
    addingNew: false,
    status: false,
  };
};
// Candidate Edit reducer
const loadEditCandidatesDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const loadEditCandidatesDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    candidateedited: true,
    // SuccessMassage: action.payload.data.message,
  };
};
const loadEditCandidatesDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
  };
};
const HANDLERS = {
  [Types.LOAD_NEW_CANDIDATES_DATA_START]: loadNewCandidatesDataStart,
  [Types.LOAD_NEW_CANDIDATES_DATA_SUCCESS]: loadNewCandidatesDataSuccess,
  [Types.LOAD_NEW_CANDIDATES_DATA_FAILURE]: loadNewCandidatesDataFailure,
  // Edit candidate Handler
  [Types.LOAD_EDIT_CANDIDATES_DATA_START]: loadEditCandidatesDataStart,
  [Types.LOAD_EDIT_CANDIDATES_DATA_SUCCESS]: loadEditCandidatesDataSuccess,
  [Types.LOAD_EDIT_CANDIDATES_DATA_FAILURE]: loadEditCandidatesDataFailure,
  //
  [Types.RESET_CANDAIDATE_STATUS]: resetCandaidateStatus,
};
export const NewCandidatesDataReducer = createReducer(INITIAL_STATE, HANDLERS);
