import { Col } from "antd";
import styled from "styled-components";
import { Colors } from "../../components/constants/constants";

export const QuestionTableContainer = styled.div`
  margin-left: 210px;
  margin-right: 5px;
  background-color: ${Colors.white};
  /* border-top: 3px solid #0ab5a9; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
  margin-top: 10px;
  overflow-y: scroll;
`;
export const QuestionTableTopHeading = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  width: calc(100% - 210px);
  background-color: ${Colors.white};
  z-index: 1;
  /* width: calc(100%-210px); */
  /* width:calc */
`;
export const TestQuestionContainer = styled.div`
  padding-top: 50px;
  /* padding-top: 70px; */
  /* display: flex; */
  /* margin-top: 50px; */
`;
export const TestQuestionAction = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;
export const TestQuestionHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
`;
export const TestQuestionTableContainer = styled.div`
  margin-top: 100px;
  /* display: flex; */
  /* justify-content: center; */
`;
export const QuestionTable = styled.table`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;
export const QuestionTableRow = styled.tr`
  display: flex;
  flex: 1;
  border-bottom: 1px solid ${Colors.tableBorderColor};
`;
// export const QuestionTableHeading = styled.th`
//   display: flex;
//   flex: 1;
//   color: ${Colors.tableTextColor};
//   text-transform: capitalize;
//   padding-bottom: 8px;
//   color: ${Colors.tableHeadingColor};
//   font-size: 13px;
//   padding-top: 8px;
//   padding-bottom: 8px;
//   border: 2px solid green;
// `;
// Heading------------------->
export const QuestionTableQuestionHeading = styled.th`
  display: flex;
  padding-bottom: 8px;
  color: ${Colors.tableHeadingColor};
  font-size: 13px;
  flex: 1;
`;
export const QuestionTableDateHeading = styled.th`
  display: flex;
  /* flex: 1; */
  padding-bottom: 8px;
  color: ${Colors.tableHeadingColor};
  font-size: 13px;
  width: 200px;
`;
export const QuestionTableViewHeading = styled.th`
  display: flex;
  padding-bottom: 8px;
  color: ${Colors.tableHeadingColor};
  font-size: 13px;
  width: 200px;
`;
export const QuestionTableActionHeading = styled.th`
  display: flex;
  padding-bottom: 8px;
  color: ${Colors.tableHeadingColor};
  font-size: 13px;
  width: 200px;
`;
// DataValue----------------------->
export const QuestionQustionTableData = styled.td`
  color: ${Colors.tableTextColor};
  text-transform: capitalize;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 1;
  /* overflow: auto; */
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #595959;

  width: 100%;

  overflow: hidden;
  /* text-overflow: ellipsis; */
`;

export const QuestionDateTableData = styled.td`
  display: flex;

  color: ${Colors.tableTextColor};
  text-transform: capitalize;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 200px;
`;

export const QuestionActionTableData = styled.td`
  display: flex;

  color: ${Colors.tableTextColor};
  text-transform: capitalize;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 200px;
  gap: 20px;
`;
export const Modal = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${Colors.filterAndModalColor};
  height: 60px;
  width: 100px;
  border-radius: 8px;
  /* z-index: 2; */
`;
export const QuickView = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
`;
