import React from "react";
import "../notfound/notfound.css";
import NavBar from "../../components/navbar/NavBar";
import SideBar from "../../components/sidebar/SideBar";
export default function notFound() {
  return (
    <div>
      <SideBar></SideBar>
      {/* <NavBar 
      // title="Go to Home" 
      page="/"></NavBar> */}
      <div className="not-found">
        <h1>404</h1>
        <br></br>
        <p> page you are requesting, is not availaible</p>
      </div>
    </div>
  );
}
