import "./style.css";
import Card from "../../components/card/Card";
import NavBar from "../../components/navbar/NavBar";
import SideBar from "../../components/sidebar/SideBar";
import { useNavigate } from "react-router-dom";
// import{cancontainer}
/// Constant
import { Colors } from "../../components/constants/constants";
export const PageLayout = () => {
  const isAdmin = sessionStorage.getItem("admin");
  const isActive = sessionStorage.getItem("isActive");
  const logIn = sessionStorage.getItem("logInStart");
  const navigate = useNavigate();

  return (
    <>
      <div className="layoutContainer">
        <SideBar listName="Home"></SideBar>

        {/* <div style={{ position: "fixed", left: "0" }}s>
          <NavBar 
          // title="Home"
          ></NavBar>
        </div> */}

        <div className="cardContainer">
          <div
            onClick={() => {
              navigate("/candidates/new-candidate", {
                state: { mail_status: "New" },
              });
            }}
          >
            <Card
              cardText={"Candidate"}
              listNo={"3"}
              width={"10rem"}
              borderRadius={"5px"}
              height={"6rem"}
              leftBorder={"0px"}
              rightBorder={"0px"}
              bottomBorder={"0px"}
              backgroudColor={Colors.white}
              shadow={true}
            />
          </div>
          {isAdmin === "true" && isActive === "true" && (
            <div
              onClick={() => {
                navigate("/test");
              }}
            >
              <Card
                cardText={"Test"}
                listNo={"2"}
                width={"10rem"}
                borderRadius={"5px"}
                height={"6rem"}
                leftBorder={"0px"}
                rightBorder={"0px"}
                bottomBorder={"0px"}
                backgroudColor={Colors.white}
                shadow={true}
              />
            </div>
          )}
          {isAdmin === "true" && isActive === "true" && (
            <div
              onClick={() => {
                navigate("/users");
              }}
            >
              <Card
                cardText={"User"}
                listNo={"3"}
                width={"10rem"}
                borderRadius={"5px"}
                height={"6rem"}
                leftBorder={"0px"}
                rightBorder={"0px"}
                bottomBorder={"0px"}
                backgroudColor={Colors.white}
                shadow={true}
              />
            </div>
          )}
        </div>
        {/* {isAdmin === "false" && isActive === "true" ? (
          <Card name={"Candidates"} listNo={"3"} />
        ) : null} */}
      </div>
      <div>
        {isAdmin === "false" && isActive === "false" && (
          <div
            className="unotherized_user"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            You are not allowed to use this portal.
          </div>
        )}
      </div>
    </>
  );
};
