//Dependency
import React, { useState, useEffect } from "react";
// Assets
import Logo from "../../assets/images/Pan-Log.png";
import Tests from "../../assets/images/Tests.png";
import User from "../../assets/images/Users.png";
import Candidate from "../../assets/images/Candidates.png";
// stylesheet
import "../sidebar/sidebar.css";
// Routing
import { useNavigate, useLocation } from "react-router";
// Component
import { WelcomeName } from "../welcomename/WelcomeName";
//
import { useIsAuthenticated } from "@azure/msal-react";

const Sidebar = ({ listName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = sessionStorage.getItem("admin");
  const isAuthenticated = useIsAuthenticated();
  const isActive = sessionStorage.getItem("isActive");
  // const [isCandidateVisible, setIsCandidateVisible] = useState(false);
  const [candidatesActive, setCandidatesActive] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [listValue, setListValue] = useState(0);
  const [activeTest, setActiveTest] = useState(false);

  //
  const [isNewActive, setIsNewActive] = useState(false);
  const [isInProgressActive, setIsInProgressActive] = useState(false);
  const [isOldActive, setIsOLdActive] = useState(false);
  useEffect(() => {
    setSelectedIcon(false);
    setListValue(listName);
  }, [selectedIcon, listName]);

  useEffect(() => {
    if (location.pathname.toString().includes("candidates")) {
      if (location.pathname.toString().includes("in-progress")) {
        setIsInProgressActive(true);
        setIsNewActive(false);
        setIsOLdActive(false);
      } else if (location.pathname.toString().includes("new-candidate")) {
        setIsInProgressActive(false);
        setIsNewActive(true);
        setIsOLdActive(false);
      } else if (location.pathname.toString().includes("old-candidate")) {
        setIsInProgressActive(false);
        setIsNewActive(false);
        setIsOLdActive(true);
      }
      // setIsCandidateVisible(true);
    } else if (location.pathname.toString().includes("test")) {
      setActiveTest(true);
    }
  }, []);
  // Candidate handle
  const newCandidateVisible = () => {
    setIsInProgressActive(false);
    setIsNewActive(true);
    setIsOLdActive(false);
    navigate("/candidates/new-candidate", { state: { mail_status: "New" } });
    // setIsCandidateVisible(true);
  };

  return (
    <div className="sidebar">
      {/* <-----------------Panorbit logo------------------> */}
      <div className="logo">
        <div
          className="panorbit-logo"
          onClick={(e) => {
            navigate("/");
          }}
        >
          <img src={Logo} width={"100%"} height={"100%"} alt="Logo" />
        </div>
      </div>
      {/* <-------------------Candidates---------------------> */}
      <div className="Candidates">
        <div className="sidebar-list">
          <span style={{ width: "25px", height: "25px" }}>
            <img src={Candidate} width={"100%"} height={"100%"} alt="Logo" />
          </span>
          <div className="sidebarText" onClick={newCandidateVisible}>
            Candidates
          </div>
        </div>
        {/*<------------------New Candidate-------------------->*/}
        <div
          className="candidate-child-selecter"
          // style={{
          //   display: isCandidateVisible ? "" : "none",
          // }}
        >
          <div className="sidebar-list">
            <div
              className="sidebarchildText"
              style={{
                backgroundColor: isNewActive ? "#05374b" : null,
                borderLeft: isNewActive
                  ? "4px solid #0AB5A9"
                  : "4px solid #093A58",
              }}
              onClick={() => {
                setIsInProgressActive(false);
                setIsNewActive(true);
                setIsOLdActive(false);
                navigate("/candidates/new-candidate", {
                  state: { mail_status: "New" },
                });
              }}
            >
              New Candidates
            </div>
          </div>
          {/*<------------------In Progress Candidate-------------------->*/}

          <div className="sidebar-list">
            <div
              className="sidebarchildText"
              onClick={() => {
                setIsInProgressActive(true);
                setIsNewActive(false);
                setIsOLdActive(false);
                navigate("/candidates/in-progress", {
                  state: { mail_status: "Inprogress" },
                });
              }}
              style={{
                backgroundColor: isInProgressActive ? "#05374b" : null,
                borderLeft: isInProgressActive
                  ? "4px solid #0AB5A9"
                  : "4px solid #093A58",
              }}
            >
              In Progress
            </div>
          </div>
          {/*<------------------Old Candidate-------------------->*/}
          <div className="sidebar-list">
            <div
              className="sidebarchildText"
              style={{
                backgroundColor: isOldActive ? "#05374b" : null,
                borderLeft: isOldActive
                  ? "4px solid #0AB5A9"
                  : "4px solid #093A58",
              }}
              onClick={() => {
                setIsInProgressActive(false);
                setIsNewActive(false);
                setIsOLdActive(true);
                navigate("/candidates/old-candidate", {
                  state: { mail_status: "Old" },
                });
              }}
            >
              Old Candidates
            </div>
          </div>
        </div>
      </div>
      {/*<----------------- Test ---------------->*/}
      {isAdmin === "true" && isActive === "true" && (
        <div
          className="home"
          onClick={(e) => {
            setListValue(listName);
            navigate("/test");
            setCandidatesActive(false);
            // setIsCandidateVisible(false);
          }}
          style={{
            backgroundColor: activeTest ? "#05374b" : null,
            borderLeft: activeTest ? "4px solid #0AB5A9" : "4px solid #093A58",
          }}
        >
          <div className="sidebar-list">
            <span style={{ width: "25px", height: "25px" }}>
              <img src={Tests} width={"100%"} height={"100%"} alt="Logo" />
            </span>

            <div className="sidebarText">Test</div>
          </div>
        </div>
      )}
      {/*<------------------ Users (Only admin can access)-----------------> */}
      {isAdmin === "true" && isActive === "true" && (
        <div className="user">
          <div
            className="sidebar-list"
            onClick={() => {
              setListValue(listName);
              navigate("/users");
              setCandidatesActive(false);
              // setIsCandidateVisible(false);
            }}
            style={{
              backgroundColor: listValue === "Users" ? "#05374b" : null,
              borderLeft:
                listValue === "Users"
                  ? "4px solid #0AB5A9"
                  : "4px solid #093A58",
            }}
          >
            <span style={{ width: "25px", height: "25px" }}>
              <img src={User} width={"100%"} height={"100%"} alt="Logo" />
            </span>
            <div className="sidebarText">Users</div>
          </div>
        </div>
      )}
      {/* <----------------^^^^^^^^-------------------> */}
      {/*<----------------- profile logout----------------> */}
      <div className="accountName">
        {isAuthenticated ? <WelcomeName /> : null}
      </div>
    </div>
  );
};

export default Sidebar;
