import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
// Component
import Sidebar from "../../components/sidebar/SideBar";
import ConformationModal from "../../components/conformationModal/ConformationModal";

// Styled component
import Button from "../../components/Button";
import { Colors } from "../../components/constants/constants";
//FontAwesomeIcons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faEdit } from "@fortawesome/free-solid-svg-icons";

import {
  CustomizeQuizContainer,
  CustomizeHeader,
  CustomizeButtonContainer,
  CustomizeTopbar,
  QuestionTimeHeader,
  TotalQuestion,
  ToatlAllocatedTime,
  QuestionsCategoryTable,
  CategoryTableHeading,
  CategoryTable,
  Table,
  CategoryTableBody,
  CategoryTableRow,
  TotalQuestionHeader,
  QuestionTypeHeader,
  QuestionTypeData,
  TotalQuestionData,
  CustomizeQuizEditContainer,
  ConfirmationContainer,
  TotalQuestionInput,
  CustmizeValuInput,
  ToatlTimeInput,
} from "./CustomizeQuiz.styled";
//
import { Creators } from "./store/action";
import { useDispatch, useSelector } from "react-redux";
export const CustomizeQuiz = () => {
  // Enable in Edit mode---------------->
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [inEditMode, setInEditMode] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);

  const [cancel, setCancel] = useState(false);
  const [noOfQuestion, setNoOfQuestion] = useState(0);
  const [allocationTime, setAllocationTime] = useState(0);

  const [noOfQuestionValid, setNoOfQuestionValid] = useState(false);
  const [toatalValidTime, setToatalValidTime] = useState(true);
  // DataTable----------------------->
  const initialValues = useSelector(
    (state) => state?.testDataReducer?.getCustomizeQuizData
  );
  const QuestionAllocation = useSelector(
    (state) => state?.testDataReducer?.totalNoOfQuestion
  );

  const timeAllocation = useSelector(
    (state) => state?.testDataReducer?.totaltimeallocation
  );
  // totaltimeallocation: null,
  const [custom_list, setCustomList] = useState({
    ...initialValues,
  });
  const isSaveSuccessfully = useSelector(
    (state) => state.testDataReducer.postCustomizeQuiz
  );
  useEffect(() => {
    dispatch(Creators.loadGetCustomizeQuiz(state.type));
    dispatch(Creators.loadPostCustomizeQuizStart());
  }, []);
  useEffect(() => {
    setCustomList({ ...initialValues });
  }, [initialValues]);
  useEffect(() => {
    setAllocationTime(timeAllocation);
    setNoOfQuestion(QuestionAllocation);
  }, [QuestionAllocation, timeAllocation]);

  useEffect(() => {
    dispatch(Creators.loadGetCustomizeQuiz(state.type));
  }, [cancel, isSaveSuccessfully]);
  useEffect(() => {
    if (isSaveSuccessfully) {
      setInEditMode(false);
    }
  }, [isSaveSuccessfully]);
  const onSave = () => {
    //
    const { _id, totalQuestions, quizTime, catagory, ...formatedCustomList } =
      custom_list;
    const values = Object.values(formatedCustomList);
    // <--------Calculating and Validating total no. of Question are eqaul or not -------->
    const sum = values.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    setIsConfirmation(false);
    if (sum === noOfQuestion) {
      setNoOfQuestionValid(false);
      if (allocationTime.length > 0) {
        setToatalValidTime(true);

        dispatch(
          Creators.loadPostCustomizeQuiz({
            custom_list: formatedCustomList,
            allocationTime,
            type: state.type,
          })
        );
      } else {
        setToatalValidTime(false);
      }
    } else {
      setNoOfQuestionValid(true);
    }
  };
  // Disable input box after saving successfully value

  // On Discard functon
  const onCancel = (status) => {
    setIsConfirmation(status);
  };

  return (
    <div>
      <CustomizeQuizContainer>
        <Sidebar />
        <CustomizeTopbar>
          <CustomizeHeader>
            <FontAwesomeIcon
              style={{ cursor: "pointer", paddingRight: "10px" }}
              icon={faChevronLeft}
              onClick={() => {
                navigate(-1);
              }}
            ></FontAwesomeIcon>
            Customize Quiz
          </CustomizeHeader>
          {!inEditMode ? (
            <CustomizeButtonContainer>
              <div
                onClick={() => {
                  // onEdit();
                  setInEditMode(true);
                  // navigate("/test/question-type/customize-quiz");
                }}
              >
                <Button
                  name={"Edit"}
                  icon={faEdit}
                  backGroundColor={Colors.buttonColor}
                />
              </div>
            </CustomizeButtonContainer>
          ) : (
            <CustomizeButtonContainer>
              <div
                onClick={() => {
                  // setInEditMode(false);
                  // setCancel(true);
                  navigate(-1);
                  // navigate("/test/question-type/customize-quiz");
                }}
              >
                <Button name={"Cancel"} backGroundColor={Colors.buttonColor} />
              </div>

              <div
                onClick={() => {
                  setIsConfirmation(true);
                }}
              >
                <Button
                  name={"Save"}
                  // icon={faPlus}
                  backGroundColor={Colors.buttonColor}
                />
              </div>
            </CustomizeButtonContainer>
          )}
        </CustomizeTopbar>
        {/* <----------------------No. of Question and Total alloacted time-------------------------> */}
        <CustomizeQuizEditContainer>
          <QuestionTimeHeader>
            <TotalQuestion>
              Total No Of Questions :
              <div
              // onChange={(event) => {
              //   setNoOfQuestion(event.target.value);
              // }}
              >
                <TotalQuestionInput
                  disabled={inEditMode ? false : true}
                  type="number"
                  name="name"
                  value={noOfQuestion}
                  onChange={(event) => {
                    setNoOfQuestion(parseInt(event.target.value));
                  }}
                ></TotalQuestionInput>
                {noOfQuestionValid ? (
                  <div style={{ color: "red" }}>
                    No. of question should be equal
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </TotalQuestion>
            <ToatlAllocatedTime>
              Total Time Allocated (mins) :
              <div>
                <ToatlTimeInput
                  disabled={inEditMode ? false : true}
                  type="number"
                  name="name"
                  value={allocationTime}
                  onChange={(event) => {
                    setAllocationTime(event.target.value);
                  }}
                ></ToatlTimeInput>

                {!toatalValidTime ? (
                  <div style={{ color: "red" }}>Allocate Vlaid time</div>
                ) : (
                  <></>
                )}
              </div>
            </ToatlAllocatedTime>
          </QuestionTimeHeader>
          {/* <----------------------No. of Question and Total alloacted time-------------------------> */}
          <QuestionsCategoryTable>
            <CategoryTableHeading>Customize</CategoryTableHeading>

            <CategoryTable>
              <Table>
                <CategoryTableBody>
                  <CategoryTableRow>
                    <QuestionTypeHeader>Question Type</QuestionTypeHeader>
                    <TotalQuestionHeader>No of Questions</TotalQuestionHeader>
                  </CategoryTableRow>
                </CategoryTableBody>
                <CategoryTableBody>
                  {initialValues &&
                    Object.entries(initialValues).map((entry) => {
                      const [key, val] = entry;
                      if (
                        key === "_id" ||
                        key === "catagory" ||
                        key === "totalQuestions" ||
                        key === "quizTime"
                      )
                        return null;
                      // obj[x] = newObj[x];
                      return (
                        // <CategoryTableBody key={val.id}>
                        <CategoryTableRow>
                          <QuestionTypeData>{key}</QuestionTypeData>
                          <TotalQuestionData>
                            {inEditMode ? (
                              <CustmizeValuInput
                                autoFocus={true}
                                type="number"
                                defaultValue={val}
                                onChange={(event) => {
                                  setCustomList({
                                    ...custom_list,
                                    [key]: parseInt(
                                      event.target.value === ""
                                        ? 0
                                        : event.target.value
                                    ),
                                  });
                                }}
                              />
                            ) : (
                              val
                            )}
                          </TotalQuestionData>
                        </CategoryTableRow>
                        // </CategoryTableBody>
                      );
                    })}
                </CategoryTableBody>
              </Table>
            </CategoryTable>
          </QuestionsCategoryTable>
        </CustomizeQuizEditContainer>
      </CustomizeQuizContainer>
      {/* <-----------------------Conformation Modal------------------> */}
      {isConfirmation ? (
        <ConfirmationContainer>
          <ConformationModal onSave={onSave} onCancel={onCancel} />
        </ConfirmationContainer>
      ) : (
        <></>
      )}

      {/* <------------------------^^^^^^^^^^^^^^^^^^------------------> */}
    </div>
  );
};

export default CustomizeQuiz;
