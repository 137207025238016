import styled from "styled-components";
import { Colors } from "../../components/constants/constants";
export const Table = styled.table`
  background-color: ${Colors.white};
  max-height: 100px;
  height: 80px;
  width: 100%;
`;
export const TableHead = styled.thead``;
export const TableBody = styled.tbody``;
export const TableData = styled.td`
  text-transform: capitalize;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px;
  color: ${Colors.tableTextColor};
`;
export const TableRow = styled.tr`
  font-size: 16px;

  border-bottom: 1px solid rgb(237, 237, 240);
`;
export const TableHeading = styled.th`
  padding-bottom: 8px;
  color: ${Colors.tableHeadingColor};
  font-size: 13px;
`;
export const ActionModal = styled.div``;
export const Modal = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: ${Colors.filterAndModalColor};
  height: 60px;
  width: 100px;
  border-radius: 8px;
`;
export const TableEmailData = styled.td`
  /* text-transform: capitalize; */
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px;
  color: ${Colors.tableTextColor};
`;
