import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  loading: false,
  Message: "",
  UserProfileData: [],
};

const saveUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const saveUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    Message: "Success",
  };
};
const saveUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    Message: "Failed",
  };
};
// Reducer for Edit the profile
const editUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const editUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    Message: "Success",
    UserProfileData: "",
  };
};
const editUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    MEssage: "Failed",
  };
};
const HANDLERS = {
  [Types.SAVE_USER_DATA_START]: saveUserDataStart,
  [Types.SAVE_USER_DATA_SUCCESS]: saveUserDataSuccess,
  [Types.SAVE_USER_DATA_FAILURE]: saveUserDataFailure,
  // Edit user Profile
  [Types.EDIT_USER_DATA_START]: editUserDataStart,
  [Types.EDIT_USER_DATA_SUCCESS]: editUserDataSuccess,
  [Types.EDIT_USER_DATA_FAILURE]: editUserDataFailure,
};
export const saveDataReducer = createReducer(INITIAL_STATE, HANDLERS);
