import {
  Types as userTypes,
  Creators as userCreators,
} from "../pages/users/store/action";
import {
  Types as updateTypes,
  Creators as updateCreators,
} from "../modals/activate-deactivate/store/action";
import {
  Types as loginTypes,
  Creators as loginCreators,
} from "../pages/pagelayout/store/action";
import {
  Types as candidatesTypes,
  Creators as candidatesCreators,
} from "../components/candidates-details/store/action";
import {
  Types as newUserTypes,
  Creators as newUserCreators,
} from "../pages/newuser/store/action";
import {
  Types as newCandidateTypes,
  Creators as newCandidateCreators,
} from "../pages/addCandidate/store/action";
import {
  Types as CandidateProfileTypes,
  Creators as CandidateProfileCreators,
} from "../components/CandidateProfile/store/action";
import {
  Types as testCategoryTypes,
  Creators as testCategoryCreators,
} from "../pages/tests/store/action";
// Types
const Types = {
  ...userTypes,
  ...updateTypes,
  ...loginTypes,
  ...candidatesTypes,
  ...newUserTypes,
  ...newCandidateTypes,
  ...CandidateProfileTypes,
  ...testCategoryTypes,
};
//   Creators
const Creators = {
  ...userCreators,
  ...updateCreators,
  ...loginCreators,
  ...candidatesCreators,
  ...newUserCreators,
  ...newCandidateCreators,
  ...CandidateProfileCreators,
  ...testCategoryCreators,
};
export { Types, Creators };
