// Dependency
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { Slider, Tooltip } from "@material-ui/core";
// StyleSheet
import "./style.css";
// Component
import Button from "../Button";
import UseClickOutside from "../clickoutside/ClickOutside";
import CandidateTable from "../table/CandidateTable";
// Constant
import { Colors } from "../../components/constants/constants.js";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "./store/action";
//FontAwesomeIcons
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faFilterCircleXmark,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
// import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
//
import { TabContainer } from "./InProgress.styled";
import Card from "../card/Card";
// import { ConfirmationContainer } from "../../pages/tests/AddQuestion.Styled";
// import SaveConformation from "../conformationModal/SaveConformation";
import { ConfirmationContainer } from "../../pages/tests/CustomizeQuiz.styled";
import ConformationModal from "../conformationModal/ConformationModal";
import { SignOutButton } from "../signoutbutton/SignOutButton";
// Main function
export default function CandidatesDetails(props) {
  //
  const [val, setVal] = useState([0, 50]);
  const [maxValue, setMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(null);
  const updateRange = (e, data) => {
    setMinValue(data[0]);
    setMaxValue(data[1]);
    setVal(data);
  };
  //

  // variable to make Tabs active
  const [testActive, setTestActive] = useState(true);
  const [assignment1Active, setAssignment1Active] = useState(false);
  const [assignment2Active, setAssignment2Active] = useState(false);
  //
  const [tab, setTab] = useState("aptitudetest");

  //
  const test_Status = ["Taken", "NotTaken"];
  const [isTestStatus, setIsTestStatus] = useState(false);
  const [testStatus, setTestStatus] = useState("Select");
  //
  const [clearFilter, setClearFilter] = useState(false);
  //
  const location = useLocation();
  // variable to store keyword from searchbox
  const [message, setMessage] = useState("");
  // variable to reset the keyword
  //
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  //
  const [candidatesIds, setCandidatesIds] = useState("");
  const [allCheckBox, setAllCheckBox] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  //
  const experience = ["Fresher", "Experience"];
  const [isExperence, setIsExperence] = useState(false);
  const [selectExperience, setSelectExperience] = useState("Select");
  //
  const depart = ["Tech", "Design", "Film", "Marketing", "Enablement"];
  const [isDepart, setDepart] = useState(false);
  const [selectDeprt, setSelectDepart] = useState("Select");
  // Mail send to candidate
  const mail_type = ["Onhold", "Reapply", "Reject"];
  const mail_type1 = [
    "AptitudeTest",
    "Assignment1",
    "Assignment2",
    "Reapply",
    "Reject",
    "Onhold",
    "",
  ];
  const [ismailClicked, setIsmailClicked] = useState(false);
  const [selectmail, setSelectMail] = useState("Select");

  //
  const [ismailTypeClicked, setIsmailTypeClicked] = useState(false);
  //
  const [mailConformation, setMailConformation] = useState(false);
  const [sendMailType, setMailSendType] = useState("");
  // const []
  //
  const [isSearched, setIsSearched] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scoreRef = useRef();
  const candidateData = useSelector(
    (state) => state?.CandidatesDataReducer?.candidatesData
  );

  const loading = useSelector((state) => state?.CandidatesDataReducer?.loading);

  const titleRef = useRef();
  // function search button
  const handleClick = () => {
    setMessage(titleRef.current.value);
    setIsSearched(!isSearched);
  };

  // Dispatching data to saga for sending mail
  const onCancel = () => {
    setMailConformation(false);
    setCandidatesIds("");
  };
  const sendMail = () => {
    // console.log(sendMailType, "sendMailType");
    setMailConformation(false);
    const payload = {
      email: candidatesIds,
      mail_type: sendMailType.toString().toLocaleLowerCase(),
    };
    console.log("send mail type", payload);
    dispatch(Creators.loadEmailData(payload));
    setCandidatesIds([]);
  };

  // Dispatching the data to download the Candidate List
  const downLoadFile = () => {
    const payload = {
      mail_status:
        props.type.mail_status === "Inprogress"
          ? "inprogres"
          : props.type.mail_status === "Old"
          ? "old"
          : false,
    };
    dispatch(Creators.loadExportData(payload));
  };
  // Initial data load and filter applied
  useEffect(() => {
    setCandidatesIds("");
    const params = {
      search: message === "Slect" ? "" : message,

      experience:
        selectExperience.toString() === "Select"
          ? ""
          : selectExperience.toString().toLocaleLowerCase(),
      catagory:
        selectDeprt.toString() === "Select"
          ? ""
          : selectDeprt.toString().toLocaleLowerCase(),
      mail_type:
        props.type.mail_status === "Inprogress"
          ? tab
          : selectmail.toString().toLocaleLowerCase(),
      inProgress: props.type.mail_status === "Inprogress" ? "inProgress" : "",
      mail_status: props.type.mail_status === "New" ? false : "",
      oldCandidates: props.type.mail_status === "Old" ? "old" : "",
      test_status:
        testStatus === "Select"
          ? ""
          : tab === "aptitudetest"
          ? testStatus.toString().toLocaleLowerCase()
          : "",

      min_score:
        testStatus.toString().toLocaleLowerCase() === "nottaken"
          ? ""
          : minValue,
      max_score:
        testStatus.toString().toLocaleLowerCase() === "nottaken"
          ? ""
          : maxValue,
      assignment1_status:
        tab === "assignment1" ? testStatus.toString().toLocaleLowerCase() : "",
      assignment2_status:
        tab === "assignment2" ? testStatus.toString().toLocaleLowerCase() : "",
    };

    console.log(params, "params");
    dispatch(Creators.loadCandidatesData(params));
  }, [isSearched]);
  //
  // useEffect(() => {
  //   dispatch(Creators.loadCandidatesDataSuccess({ data: [] }));
  //   setMessage("");
  //   setSelectDepart("Select");
  //   setMinValue(0);
  //   setMaxValue(50);
  //   setVal([0, 50]);
  //   setTestStatus("Select");

  //   titleRef.current.value = "";
  // }, [tab]);
  // For Reset filter on page change
  useEffect(() => {
    setMessage("");
    titleRef.current.value = "";
    setSelectExperience("Select");
    setSelectDepart("Select");
    setSelectMail("Select");
    setTestStatus("Select");
    setMinValue(0);
    setMaxValue(50);
    setVal([0, 50]);
    setCandidatesIds("");
    const params = {
      search: "",
      // score: 60,
      experience: "",
      catagory: "",
      mail_type: props.type.mail_status === "Inprogress" ? tab : "",
      inProgress: props.type.mail_status === "Inprogress" ? "inProgress" : "",
      mail_status: props.type.mail_status === "New" ? false : "",
      oldCandidates: props.type.mail_status === "Old" ? "old" : "",
      // mail_status: props.type.mail_status === "New" ? false : "",
      test_status: "",
    };

    dispatch(Creators.loadCandidatesDataSuccess({ data: [] }));
    dispatch(Creators.loadCandidatesData(params));
  }, [location?.pathname, clearFilter, tab]);
  // for reset filter on tab change

  // const pages = _.range(1, totalPages + 1);
  const selectAllCandidates = (checkbox) => {
    var allCheckBoxes = document.querySelectorAll("input[id=candidates]");
    if (checkbox.target.checked === true) {
      setSelectAll(true);
      allCheckBoxes.forEach(function (checkbox, i) {
        let email_id = candidateData[i].email;
        if (candidatesIds.includes(email_id) === false) {
          setCandidatesIds((prevState) => [...prevState, email_id]);
        }
        checkbox.checked = true;
      });
    } else {
      setSelectAll(false);
      allCheckBoxes.forEach(function (checkbox, i) {
        let email_id = candidateData[i].email;
        if (candidatesIds.includes(email_id) === true) {
          setCandidatesIds((prevState) =>
            prevState.filter((element) => {
              return element !== email_id;
            })
          );
        }
        checkbox.checked = false;
      });
    }
  };
  const handleSelect = (e, email) => {
    const { checked } = e.target;
    if (checked === true) {
      setAllCheckBox(allCheckBox + 1);
      if (candidatesIds.includes(email) === false) {
        setCandidatesIds((prevState) => [...prevState, email]);
      }
    } else {
      if (candidatesIds.includes(email) === true) {
        setAllCheckBox(allCheckBox - 1);
        setCandidatesIds((prevState) =>
          prevState.filter((element) => {
            return element !== email;
          })
        );
      }
    }
  };

  return (
    <div>
      {/* <--------------Tab for header---------------> */}
      <div
        style={{
          marginLeft: "210px",
          display: props.type.mail_status === "Inprogress" ? "" : "none",
          // backgroundColor: "#f8f8f8",
        }}
      >
        <TabContainer>
          <div
            onClick={() => {
              setTestActive(true);
              setAssignment1Active(false);
              setAssignment2Active(false);
              setTab("aptitudetest");
            }}
          >
            <Card
              width={"300px"}
              height={"50px"}
              cardText={"Aptitude"}
              borderRadius={"00px 0px 0px 0px;"}
              bottomBorder={"0px"}
              topBorder={testActive ? "4px" : "0px"}
              decoration={testActive ? "underline" : ""}
              leftBorder={"0px"}
              rightBorder={"0px"}
              backgroudColor={
                testActive ? Colors.activeColor : Colors.filterBackgroundColor
              }
              opacity={testActive ? "1" : "0.5"}
            />
          </div>
          <div
            onClick={() => {
              setTestActive(false);
              setAssignment1Active(true);
              setAssignment2Active(false);
              setTab("assignment1");
            }}
          >
            <Card
              width={"300px"}
              height={"50px"}
              cardText={"Assignment 1"}
              borderRadius={"0px 0px 0px 0px;"}
              bottomBorder={"0px"}
              topBorder={assignment1Active ? "4px" : "0px"}
              decoration={assignment1Active ? "underline" : ""}
              leftBorder={"0px"}
              rightBorder={"0px"}
              backgroudColor={
                assignment1Active
                  ? Colors.activeColor
                  : Colors.filterBackgroundColor
              }
              opacity={assignment1Active ? "1" : "0.5"}
            />
          </div>
          <div
            onClick={() => {
              setTestActive(false);
              setAssignment1Active(false);
              setAssignment2Active(true);
              setTab("assignment2");
            }}
          >
            <Card
              width={"300px"}
              height={"50px"}
              cardText={"Assignment 2"}
              borderRadius={"0px 0px 0px 0px;"}
              bottomBorder={"0px"}
              topBorder={assignment2Active ? "4px" : "0px"}
              decoration={assignment2Active ? "underline" : ""}
              leftBorder={"0px"}
              rightBorder={"0px"}
              backgroudColor={
                assignment2Active
                  ? Colors.activeColor
                  : Colors.filterBackgroundColor
              }
              opacity={assignment2Active ? "1" : "0.5"}
            />
          </div>
        </TabContainer>
      </div>
      {/* <---------------Page Layout --------------> */}
      <div
        className={`${
          props.type.mail_status === "Inprogress"
            ? "candidates-details-inProgress"
            : "candidates-details"
        }`}
      >
        <div
          className={`${
            props.type.mail_status === "Inprogress"
              ? "upload-download"
              : "upload-download-Progress"
          }`}
        >
          <div className="candidate_header">
            {props.type.mail_status} Candidate List
          </div>
          <div className="addAndDownload-btn">
            {/* Emial send */}
            <div className="send_page">
              <UseClickOutside
                onClickOutside={() => {
                  setIsmailTypeClicked(false);
                }}
              >
                <div className="mail-button">
                  {candidatesIds.length > 0 && (
                    <>
                      <button
                        onClick={() => {
                          setIsmailTypeClicked(!ismailTypeClicked);
                        }}
                      >
                        Send mail
                        <FontAwesomeIcon icon={faChevronDown} color={"#FFF"} />
                      </button>

                      <div
                        className="mailDropDown"
                        style={{ display: ismailTypeClicked ? "" : "none" }}
                      >
                        {mail_type1.map((item, id) => (
                          <p
                            className="mailOptions"
                            key={id.toString()}
                            onClick={() => {
                              setMailConformation(true);
                              setMailSendType(item);
                              // sendMail(item);
                            }}
                          >
                            {item}
                          </p>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </UseClickOutside>
            </div>
            {/* Enail send End */}
            <div className="addCandidate">
              <UseClickOutside
                onClickOutside={() => {
                  setIsDropDownOpen(false);
                }}
              >
                <div
                  style={{
                    display: props.type.mail_status === "New" ? "" : "none",
                  }}
                  onClick={() => {
                    setIsDropDownOpen(!isDropDownOpen);
                  }}
                >
                  <Button
                    name={"New Candidate"}
                    icon={faPlus}
                    backGroundColor={Colors.blue}
                  />
                </div>
              </UseClickOutside>
              <div className="addCandidateContainer">
                <div
                  className="addSingleCandidate"
                  style={{
                    display: isDropDownOpen ? "" : "none",
                    // position: "absolute",
                  }}
                  onClick={() => {
                    navigate("/candidates/new-candidate/add-candidate");
                  }}
                >
                  Add Single
                </div>
                <div
                  className="addBulkCandidate"
                  style={{
                    display: isDropDownOpen ? "" : "none",
                    // position: "absolute",
                  }}
                  onClick={() => {
                    navigate("/candidates/upload");
                  }}
                >
                  Add Bulk
                </div>
              </div>
            </div>

            <div onClick={downLoadFile}>
              <Button
                name={"Download"}
                // icon={faPlus}
                backGroundColor={Colors.blue}
              />
            </div>
          </div>
        </div>

        {/* <----------------filterOpen------------------> */}
        <div className="search-filter">
          {/* <------------Seach box input --------------> */}
          <div className="searchbox">
            <label className="search-label">Search:</label>

            <div className="input-div">
              <Tooltip title="Search candidate by name, email, or contact number">
                <input
                  ref={titleRef}
                  style={{ height: "30px" }}
                  // id="search"
                  placeholder="Search"
                ></input>
              </Tooltip>
            </div>
          </div>
          {/* <------------Experience Filter --------------> */}
          <div
            className="searchbox"
            style={{
              display: props.type.mail_status === "Inprogress" ? "none" : "",
            }}
          >
            <div style={{ color: "#3a3a3a", paddingRight: "10px" }}>
              Experience :
            </div>
            <UseClickOutside
              onClickOutside={() => {
                setIsExperence(false);
              }}
            >
              <div
                className="DropDown-btn"
                onClick={() => {
                  setIsExperence(!isExperence);
                }}
              >
                <div>{selectExperience}</div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                {isExperence && (
                  <div className="SelectOption">
                    {experience.map((item, id) => (
                      <p
                        style={{ paddingLeft: "10px" }}
                        key={id.toString()}
                        onClick={() => {
                          setSelectExperience(item);
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </UseClickOutside>
            {/* <div style={{}}></div> */}
          </div>
          {/* <------------Department Filter --------------> */}
          <div className="searchbox">
            <div style={{ color: "#3a3a3a", paddingRight: "10px" }}>
              Department :
            </div>
            <UseClickOutside
              onClickOutside={() => {
                setDepart(false);
              }}
            >
              <div
                className="DropDown-btn"
                onClick={() => {
                  setDepart(!isDepart);
                  // setPositionOptionCLicked(!PositionOptionClicked);
                }}
              >
                <div>{selectDeprt}</div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>

                {isDepart && (
                  <div className="SelectOption">
                    {depart.map((item, id) => (
                      <p
                        style={{ paddingLeft: "10px" }}
                        key={id.toString()}
                        onClick={() => {
                          setSelectDepart(item);
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </UseClickOutside>
          </div>
          {/* <------------Type Filter --------------> */}
          <div
            className="searchbox"
            style={{ display: props.type.mail_status === "Old" ? "" : "none" }}
          >
            <div style={{ color: "#3a3a3a", paddingRight: "10px" }}>Type :</div>
            <UseClickOutside
              onClickOutside={() => {
                setIsmailClicked(false);
              }}
            >
              <div
                className="DropDown-btn"
                onClick={() => {
                  // setPositionOptionCLicked(!PositionOptionClicked);
                  setIsmailClicked(!ismailClicked);
                }}
              >
                <div style={{ color: "#3a3a3a", paddingRight: "10px" }}>
                  {selectmail}
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                {ismailClicked && (
                  <div className="SelectOption" style={{}}>
                    {mail_type.map((item, id) => (
                      <p
                        style={{ paddingLeft: "10px" }}
                        key={id.toString()}
                        onClick={() => {
                          setSelectMail(item);
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </UseClickOutside>
          </div>

          {/* <------------Score Filter -------------->  */}
          <div
            className="searchbox"
            style={{
              display: props.type.mail_status === "Inprogress" ? "" : "none",
            }}
            ref={scoreRef}
          >
            <div
              style={{
                color: "#3a3a3a",
                paddingRight: "10px",
                display: "flex",
              }}
            >
              Score :
            </div>
            <div className="score-slider">
              <Tooltip title="Score filter">
                <Slider
                  value={val}
                  onChange={updateRange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={50}
                />
              </Tooltip>
            </div>
          </div>
          {/* <------------Test Status Filter -------------->  */}
          <div
            className="searchbox"
            style={{
              display: props.type.mail_status === "Inprogress" ? "" : "none",
            }}
            // ref={scoreRef}
          >
            <div
              style={{
                color: "#3a3a3a",
                paddingRight: "10px",
                display: "flex",
              }}
            >
              Test Status :
            </div>
            <UseClickOutside
              onClickOutside={() => {
                setIsTestStatus(false);
              }}
              modal="score"
            >
              <div
                className="DropDown-btn"
                onClick={() => {
                  // setPositionOptionCLicked(!PositionOptionClicked);
                  setIsTestStatus(!isTestStatus);
                }}
              >
                <div style={{ color: "#3a3a3a", paddingRight: "10px" }}>
                  {testStatus}
                </div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                {isTestStatus && (
                  <div className="SelectOption">
                    {test_Status.map((item, id) => (
                      <p
                        style={{ paddingLeft: "10px" }}
                        key={id.toString()}
                        onClick={() => {
                          setTestStatus(item);
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </UseClickOutside>
          </div>
          {/* <------------Search button-------------->  */}
          <div className="search-button" onClick={handleClick}>
            <Button
              name={"Search"}
              // icon={faPlus}
              backGroundColor={Colors.blue}
              width={"100px"}
              height={"30px"}
            />
          </div>
          {/* <------------Clear search filter button-------------->  */}
          <Tooltip title="Clear filter">
            <div
              className="search-button"
              onClick={() => {
                setClearFilter(!clearFilter);
              }}
            >
              <Button
                // name={"Clear"}
                icon={faFilterCircleXmark}
                backGroundColor={Colors.blue}
                width={"100px"}
                height={"30px"}
              />
            </div>
          </Tooltip>
        </div>
        {/* <------------Candidate Table--------------> */}
        <div className="candidate_list">
          {loading ? (
            //<----------------Loder----------------->
            <div className="loader"></div>
          ) : (
            <CandidateTable
              type={props.type.mail_status}
              tab={tab}
              // perpage={perpage}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              // currentPage={currentPage}
              candidatesIds={candidatesIds}
              setCandidatesIds={setCandidatesIds}
              selectAllCandidates={selectAllCandidates}
              paginatedCandidates={candidateData}
              handleSelect={handleSelect}
              allCheckBox={allCheckBox}
            ></CandidateTable>
          )}
        </div>
      </div>
      {mailConformation && (
        <SignOutButton
          noButton={onCancel}
          yesButton={sendMail}
          text={"Are you sure you want to send mail ?"}
        />
      )}
    </div>
  );
}
