// Dependency
import React, { useEffect } from "react";
import { useState } from "react";
import "./CandidateFormstyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useImperativeHandle } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Component
import UseClickOutside from "../../components/clickoutside/ClickOutside";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "./store/action";
import moment from "moment";
import { ConfirmationContainer } from "../tests/AddQuestion.Styled";
import SaveConformation from "../../components/conformationModal/SaveConformation";
// function CandidateForm(props) {
const CandidateForm = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    onClick() {
      props.candidateDate ? OnEditSave() : OnSave();
    },
  }));

  // Add success
  const addSuccess = useSelector(
    (state) => state?.NewCandidatesDataReducer?.newCandidateAdded
  );
  // Edit Success
  const editSuccess = useSelector(
    (state) => state?.NewCandidatesDataReducer?.candidateedited
  );

  const DepartmantOptions = [
    "Tech",
    "Design",
    "Film",
    "Marketing",
    "Enablement",
  ];

  const Result = ["Pass", "Fail"];
  const PositionOption = ["Jr. Software Developer", "Sr. Software Developer"];
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  //  Set Variable
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [currentCTC, setCurrentCTC] = useState(null);
  const [expectedCTC, setExpectedCTC] = useState(null);
  const [experience, setExperience] = useState(null);
  const [mobileNo, setmobileNo] = useState("");
  const [noticePeriod, setNoticePeriod] = useState(null);
  // const [isAdded, setIsAdded] = useState(false);
  //

  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNoValid, setIsNoValid] = useState(true);
  const [isCurrentCTCValid, setIsCurrentCTCValid] = useState(true);
  const [isExpectedCTCValid, setIsExpectedCTCValid] = useState(true);
  const [isExperienceValid, setIsExperienceValid] = useState(true);
  //
  const [isDeprtmentselected, setIsDeprtmentselected] = useState(true);
  const [isPositionselected, setIsPositionselected] = useState(true);

  const [isNoticeselected, setIsNoticelected] = useState(true);

  //
  const [departmantOptionClicked, setdepartmentOptionCLicked] = useState(false);
  const [PositionOptionClicked, setPositionOptionCLicked] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(true);
  //
  const [department, setDepartment] = useState("Select");

  const [position, setposition] = useState("");
  // Redux
  const dispatch = useDispatch();
  const changeValue = (e) => {
    setFirstName(e.target.value);
  };

  //SetState variable to update the Candidate Profile
  const [profile, setProfile] = useState({
    // Variable for Result
    result: "Select",

    //Variable for assignment1
    assignment1Score: "",
    assignment1Result: "Select",
    assignment1DueDate: null,
    assignment1SubmitDate: "",
    //Variable for assignment2
    assignment2Score: null,
    assignment2Result: "Select",
    assignment2DueDate: null,
    assignment2SubmitDate: "",
  });
  // SetState Variable to open the dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    Assignment1ResultDropdown: false,
    Assignment2ResultDropdown: false,
    ResultDropdown: false,
  });

  //

  // Set Variable to validate the Required input feild
  useEffect(() => {
    dispatch(Creators.resetCandaidateStatus());
    if (props.candidateDate) {
      setDepartment(props.candidateDate.candidateData.catagory);
      setExperience(props.candidateDate.candidateData.experience);
      // setMailStatus(props.candidateDate.candidateData.catagor);
      setposition(props.candidateDate.candidateData.position);
      //
      setFirstName(props.candidateDate.candidateData.fullName);
      setEmail(props.candidateDate.candidateData.email);
      setCurrentCTC(props.candidateDate.candidateData.currentCtc);
      setExpectedCTC(props.candidateDate.candidateData.expectedCtc);
      setmobileNo(props.candidateDate.candidateData.phoneNumber);
      setNoticePeriod(props.candidateDate.candidateData.noticePeriod);
      // Assignment1
      setProfile({
        ...profile,
        result: props.candidateDate.candidateData.result,
        assignment1Score: props.candidateDate.candidateData.assignment1Score,
        assignment1Result: props.candidateDate.candidateData.assignment1Result,
        assignment1DueDate:
          props.candidateDate.candidateData.assignment1Duedate,
        assignment1SubmitDate:
          props.candidateDate.candidateData.assignment1MailsentTime === null
            ? null
            : moment(
                props.candidateDate.candidateData.assignment1MailsentTime
              ).format("YYYY-MM-DD"),
        assignment2Score: props.candidateDate.candidateData.assignment2Score,
        assignment2Result: props.candidateDate.candidateData.assignment2Result,
        assignment2DueDate:
          props.candidateDate.candidateData.assignment2Duedate,
        assignment2SubmitDate:
          props.candidateDate.candidateData.assignment2MailsentTime === null
            ? null
            : moment(
                props.candidateDate.candidateData.assignment2MailsentTime
              ).format("YYYY-MM-DD"),
      });
    }
  }, []);
  //

  const OnEditSave = () => {
    // e.preventDefault();
    // const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const payload = {
      fullName: `${firstName} ${lastName}`,
      email: email,
      phoneNumber: mobileNo,
      catagory: department,
      currentCtc: currentCTC,
      expectedCtc: expectedCTC,
      position: position,
      noticePeriod: noticePeriod,
      CV: selectedFile,
      experience: experience,
      assignment1Score: profile.assignment1Score,
      assignment1Result:
        profile.assignment1Result === "Select"
          ? null
          : profile.assignment1Result,
      assignment1Duedate: profile.assignment1DueDate,
      assignment1Submitdate: profile.assignment1SubmitDate,
      assignment2Score: profile.assignment2Score,
      assignment2Result:
        profile.assignment2Result === "Select"
          ? null
          : profile.assignment2Result,
      assignment2Duedate: profile.assignment2DueDate,
      assignment2Submitdate: profile.assignment2SubmitDate,
      result: profile.result === "Select" ? "" : profile.result,
    };
    if (firstName.length > 1) {
      setIsNameValid(true);
      if (mobileNo.length === 10) {
        setIsNoValid(true);
        if (currentCTC >= 0 && currentCTC != null) {
          setIsCurrentCTCValid(true);
          if (expectedCTC >= 0) {
            setIsExpectedCTCValid(true);
            if (experience >= 0 && experience !== null) {
              setIsExperienceValid(true);
              if (noticePeriod >= 0 && noticePeriod != null) {
                setIsNoticelected(true);
                if (position !== "") {
                  setIsPositionselected(true);
                  dispatch(
                    Creators.loadEditCandidatesData({
                      payload,
                      candidtaeId: props.candidateDate.cnadidate_id,
                    })
                  );
                } else {
                  setIsPositionselected(false);
                }
              } else {
                setIsNoticelected(false);
              }
            } else {
              setIsExperienceValid(false);
            }
          } else {
            setIsExpectedCTCValid(false);
          }
        } else {
          setIsCurrentCTCValid(false);
        }
      } else {
        setIsNoValid(false);
      }
    } else {
      setIsNameValid(false);
    }
  };
  const OnSave = () => {
    // Form Data
    const fileUpload = new FormData();
    fileUpload.append("fullName", `${firstName} ${lastName}`);
    // fileUpload.append()
    fileUpload.append("email", email);
    fileUpload.append("phoneNumber", mobileNo);
    fileUpload.append("catagory", department.toString().toLowerCase());
    fileUpload.append("expectedCtc", expectedCTC);
    fileUpload.append("currentCtc", currentCTC);
    fileUpload.append("position", position);
    fileUpload.append("noticePeriod", noticePeriod);
    fileUpload.append("CV", selectedFile);
    fileUpload.append("experience", experience);

    const payload = {
      fileUpload,
    };
    // Form Validation---------->
    if (firstName.length > 1) {
      setIsNameValid(true);
      if (email.match(mailformat)) {
        setIsEmailValid(true);
        if (mobileNo.length === 10 && mobileNo.length !== null) {
          setIsNoValid(true);
          if (currentCTC >= 0 && currentCTC !== null) {
            setIsCurrentCTCValid(true);
            if (expectedCTC >= 0 && expectedCTC !== null) {
              setIsExpectedCTCValid(true);
              if (department !== "Select" && department !== " ") {
                setIsDeprtmentselected(true);
                if (experience >= 0 && experience !== null) {
                  setIsExperienceValid(true);
                  if (noticePeriod >= 0 && noticePeriod !== null) {
                    // console.log(noticePeriod);
                    setIsNoticelected(true);
                    if (position !== "") {
                      setIsPositionselected(true);
                      if (selectedFile) {
                        setIsFilePicked(true);
                        // Dispatch the data
                        dispatch(Creators.loadNewCandidatesData(payload));
                      } else {
                        setIsFilePicked(false);
                      }
                    } else {
                      setIsPositionselected(false);
                    }
                  } else {
                    setIsNoticelected(false);
                  }
                } else {
                  setIsExperienceValid(false);
                }
              } else {
                setIsDeprtmentselected(false);
              }
            } else {
              setIsExpectedCTCValid(false);
            }
          } else {
            setIsCurrentCTCValid(false);
          }
        } else {
          setIsNoValid(false);
        }
      } else {
        setIsEmailValid(false);
      }
    } else {
      setIsNameValid(false);
    }
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);

    handleSubmission();
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append("File", selectedFile);
  };

  return (
    <div className="newcandidateform">
      <form>
        {/* <----------------Candidate name--------------> */}
        <div className="boxContainer">
          <div className="firstname">
            <div className="lable">
              First name<span style={{ color: "red" }}>*</span>
            </div>

            <div className="input">
              <input
                className="forn-input"
                type="text"
                name="name"
                placeholder="First name"
                value={firstName}
                required
                onChange={(e) => {
                  changeValue(e);
                }}
              />
            </div>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                display: isNameValid ? "none" : "",
              }}
            >
              Required
            </div>
          </div>
          <div className="lastname">
            <div>Last name</div>

            <div className="input">
              <input
                className="forn-input"
                type="text"
                name="name"
                placeholder="Last name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>
        </div>
        {/* <----------------Contact Info-----------------> */}
        <div className="boxContainer">
          <div className="firstname">
            <div className="lable">
              Email<span style={{ color: "red" }}>*</span>
            </div>
            <div className="input">
              <input
                className="forn-input"
                type="text"
                name="name"
                disabled={props.candidateDate ? true : false}
                placeholder="Enter email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div
              className={`${
                isEmailValid ? "filledRequiredField" : "unfilledRequiredField"
              }`}
            >
              Required
            </div>
          </div>
          <div className="lastname">
            <div>
              Contact No(+91)<span style={{ color: "red" }}>*</span>
            </div>

            <div className="input">
              <input
                className="forn-input"
                type="number"
                name="name"
                maxlength="10"
                value={mobileNo}
                onChange={(e) => {
                  setmobileNo(e.target.value);
                }}
              />
            </div>
            <div
              className={`${
                isNoValid ? "filledRequiredField" : "unfilledRequiredField"
              }`}
            >
              Enter Valid Mobile no.
            </div>
          </div>
        </div>
        {/* <--------------Current CTC and Expected CTC-------------> */}
        <div className="boxContainer">
          <div className="firstname">
            <div className="lable">
              Current CTC (LPA)<span style={{ color: "red" }}>*</span>
            </div>

            <div className="input">
              <input
                className="forn-input"
                type="number"
                name="name"
                onChange={(e) => setCurrentCTC(parseFloat(e.target.value))}
                value={currentCTC}
              />
            </div>
            <div
              className={`${
                isCurrentCTCValid
                  ? "filledRequiredField"
                  : "unfilledRequiredField"
              }`}
            >
              Required
            </div>
          </div>
          <div className="lastname">
            <div>
              Expected CTC (LPA)<span style={{ color: "red" }}>*</span>
            </div>

            <div className="input">
              <input
                className="forn-input"
                type="number"
                name="name"
                onChange={(e) => setExpectedCTC(parseFloat(e.target.value, 10))}
                value={expectedCTC}
              />
            </div>
            <div
              className={`${
                isExpectedCTCValid
                  ? "filledRequiredField"
                  : "unfilledRequiredField"
              }`}
            >
              Required
            </div>
          </div>
        </div>
        {/* <---------------Department and Experience-------------->  */}
        <div className="boxContainer">
          <div className="dropDownAndDateInputBox">
            Department<span style={{ color: "red" }}>*</span>
            <UseClickOutside
              onClickOutside={() => {
                setdepartmentOptionCLicked(false);
              }}
            >
              <div
                className="departmentDropDown-btn"
                onClick={() => {
                  setdepartmentOptionCLicked(!departmantOptionClicked);
                }}
              >
                <div>{department}</div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
              <div
                className={`${
                  isDeprtmentselected
                    ? "filledRequiredField"
                    : "unfilledRequiredField"
                }`}
              >
                Required
              </div>

              {departmantOptionClicked && (
                <div className="SelectOptions">
                  {DepartmantOptions.map((item, id) => (
                    <p
                      className="dropdownOption"
                      key={id.toString()}
                      onClick={() => {
                        setDepartment(item);
                        setIsDeprtmentselected(true);
                        setdepartmentOptionCLicked(!departmantOptionClicked);
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              )}
            </UseClickOutside>
          </div>
          {/* <---------------Experience-----------------> */}
          <div className="lastname">
            <div>
              Experience CTC (Year)<span style={{ color: "red" }}>*</span>
            </div>

            <div className="input">
              <input
                className="forn-input"
                type="number"
                name="name"
                onChange={(e) => setExperience(parseFloat(e.target.value, 10))}
                value={experience}
              />
            </div>
            <div
              className={`${
                isExperienceValid
                  ? "filledRequiredField"
                  : "unfilledRequiredField"
              }`}
            >
              Required
            </div>
          </div>
        </div>
        {/* <---------------Notice period and position------------------> */}
        <div className="boxContainer">
          <div className="dropDownAndDateInputBox">
            <div className="Notice_period">
              <div>
                Notice Period (in Days) <span style={{ color: "red" }}>*</span>
              </div>

              <div className="input">
                <input
                  className="forn-input"
                  type="number"
                  name="name"
                  onChange={(e) => setNoticePeriod(parseInt(e.target.value))}
                  value={noticePeriod}
                />
              </div>
              <div
                className={`${
                  isNoticeselected
                    ? "filledRequiredField"
                    : "unfilledRequiredField"
                }`}
              >
                Required
              </div>
            </div>
          </div>
          {/* <----------------Position Dropdown-----------------> */}
          <div className="lastname">
            <div>
              Position <span style={{ color: "red" }}>*</span>
            </div>

            <div className="input">
              <input
                className="forn-input"
                type="text"
                name="name"
                onChange={(e) => setposition(e.target.value)}
                value={position}
              />
            </div>
            <div
              className={`${
                isPositionselected
                  ? "filledRequiredField"
                  : "unfilledRequiredField"
              }`}
            >
              Required
            </div>
          </div>
          {/* <div className="PostiondropDown">
            Position <span style={{ color: "red" }}>*</span>
            <UseClickOutside
              onClickOutside={() => {
                setPositionOptionCLicked(false);
              }}
            >
              <div
                className="departmentDropDown-btn"
                onClick={() => {
                  setPositionOptionCLicked(!PositionOptionClicked);
                }}
              >
                <div>{position}</div>
                <div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>

              {PositionOptionClicked && (
                <div className="SelectOptions">
                  {PositionOption.map((item, id) => (
                    <p
                      className="dropdownOption"
                      key={id.toString()}
                      onClick={() => {
                        setposition(item);
                        setIsPositionselected(true);
                        setPositionOptionCLicked(!PositionOptionClicked);
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              )}
            </UseClickOutside>
            <div
              className={`${
                isPositionselected
                  ? "filledRequiredField"
                  : "unfilledRequiredField"
              }`}
            >
              Required
            </div>
          </div> */}
        </div>
        {/* <-----------------Apptitude Result--------------------> */}
        <div style={{ display: props.candidateDate ? "" : "none" }}>
          <div
            style={{
              display:
                props?.candidateDate?.candidateData?.mailType === "aptitudetest"
                  ? ""
                  : props?.candidateDate?.candidateData?.mailType ===
                    "assignment1"
                  ? ""
                  : props?.candidateDate?.candidateData?.mailType ===
                    "assignment2"
                  ? ""
                  : "none",
            }}
          >
            <div className="boxContainer">
              <div className="dropDownAndDateInputBox">
                <div className="Notice_period">
                  <div>Aptitude Score</div>
                  <div className="input">
                    <p>{props?.candidateDate?.candidateData?.score}</p>
                  </div>
                </div>
              </div>
              {/* <----------------Apptitude  Result-------------------> */}

              <div className="PostiondropDown">
                Aptitude Result
                <div
                  className="departmentDropDown-btn"
                  onClick={() => {
                    setIsDropdownOpen({
                      ...profile,
                      ResultDropdown: !isDropdownOpen.ResultDropdown,
                    });
                  }}
                >
                  <div>{profile.result}</div>
                  <div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
                {isDropdownOpen.ResultDropdown && (
                  <div className="SelectOptions">
                    {Result.map((item, id) => (
                      <p
                        className="dropdownOption"
                        key={id.toString()}
                        onClick={() => {
                          setProfile({
                            ...profile,
                            result: item,
                          });
                          setIsDropdownOpen({
                            ...profile,
                            ResultDropdown: false,
                          });
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <----------------Assignment 1 Details---------------------> */}

          <div
            style={{
              display:
                props?.candidateDate?.candidateData?.mailType === "assignment1"
                  ? ""
                  : props?.candidateDate?.candidateData?.mailType ===
                    "assignment2"
                  ? ""
                  : "none",
            }}
          >
            {/* <----------------Due date and Submit date---------------------> */}
            <div className="boxContainer">
              <div className="dropDownAndDateInputBox">
                <div className="Notice_period">
                  <div>Assignment 1 Due date</div>
                  <div>
                    <input
                      className="dateInput"
                      type="date"
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          assignment1DueDate: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </div>
              </div>
              <div className="PostiondropDown">
                Assignment 1 Submit Date:
                <div>
                  <input
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        assignment1SubmitDate: e.target.value,
                      })
                    }
                    className="dateInput"
                    type="date"
                  ></input>
                </div>
              </div>
            </div>
            {/* <----------------Score and Result-------------------------> */}
            <div className="boxContainer">
              <div className="dropDownAndDateInputBox">
                <div className="Notice_period">
                  <div>Assignment 1 Score</div>
                  <div className="input">
                    <input
                      className="forn-input"
                      type="number"
                      name="name"
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          assignment1Score: parseFloat(e.target.value),
                        })
                      }
                      defaultValue={profile.assignment1Score}
                    />
                  </div>
                </div>
              </div>
              {/* <----------------Assignment 1 Result-------------------> */}
              <div className="PostiondropDown">
                Assignment 1 Result:
                <div
                  className="departmentDropDown-btn"
                  onClick={() => {
                    setIsDropdownOpen({
                      ...profile,
                      Assignment1ResultDropdown:
                        !isDropdownOpen.Assignment1ResultDropdown,
                    });
                  }}
                >
                  <div>{profile.assignment1Result}</div>
                  <div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
                {/* <br /> */}
                {isDropdownOpen.Assignment1ResultDropdown && (
                  <div className="SelectOptions">
                    {Result.map((item, id) => (
                      <p
                        className="dropdownOption"
                        key={id.toString()}
                        onClick={() => {
                          setProfile({
                            ...profile,
                            assignment1Result: item,
                          });
                          setIsDropdownOpen({
                            ...profile,
                            Assignment1ResultDropdown: false,
                          });
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <----------------Assignment 2 Details---------------------> */}
          <div
            style={{
              display:
                props?.candidateDate?.candidateData?.mailType === "assignment2"
                  ? ""
                  : "none",
            }}
          >
            {/* <----------------Due date and Submit date---------------------> */}
            <div className="boxContainer">
              <div className="dropDownAndDateInputBox">
                <div className="Notice_period">
                  <div>Assignment 2 Due date</div>
                  <div>
                    <input
                      className="dateInput"
                      type="date"
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          assignment2DueDate: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                </div>
              </div>
              <div className="PostiondropDown">
                Assignment 1 Submit Date:
                <div>
                  <input
                    onChange={(e) =>
                      setProfile({
                        ...profile,
                        assignment2SubmitDate: e.target.value,
                      })
                    }
                    className="dateInput"
                    type="date"
                  ></input>
                </div>
              </div>
            </div>
            {/* <----------------Score and Result-------------------------> */}
            <div className="boxContainer">
              <div className="dropDownAndDateInputBox">
                <div className="Notice_period">
                  <div>Assignment 2 Score</div>
                  <div className="input">
                    <input
                      className="forn-input"
                      type="number"
                      name="name"
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          assignment2Score: parseFloat(e.target.value),
                        })
                      }
                      defaultValue={profile.assignment2Score}
                    />
                  </div>
                </div>
              </div>
              <div className="PostiondropDown">
                Assignment 2 Result:
                <div
                  className="departmentDropDown-btn"
                  onClick={() => {
                    setIsDropdownOpen({
                      ...profile,
                      Assignment2ResultDropdown:
                        !isDropdownOpen.Assignment2ResultDropdown,
                    });
                  }}
                >
                  <div>{profile.assignment2Result}</div>
                  <div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
                {/* <br /> */}
                {isDropdownOpen.Assignment2ResultDropdown && (
                  <div className="SelectOptions">
                    {Result.map((item, id) => (
                      <p
                        className="dropdownOption"
                        key={id.toString()}
                        onClick={() => {
                          setProfile({
                            ...profile,
                            assignment2Result: item,
                          });
                          setIsDropdownOpen({
                            ...profile,
                            Assignment2ResultDropdown: false,
                          });
                          // setPositionOptionCLicked(!PositionOptionClicked);
                        }}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*<----------------------- Resueme------------------------>  */}
        <div className={props.candidateDate ? "disabledCV" : "UploadCV"}>
          <div className="resume">
            <div className="lable">
              Attach Resume <span style={{ color: "red" }}>*</span>
            </div>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                display: isFilePicked ? "none" : "",
              }}
            >
              Required
            </div>
            <input
              type="file"
              accept="application/pdf,.doc,.docx,"
              onChange={changeHandler}
            />
          </div>
        </div>
        {/* <-------------Modal for Add new candidate PopUP--------------> */}
        {addSuccess && (
          <ConfirmationContainer>
            <SaveConformation text={"Candidate profile added successfully"} />
          </ConfirmationContainer>
        )}
        {/* <-------------Modal for Edit PopUP--------------> */}
        {editSuccess && (
          <ConfirmationContainer>
            <SaveConformation text={"Candidate profile updated successfully"} />
          </ConfirmationContainer>
        )}
      </form>
    </div>
  );
});
export default CandidateForm;
