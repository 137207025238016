import { createActions } from "reduxsauce";
const { Types, Creators } = createActions({
  saveUserData: ["payload"],
  saveUserDataStart: ["payload"],
  saveUserDataSuccess: ["payload"],
  saveUserDataFailure: ["payload"],
  // Edit User Actions
  editUserData: ["payload"],
  editUserDataStart: ["payload"],
  editUserDataSuccess: ["payload"],
  editUserDataFailure: ["payload"],
});

export { Types, Creators };
