import { WebService } from "./webServices";

const setAccessTokenToHeader = (token) => {
  if (token) {
    WebService.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete WebService.defaults.headers.Authorization;
  }
};
export default setAccessTokenToHeader;
