import styled from "styled-components";
// import { Colors } from "../components/constants/constants";

export const CandidateDetailsProfileContainer = styled.div`
  margin-top: 10px;
  margin-left: 210px;
  margin-right: 8px;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top: 3px solid #0ab5a9;
  border-radius: 8px;
  height: 97vh;
  background-color: #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const CandidateTopbar = styled.div`
  position: fixed;
  width: calc(100% - 219px);

  display: flex;
  justify-content: space-between;
  background-color: #eeeff5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 50px;

  align-items: center;
`;
export const TopbarProfileName = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
`;
export const TopbarProfileEdit = styled.div`
  display: flex;
  margin-right: 20px;
  gap: 20px;
`;
export const CandidateProfile = styled.div``;
export const PersonalProfileContact = styled.div`
  border-bottom-style: dashed;
  color: #707070;
  padding-bottom: 30px;
  margin: 20px;
  margin-top: 30px;
`;
export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: larger;
`;
export const Title = styled.div`
  display: flex;
  flex: 2;
  color: #717171;
`;
export const Value = styled.div`
  color: #393939;
  flex: 8;
  font-size: 600;
`;

export const CandidatePairs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
`;
export const Dropdown = styled.div`
  position: "relative";
  display: flex;
  width: 120px;
  cursor: pointer;
  border: 1px solid #e1e2e2;
  color: #545555;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 15px;
  background-color: #fff;
`;
export const ResultOption = styled.div`
  position: absolute;
  flex-direction: column;
  margin-top: 25px;
  background-color: #f8f8f8;
  margin-left: -10px;
  border: 2px solid silver;
  background-color: #f8f8f8;
  z-index: 1;
  width: 120px;
`;
