// Dependecy
import React, { useEffect, useState } from "react";
import moment from "moment";
// Constant
import { Colors } from "../constants/constants";
//
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
// Component
import Button from "../Button";

import Sidebar from "../sidebar/SideBar";
import UseClickOutside from "../clickoutside/ClickOutside";
//FontAwesomeIcons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//

import {
  faChevronLeft,
  faDownload,
  faEdit,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "./store/action";
import { Creators as mailCreators } from ".././candidates-details/store/action";

// CSS
import "./CandidateProfileStyle.css";
import {
  CandidateDetailsProfileContainer,
  CandidateTopbar,
  TopbarProfileEdit,
  TopbarProfileName,
  PersonalProfileContact,
  ProfileHeader,
  Title,
  Value,
  CandidatePairs,
  // MailDropDown,
} from "./Candidate.Styled";
import { SignOutButton } from "../signoutbutton/SignOutButton";
//
function CandidateProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const candidateData = useSelector(
    (state) => state?.CandidatesProfileReducer?.candidateData
  );
  //
  const [mailConformation, setMailConformation] = useState(false);
  const [sendMailSendType, setSendMailSendType] = useState("");
  // Dispatching the data to call the api
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = state;
  useEffect(() => {
    dispatch(Creators.loadCandidatesProfileData({ id }));
  }, []);
  //Possible mail that Hr can sent to user
  const mail_type1 = [
    "AptitudeTest",
    "Assignment1",
    "Assignment2",
    "Reapply",
    "Reject",
    "Onhold",
    "",
  ];
  //
  const [ismailTypeClicked, setIsmailTypeClicked] = useState(false);
  const onCancel = () => {
    setMailConformation(false);
  };
  // Send mail to Candidate
  const sendMail = () => {
    setMailConformation(false);
    const payload = {
      email: [candidateData.email],
      mail_type: sendMailSendType.toString().toLocaleLowerCase(),
    };

    dispatch(mailCreators.loadEmailData(payload));
    // setCandidatesIds([]);
  };
  return (
    <div>
      {/* <------------------Sidebar--------------> */}
      <Sidebar></Sidebar>

      {/* <-----------Candidate Profile Name------------> */}
      <CandidateDetailsProfileContainer>
        {/* <--------------Candidate Profile Topbar---------------> */}
        <CandidateTopbar>
          {/* <---------------Candidate Profile Name----------------> */}
          <TopbarProfileName>
            <FontAwesomeIcon
              style={{ cursor: "pointer", paddingRight: "10px" }}
              icon={faChevronLeft}
              onClick={() => {
                navigate(-1);
              }}
            ></FontAwesomeIcon>
            {candidateData.fullName} Profile
          </TopbarProfileName>
          {/* <---------------Candidate Edit Profile----------------> */}
          <TopbarProfileEdit>
            {/* <-------------Send mail to Candidate---------------> */}
            <div>
              <UseClickOutside
                onClickOutside={() => {
                  setIsmailTypeClicked(false);
                }}
              >
                <div
                  onClick={() => {
                    setIsmailTypeClicked(!ismailTypeClicked);
                  }}
                >
                  <Button
                    name={"Send mail"}
                    backGroundColor={Colors.blue}
                    width={"100px"}
                    height={"30px"}
                  />
                </div>

                <div
                  className="mailDropDown"
                  style={{ display: ismailTypeClicked ? "" : "none" }}
                >
                  {/* <MailDropDown> */}
                  {mail_type1.map((item, id) => (
                    <p
                      style={{ cursor: "pointer" }}
                      key={id.toString()}
                      onClick={() => {
                        setIsmailTypeClicked(false);
                        setMailConformation(true);
                        // sendMail(item);
                        setSendMailSendType(item);
                      }}
                    >
                      {item}
                    </p>
                  ))}
                  {/* </MailDropDown> */}
                </div>
              </UseClickOutside>
            </div>
            {/* <-------------Edit Candidate---------------> */}
            <div
              onClick={() => {
                navigate(`${location.pathname}edit-candidate/`, {
                  state: { candidateData: candidateData, cnadidate_id: id },
                });
              }}
            >
              <Button
                name={"Edit"}
                // icon={faPlus}
                icon={faEdit}
                backGroundColor={Colors.blue}
                width={"100px"}
                height={"30px"}
              />
            </div>
          </TopbarProfileEdit>
        </CandidateTopbar>
        {/* <-----------------Personal Profile----------------> */}
        <PersonalProfileContact>
          {/* <-------------Profile Header----------------> */}
          <ProfileHeader>:</ProfileHeader>
          {/* <-------------Candidate Profile Name----------------> */}
          <CandidatePairs>
            <Title>Name :</Title>
            <Value>{candidateData.fullName}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Email----------------> */}
          <CandidatePairs>
            <Title>Email :</Title>
            <Value>{candidateData.email}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Contact No.----------------> */}
          <CandidatePairs>
            <Title>Contact No :</Title>
            <Value>{candidateData.phoneNumber}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Category----------------> */}
          <CandidatePairs>
            <Title>Department :</Title>
            <Value>{candidateData.catagory}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Position-----------------> */}
          <CandidatePairs>
            <Title>Position :</Title>
            <Value>{candidateData.position}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Experience----------------> */}
          <CandidatePairs>
            <Title>Experience :</Title>
            <Value>{candidateData.experience}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Curretn CTC----------------> */}
          <CandidatePairs>
            <Title>Current CTC :</Title>
            <Value>{candidateData.currentCtc}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Expected CTC----------------> */}
          <CandidatePairs>
            <Title>Expected CTC :</Title>
            <Value>{candidateData.expectedCtc}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Notice Period----------------> */}
          <CandidatePairs>
            <Title>Notice Period :</Title>
            <Value>{candidateData.noticePeriod}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Resume/CV----------------> */}
          <CandidatePairs>
            <Title>Resume/CV :</Title>
            <Value>
              {" "}
              <a
                className="Resume-viewer-container"
                href={`https://hrportal.panorbitprojects.com${candidateData.CV}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  name={"View Resume"}
                  icon={faDownload}
                  backGroundColor={Colors.blue}
                  width={"150px"}
                  height={"40px"}
                />
              </a>
            </Value>
          </CandidatePairs>
        </PersonalProfileContact>
        {/* <-----------------professional Profile----------------> */}

        {/* <-----------------Aptitude Timeline----------------> */}
        <PersonalProfileContact
          style={{
            display:
              candidateData.mailType === "aptitudetest" ||
              candidateData.score > 0
                ? ""
                : candidateData.mailType === "assignment1" ||
                  candidateData.assignment1Score > 0
                ? ""
                : candidateData.mailType === "assignment2" ||
                  candidateData.assignment2Score > 0
                ? ""
                : "none",
          }}
        >
          {/* <-------------Profile Header----------------> */}
          <ProfileHeader>Aptitude Test</ProfileHeader>
          {/* <-------------Test Link Date----------------> */}
          <CandidatePairs>
            <Title>Test Link Date :</Title>
            <Value>
              {candidateData.testmailSenttime === null
                ? "-"
                : moment(candidateData.testmailSenttime).format("YYYY-MM-DD")}
              {/* {moment(candidateData.testmailSenttime).format("YYYY-MM-DD")} */}
            </Value>
          </CandidatePairs>
          {/* <-------------Test Taken Date----------------> */}
          <CandidatePairs>
            <Title>Test Taken Date :</Title>
            <Value>
              {candidateData.testTakendate === null
                ? "-"
                : moment(candidateData.testTakendate).format("YYYY-MM-DD")}
            </Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Score----------------> */}
          <CandidatePairs>
            <Title>Score :</Title>
            <Value>{candidateData.score}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Result----------------> */}
          <CandidatePairs>
            <Title>Result :</Title>
            <Value>{candidateData.result}</Value>
          </CandidatePairs>
        </PersonalProfileContact>

        {/* <-------------Assignment1 Test---------------> */}

        <PersonalProfileContact
          style={{
            display:
              candidateData.mailType === "assignment1" ||
              candidateData.assignment1Score > 0
                ? ""
                : candidateData.mailType === "assignment2" ||
                  candidateData.assignment1Score > 0
                ? ""
                : "none",
          }}
        >
          {/* <-------------Profile Header----------------> */}
          <ProfileHeader>
            <div>Assignment 1 Test</div>
          </ProfileHeader>
          {/* <-------------Test Link Date----------------> */}
          <CandidatePairs>
            <Title>Due Date :</Title>

            <Value>{candidateData.assignment1Duedate}</Value>
          </CandidatePairs>
          {/* <-------------Test Taken Date----------------> */}
          <CandidatePairs>
            <Title>Submit Date :</Title>

            <Value>{candidateData.assignment1Submitdate}</Value>
          </CandidatePairs>

          {/* <-------------Candidate Profile Score----------------> */}
          <CandidatePairs>
            <Title>Score :</Title>

            <Value>{candidateData.assignment1Score}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Result----------------> */}
          <CandidatePairs>
            <Title>Result :</Title>

            <Value>{candidateData.assignment1Result}</Value>
          </CandidatePairs>
        </PersonalProfileContact>
        {/* <-------------Assignment2 Test---------------> */}

        <PersonalProfileContact
          style={{
            display:
              candidateData.mailType === "assignment2" ||
              candidateData.assignment2Score > 0
                ? ""
                : "none",
          }}
        >
          {/* <-------------Profile Header----------------> */}
          <ProfileHeader>
            <div>Assignment 2 Test</div>
          </ProfileHeader>
          {/* <-------------Test Link Date----------------> */}
          <CandidatePairs>
            <Title>Due Date :</Title>

            <Value>{candidateData.assignment2Duedate}</Value>
          </CandidatePairs>
          {/* <-------------Test Taken Date----------------> */}
          <CandidatePairs>
            <Title>Submit Date :</Title>

            <Value>{candidateData.assignment2Submitdate}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Score----------------> */}
          <CandidatePairs>
            <Title>Score :</Title>

            <Value>{candidateData.assignment2Score}</Value>
          </CandidatePairs>
          {/* <-------------Candidate Profile Result----------------> */}
          <CandidatePairs>
            <Title>Result :</Title>

            <Value>{candidateData.assignment2Result}</Value>
          </CandidatePairs>
        </PersonalProfileContact>
      </CandidateDetailsProfileContainer>
      {mailConformation && (
        <SignOutButton
          noButton={onCancel}
          yesButton={sendMail}
          text={"Are you sure you want to send mail ?"}
        />
      )}
    </div>
  );
}

export default CandidateProfile;
