import { put } from "redux-saga/effects";
import { WebService } from "../../../axios/webServices";
import { Creators } from "./action";
import { apiEndPoints } from "../../../axios/endPoints";
import { toast } from "react-toastify";
// Loading the data for candidate table
export function* loadCandidatesSaga(action) {
  try {
    yield put(Creators.loadCandidatesDataStart());

    const response = yield WebService.get(apiEndPoints.readCandidates, {
      params: {
        search: action.payload.search,

        min_score: action.payload.min_score,
        max_score: action.payload.max_score,

        experience: action.payload.experience,
        test_status: action.payload.test_status,
        catagory: action.payload.catagory,
        mailType: action.payload.mail_type,
        mail_status: action.payload.mail_status,
        oldCandidates: action.payload.oldCandidates,
        inProgress: action.payload.inProgress,
        assignment1_status: action.payload.assignment1_status,
        assignment2_status: action.payload.assignment2_status,
      },
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(
        Creators.loadCandidatesDataSuccess({
          data: response.data.data,
        })
      );
    } else {
      Creators.loadUserDataFailure({ errorMessage: "api error" });
    }
  } catch {}
}

// saga for Download Candiate file in excel
export function* downloadCandidatesDataSaga(action) {
  try {
    yield put(Creators.loadExportDataStart());
    const response = yield WebService.get(apiEndPoints.downloadFile, {
      params: {
        mail_status: action.payload.mail_status,
      },
    });

    if (response.status >= 200 && response.status < 300) {
      const url = `${apiEndPoints.downloadFile}/?mail_status=${action.payload.mail_status}`;
      const link = document.createElement("a");
      link.href = url;
      link.click();
      yield put(Creators.loadExportDataSuccess());
      toast.success(" Downloaded successfully");
    } else {
      yield put(Creators.loadExportDataFailure());
      toast.error(" Something went wrong");
    }
  } catch {}
}

// Saga for Send Mail to candidate
export function* sendMailSaga(action) {
  try {
    yield put(Creators.loadEmailDataStart());
    const response = yield WebService.post(apiEndPoints.sendMailL, {
      data: [
        {
          email: action.payload.email,
          mail_type: action.payload.mail_type.toString().toLocaleLowerCase(),
        },
      ],
    });

    if (response.status >= 200 && response.status < 300) {
      console.log(action.payload.mail_type.toString().toLocaleLowerCase());
      yield put(Creators.loadEmailDataSuccess({}));
      if (
        action.payload.mail_type.toString().toLocaleLowerCase() ===
          "aptitudetest" ||
        action.payload.mail_type.toString().toLocaleLowerCase() ===
          "assignment1" ||
        action.payload.mail_type.toString().toLocaleLowerCase() ===
          "assignment2"
      ) {
        toast.success(
          "Mail sent successfully and candidate profile moved to Inprogress candidate list"
        );
      } else {
        toast.success(
          "Mail sent successfully and candidate profile moved to old candidate list"
        );
      }
    } else {
      toast.error(" Something went wrong ");
    }
  } catch (error) {
    Creators.loadEmailDataFailure({ errorMessage: "api error" });
  }
}
