import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// Styled Component
import {
  AddQuestionContainer,
  AddQuestionTopBar,
  AddQuestionHeader,
  AddQuestionButtonContainer,
  AddQuestion,
  AddQuestionLable,
  InputSingleQuestion,
  AddQuestionType,
  CategoryDropdown,
  DropdownIcon,
  DropDownContainer,
  DropDownOptions,
  CategorySelection,
  CategoryOptions,
  MultipleQuestionOptions,
  NewQuestionType,
  Answer1,
  Answer2,
  Answer3,
  Answer4,
  Answer1Input,
  Answer2Input,
  Answer3Input,
  Answer4Input,
  DropDownLable,
  AnswerLable,
  ErrorLabale,
  ConfirmationContainer,
  // InputQuestionImage,
  // ImageInputContainer,
  // SelectFileLabel,
  AddNewQuestionTypeInput,
  AddNewQuestionType,
  AddQuestionTypeContainer,
} from "./AddQuestion.Styled";
// Constant and FontAwsomIcon
import { Colors } from "../../components/constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCaretDown } from "@fortawesome/free-solid-svg-icons";
// Component
import Sidebar from "../../components/sidebar/SideBar";
import Button from "../../components/Button";
import { Creators } from "./store/action";
import SaveConformation from "../../components/conformationModal/SaveConformation";
// Mainfunction------------------>
const AddNewQuestions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // Both Dispatch for reseting the Status of success
    dispatch(Creators.addNewQuestionStart());
    dispatch(Creators.editQuestionStart());
    if (state.QuizID.length > 0) {
      setQuestion(state.question);
      setOption({
        ...option,
        option1: state?.ansOption[0],
        option2: state?.ansOption[1],
        option3: state?.ansOption[2],
        option4: state?.ansOption[3],
      });

      setQuestionTypes(state.catagory);
      // setEditedCorrectOptions(state.correctAns);
    }
  }, []);
  const addSuccess = useSelector(
    (state) => state?.testDataReducer?.questionAddedStatus
  );
  const editSuccess = useSelector(
    (state) => state?.testDataReducer?.questionEditedStatus
  );
  const questionType = useSelector(
    (state) => state?.testDataReducer?.questionTypeData
  );
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const [option, setOption] = useState({});
  const probableAns = Object.values(option).filter(
    (value) => value?.length > 0
  );
  const editedCorrectOptions = state.correctAns;
  // const [editedCorrectOptions, setEditedCorrectOptions] = useState();
  const [correctOptions, setCorrectOptions] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionTypes, setQuestionTypes] = useState("Select");
  // Validation ----------------------------->
  const [isQuestionValid, setIsQuestionValid] = useState(false);
  const [isQuestionTypeValid, setIsQuestionTypeValid] = useState(false);
  const [isOptionValid, setIsoptionValid] = useState(false);
  const [isCheckBoxValid, setIsCheckBoxValid] = useState(false);
  const [newQuestionTypeActive, setNewQuestionTypeActive] = useState(false);

  useEffect(() => {
    if (state.QuizID?.length > 0) {
      setCorrectOptions((correctOptions) => [
        ...correctOptions,
        ...state?.correctAns,
      ]);
    }
  }, [state?.correctAns]);

  // Handle checkbox ---------------->
  const handleCheckbox = (event) => {
    if (event.target.checked) {
      setCorrectOptions((correctOptions) => [
        ...correctOptions,
        event.target.name,
      ]);
    } else {
      const newAns = correctOptions.filter((ans) => ans !== event.target.name);

      setCorrectOptions(newAns);
    }
  };

  //  <------------OnSave or Add new Question in table-------------->

  // <-------------On Edit Function----------->
  const onEdit = () => {
    const payload = {
      QuizId: state.QuizID,
      question,
      questionTypes,
      probableAns,
      correctOptions,
      catagory: state.type,
    };
    if (question.length > 0) {
      setIsQuestionValid(false);
      if (questionTypes.length > 0 && questionTypes !== "Select") {
        setIsQuestionTypeValid(false);
        if (probableAns.length >= 2) {
          setIsoptionValid(false);
          if (correctOptions.length >= 1) {
            setIsCheckBoxValid(false);
            // Dispatch the data to server -------------->
            dispatch(Creators.editQuestion(payload));
            // }
          } else {
            setIsCheckBoxValid(true);
          }
        } else {
          setIsoptionValid(true);
        }
      } else {
        setIsQuestionTypeValid(true);
      }
    } else {
      setIsQuestionValid(true);
    }
  };
  // <-------------OnSave function------------>
  const onSave = () => {
    const payload = {
      question,
      questionTypes,
      probableAns,
      correctOptions,
      catagory: state.type,
    };
    if (question.length > 0) {
      setIsQuestionValid(false);
      if (questionTypes.length > 0 && questionTypes !== "Select") {
        setIsQuestionTypeValid(false);
        if (probableAns.length >= 2) {
          setIsoptionValid(false);
          if (correctOptions.length >= 1) {
            setIsCheckBoxValid(false);

            // Dispatch the data to server -------------->
            dispatch(Creators.addNewQuestion(payload));
          } else {
            setIsCheckBoxValid(true);
          }
        } else {
          setIsoptionValid(true);
        }
      } else {
        setIsQuestionTypeValid(true);
      }
    } else {
      setIsQuestionValid(true);
    }
  };
  return (
    <div>
      <AddQuestionContainer>
        <Sidebar />
        <AddQuestionTopBar>
          <AddQuestionHeader>
            <FontAwesomeIcon
              style={{ cursor: "pointer", paddingRight: "10px" }}
              icon={faChevronLeft}
              onClick={() => {
                navigate(-1);
              }}
            ></FontAwesomeIcon>
            {state?.QuizID?.length > 0 ? "Edit" : "Add New"} Question
          </AddQuestionHeader>
          <AddQuestionButtonContainer>
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <Button name={"Cancel"} backGroundColor={Colors.buttonColor} />
            </div>
            <div onClick={state.QuizID.length > 0 ? onEdit : onSave}>
              <Button name={"Save"} backGroundColor={Colors.buttonColor} />
            </div>
          </AddQuestionButtonContainer>
        </AddQuestionTopBar>
        <AddQuestion>
          <form>
            <AddQuestionLable>Enter question :</AddQuestionLable>
            {/* <------------------Text Question Input-------------------> */}
            <InputSingleQuestion
              type="textarea"
              name="name"
              value={question}
              onChange={(event) => {
                setQuestion(event.target.value);
              }}
            />
            {/* <------------------Image Question Input-------------------> */}

            {/* <InputQuestionImage type={"file"}></InputQuestionImage> */}

            {isQuestionValid && <ErrorLabale>Enter Question</ErrorLabale>}
          </form>
          <AddQuestionType>
            {/* <-----------------Dropdown-----------> */}
            <DropDownLable>Question type :</DropDownLable>
            <AddQuestionTypeContainer>
              <DropDownContainer
                onClick={() => {
                  setIsDropDownOpen(!isDropdownOpen);
                }}
              >
                <CategoryDropdown
                  type="text"
                  disabled={true}
                  value={questionTypes}
                  style={{ cursor: "pointer", paddingLeft: "8px" }}
                ></CategoryDropdown>

                <DropdownIcon>
                  <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                </DropdownIcon>
              </DropDownContainer>
              <DropDownOptions>
                {isDropdownOpen && (
                  <CategorySelection>
                    {questionType.map((options) => {
                      return (
                        <CategoryOptions
                          onClick={(e) => {
                            setQuestionTypes(options._id);
                            // setSelectedOption(item);
                            setNewQuestionTypeActive(false);
                            setIsDropDownOpen(false);
                          }}
                        >
                          {options._id}
                        </CategoryOptions>
                      );
                    })}

                    <NewQuestionType
                      onClick={() => {
                        setNewQuestionTypeActive(true);
                        setIsDropDownOpen(false);
                      }}
                    >
                      Add new question type
                    </NewQuestionType>
                  </CategorySelection>
                )}
              </DropDownOptions>
              {newQuestionTypeActive && (
                <AddNewQuestionType>
                  <AddNewQuestionTypeInput
                    type="text"
                    placeholder="Add new question type"
                    onChange={(event) => {
                      setQuestionTypes(event.target.value);
                    }}
                  ></AddNewQuestionTypeInput>
                </AddNewQuestionType>
              )}
            </AddQuestionTypeContainer>
          </AddQuestionType>
          {isQuestionTypeValid && (
            <ErrorLabale>Select Question Type</ErrorLabale>
          )}
          {/* <-----------------Possibale Answer's------------------> */}
          <AnswerLable>Answers :</AnswerLable>
          {isOptionValid && (
            <ErrorLabale>At least two option required</ErrorLabale>
          )}
          {isCheckBoxValid && (
            <ErrorLabale>At least select one correct option</ErrorLabale>
          )}
          <MultipleQuestionOptions>
            <Answer1>
              {/* <div> */}
              <input
                type="checkbox"
                id="horns"
                name={option.option1}
                checked={correctOptions.includes(option.option1)}
                onChange={(e) => {
                  handleCheckbox(e);
                }}
              />
              <Answer1Input
                htmlFor="horns"
                type="text"
                placeholder="option 1"
                value={option.option1}
                onChange={(e) => {
                  setOption({
                    ...option,
                    option1: e.target.value,
                  });
                }}
              ></Answer1Input>
              {/* </div> */}
            </Answer1>
            <Answer2>
              {/* <div> */}
              <input
                type="checkbox"
                id="horns"
                checked={correctOptions?.includes(option.option2)}
                name={option.option2}
                onChange={(e) => {
                  handleCheckbox(e);
                }}
              />
              <Answer2Input
                htmlFor="horns"
                type="text"
                placeholder="option 2"
                value={option.option2}
                onChange={(e) => {
                  setOption({
                    ...option,
                    option2: e.target.value,
                  });
                }}
              ></Answer2Input>
              {/* </div> */}
            </Answer2>
            <Answer3>
              {/* <div> */}
              <input
                type="checkbox"
                id="horns"
                checked={correctOptions.includes(option.option3)}
                name={option.option3}
                onChange={(e) => {
                  handleCheckbox(e);
                }}
              />
              <Answer3Input
                htmlFor="horns"
                type="text"
                placeholder="option 3"
                value={option.option3}
                onChange={(e) => {
                  setOption({
                    ...option,
                    option3: e.target.value,
                  });
                }}
              ></Answer3Input>
              {/* </div> */}
            </Answer3>
            {/* <-----------------Ansewer 4 ------------------------> */}
            <Answer4>
              {/* <div> */}
              <input
                type="checkbox"
                id="horns"
                name={option.option4}
                checked={correctOptions.includes(option.option4)}
                onChange={(e) => {
                  handleCheckbox(e);
                }}
              />

              <Answer4Input
                htmlFor="horns"
                type="text"
                placeholder="option 4"
                value={option.option4}
                onChange={(e) => {
                  setOption({
                    ...option,
                    option4: e.target.value,
                  });
                }}
              ></Answer4Input>
              {/* </div> */}
            </Answer4>
          </MultipleQuestionOptions>
        </AddQuestion>
      </AddQuestionContainer>
      {addSuccess && (
        <ConfirmationContainer>
          <SaveConformation text={"Question added successfully"} />
        </ConfirmationContainer>
      )}
      {editSuccess && (
        <ConfirmationContainer>
          <SaveConformation text={"Question updated successfully"} />
        </ConfirmationContainer>
      )}
    </div>
  );
};

export default AddNewQuestions;
