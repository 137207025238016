import { createReducer } from "reduxsauce";
import { Types } from "./action";
import setAccessTokenToHeader from "../../../axios/helper";
const INITIAL_STATE = {
  loading: false,
  adminLoggedIn: false,
};

const loginStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const loginSuccess = (state = INITIAL_STATE, action) => {
  sessionStorage.setItem("token", action.payload.data.data.access_token);
  sessionStorage.setItem("admin", action.payload.data.data.is_superuser);
  sessionStorage.setItem("isActive", action.payload.data.data.is_active);
  sessionStorage.setItem("isStaff", action.payload.data.data.is_staff);
  return {
    ...state,
    loading: false,
    adminLoggedIn: action.payload.data.data.is_superuser,
  };
};
const loginFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
  };
};
const HANDLERS = {
  [Types.LOGIN_START]: loginStart,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
};
export const loginReducer = createReducer(INITIAL_STATE, HANDLERS);
