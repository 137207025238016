import "./navbar.css";
import { WelcomeName } from "../welcomename/WelcomeName";
import { useIsAuthenticated } from "@azure/msal-react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router";
const NavBar = ({ title, page }) => {
  // const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const isAdmin = sessionStorage.getItem("admin");
  return (
    <div className="sticky-nav">
      {title !== "Home" && (
        <div className="heading">
          {/* <FontAwesomeIcon
            className="left-icon"
            icon={faChevronLeft}
            onClick={() => {
              navigate(page);
            }}
          ></FontAwesomeIcon> */}
          <div>{title}</div>
        </div>
      )}
      {title === "Home" && (
        <div className="home-heading">
          {(isAdmin === "true" || isAdmin === "false") && <div>{title}</div>}
        </div>
      )}
      <div className="welcome-div">
        {isAuthenticated ? <WelcomeName /> : null}
      </div>
    </div>
  );
};

export default NavBar;
