import { createActions } from "reduxsauce";
const { Types, Creators } = createActions({
  loadTestCategory: ["payload"],
  loadTestCategoryStart: ["payload"],
  loadTestCategorySuccess: ["payload"],
  loadTestCategoryFailure: ["payload"],
  // Bulk Upload
  loadBulkQuestion: ["payload"],
  loadBulkQuestionStart: ["payload"],
  loadBulkQuestionSuccess: ["payload"],
  loadBulkQuestionFailure: ["payload"],
  // QuestionTable
  loadQuestionTable: ["payload"],
  loadQuestionTableStart: ["payload"],
  loadQuestionTableSuccess: ["payload"],
  loadQuestionTableFailure: ["payload"],
  // CustomizeQuiz get
  loadGetCustomizeQuiz: ["payload"],
  loadGetCustomizeQuizStart: ["payload"],
  loadGetCustomizeQuizSuccess: ["payload"],
  loadGetCustomizeQuizFailure: ["payload"],
  // Customize Quiz Post
  loadPostCustomizeQuiz: ["payload"],
  loadPostCustomizeQuizStart: ["payload"],
  loadPostCustomizeQuizSuccess: ["payload"],
  loadPostCustomizeQuizFailure: ["payload"],
  // Activate Question
  loadActivateQuestion: ["payload"],
  loadActivateQuestionStart: ["payload"],
  loadActivateQuestionSuccess: ["payload"],
  loadActivateQuestionFailure: ["payload"],
  // De Activate Question
  loadDeactivateQuestion: ["payload"],
  loadDeactivateQuestionStart: ["payload"],
  loadDeactivateQuestionSuccess: ["payload"],
  loadDeactivateQuestionFailure: ["payload"],
  // Add new Questions
  addNewQuestion: ["payload"],
  addNewQuestionStart: ["payload"],
  addNewQuestionSuccess: ["payload"],
  addNewQuestionFailure: ["payload"],
  // Edit Question
  editQuestion: ["payload"],
  editQuestionStart: ["payload"],
  editQuestionSuccess: ["payload"],
  editQuestionFailure: ["payload"],
});
export { Types, Creators };
