import { style } from "@mui/system";
import styled from "styled-components";
import { Colors } from "../../components/constants/constants";
export const CustomizeQuizContainer = styled.div`
  margin-left: 210px;
  margin-right: 5px;
  background-color: ${Colors.white};
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
  margin-top: 10px;
`;
export const CustomizeTopbar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  position: absolute;
  width: calc(100% - 210px);
`;
export const CustomizeHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
`;
export const CustomizeAction = styled.div``;
export const CustomizeButtonContainer = styled.div`
  margin-right: 25px;
  display: flex;
  gap: 20px;
`;
export const CustomizeQuizEditContainer = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  margin-top: 100px;
  margin-right: 100px;
  margin-left: 100px;
`;
export const QuestionTimeHeader = styled.div`
  /* background-color: aqua; */
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 850px;
  height: 100px;
  /* align-items: center; */
  /* justify-content: space-around; */
`;
export const ToatlAllocatedTime = styled.div`
  /* width: 300px; */
`;
export const TotalQuestion = styled.div``;
export const ToatlTimeInput = styled.input`
  width: 300px;
`;
export const TotalQuestionInput = styled.input`
  width: 300px;
`;

export const QuestionsCategoryTable = styled.div`
  /* background-color: yellow; */
`;

export const CategoryTable = styled.div`
  display: flex;
  /* justify-content: center; */
  justify-content: flex-start;
  margin-top: 20px;
  /* justify-content: center; */
`;
export const CategoryTableHeading = styled.div`
  margin-top: 50px;
`;
export const Table = styled.table`
  /* width: 100%; */
  border: 1px solid black;
`;
export const CategoryTableBody = styled.tbody``;
export const CategoryTableRow = styled.tr``;
//
export const TotalQuestionHeader = styled.th`
  border: 1px solid ${Colors.tableBorderColor};
  width: 200px;
  padding-left: 10px;
`;
export const QuestionTypeHeader = styled.th`
  border: 1px solid ${Colors.tableBorderColor};
  width: 300px;
  padding-left: 10px;
`;
//
export const QuestionTypeData = styled.td`
  border: 1px solid ${Colors.tableBorderColor};
  padding-left: 10px;
  width: 300px;
`;
export const TotalQuestionData = styled.td`
  border: 1px solid ${Colors.tableBorderColor};
  padding-left: 10px;
  width: 200px;
`;
// export const CategoryTableHeader = styled.th`
//   border: 1px solid black;
// `;
export const CategoryTableData = styled.td`
  border: 1px solid ${Colors.tableBorderColor};
`;
export const ConfirmationContainer = styled.div`
  /* position: absolute; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
`;

export const CustmizeValuInput = styled.input`
  outline: none;
  border: 0px solid ${Colors.tableBorderColor};
  width: 200px;
  &:focus {
    outline: none;
    /* box-shadow: 0px 0px 2px red; */
    border: 0px solid ${Colors.tableBorderColor};
  }
`;
