import React from "react";
import Card from "../card/Card";
import {
  QuickViewContainer,
  QuestionAndAnswer,
  Question,
  Answer,
  AnswerContainer,
  QuestionLable,
  OptionLable,
  CancelFont,
  AnswerSubContainer,
} from "./QuickViewModal.Styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../constants/constants";
const answers = ["1/3", "1/8", "2/8", "1/16"];
const QuickViewModal = (question, answer, onclickFunc) => {
  console.log(question.onclickFunc, "onclickFunc");

  // const Question = question;
  return (
    <QuickViewContainer>
      <Card
        width={"1000px"}
        borderRadius={"10px"}
        height={"400px"}
        topBorder={"4px"}
        bottomBorder={"0px"}
        leftBorder={"0px"}
        rightBorder={"0px"}
        backgroudColor={Colors.white}
        decoration={""}
        opacity={"1"}
        shadow={true}
      ></Card>
      <QuestionAndAnswer>
        <QuestionLable>Question :</QuestionLable>
        <CancelFont onClick={question.onclickFunc}>
          <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
        </CancelFont>
        <Question>{question?.question}</Question>
        <AnswerContainer>
          <OptionLable>Options</OptionLable>
          {question?.answer.map((answer, key) => {
            return (
              <Answer key={key}>
                {key + 1}. {answer}
              </Answer>
            );
          })}
        </AnswerContainer>
      </QuestionAndAnswer>
    </QuickViewContainer>
  );
};

export default QuickViewModal;
