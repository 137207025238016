// Dependency
import styled from "styled-components";
// Constant
import { Colors } from "../../components/constants/constants";
export const QuestionTypeContainer = styled.div`
  margin-left: 210px;
  margin-right: 5px;
  background-color: ${Colors.white};
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
  margin-top: 10px;
  overflow-y: scroll;
`;
export const CategoryTopBar = styled.div`
  margin-top: 20px;
  display: flex;
  cursor: pointer;
  /* width: calc(100% - 200px); */
  /* position: absolute; */
  /* background-color: green; */
  /* width: calc(100% - 210px); */
  /* margin-bottom: 20px */

  justify-content: space-between;
  height: 50px;
  margin-bottom: 10px;
  padding-right: 10px;
`;
export const TestButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
`;
export const CategoryHeading = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
`;
export const QuestionTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  gap: 50px;
  margin-left: 20px;
  margin-right: 20px;
`;
export const NoRecored = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
export const AddQuestionDropdown = styled.div`
  position: absolute;
  background-color: ${Colors.blue};
  z-index: 1;
  width: 160px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.tableBorderColor};
  align-items: center;
  height: 70px;
  justify-content: center;
  gap: 5px;
  /* padding-top: 10px; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const OptionIteam = styled.div`
  color: white;
  /* height: 50px; */
  /* text-colo÷ */
`;
