import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import "../signinbutton/signinbutton.css";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { browserName } from "react-device-detect";
import { height } from "@mui/system";
export const SignInButton = () => {
  const logIn = sessionStorage.getItem("logInStart");
  const { instance } = useMsal();
  const [loading, setLoading] = useState(false);
  const handleSignIn = (e) => {
    setLoading(true);
    e.preventDefault();
    instance.loginRedirect({
      scopes: ["user.read"],
    });
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Please Wait...
      </div>
    );
  }
  return (
    <div className="main-container">
      {!logIn && (
        <div className="login-container">
          <div className="login-img">
            <img
              className="login-image"
              src={require("../../assets/images/login-img.png")}
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className="home-layout">
            <div className="headings">Pan Hire</div>
            <div style={{ width: "65%", padding: "20px 0px" }}>
              <button
                className="signIn"
                onClick={(e) => {
                  handleSignIn(e);
                }}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
