import { combineReducers } from "redux";
import { userDataReducer } from "../pages/users/store/reducer";
import { userUpdateReducer } from "../modals/activate-deactivate/store/reducer";
import { loginReducer } from "../pages/pagelayout/store/reducer";
import { CandidatesDataReducer } from "../components/candidates-details/store/reducer";
import { saveDataReducer } from "../pages/newuser/store/reducer";
import { NewCandidatesDataReducer } from "../pages/addCandidate/store/reducer";
// import { CandidatesProfileReducer } from "../components/CandidateProfile/store/reducer";
import { CandidatesProfileReducer } from "../components/CandidateProfile/store/reducer";
import { testDataReducer } from "../pages/tests/store/reducer";
const rootReducer = combineReducers({
  userDataReducer,
  userUpdateReducer,
  loginReducer,
  CandidatesDataReducer,
  saveDataReducer,
  NewCandidatesDataReducer,
  CandidatesProfileReducer,
  testDataReducer,
});

export default rootReducer;
