// Dependency
import styled from "styled-components";
// Constant
import { Colors } from "../../components/constants/constants";
// Stylesheet
export const TestScreenContainer = styled.div`
  margin-left: 210px;
  margin-right: 5px;
  background-color: ${Colors.white};
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
  margin-top: 10px;
`;

export const TestCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-left: 20px;
  margin-top: 15px;
`;
