import styled from "styled-components";
import { Colors } from "../components/constants/constants";
export const ButtonContainer = styled.div`
  background-color: ${(props) => props.bg};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
`;
export const ButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white};
`;
export const Icon = styled.div`
  padding-right: ${(props) => props.iconAligned};
`;
// &:hover {
//   background-image: linear-gradient(to right, ${Colors.blue}, #2a2a72);
// }
