import React from "react";
// Constant
import Button from "../Button";
// Constant
import { Colors } from "../constants/constants";
import ConfomCard from "../card/ConfomCard";
const ConformationModal = ({ onSave, onCancel }) => {
  return (
    <div>
      <div className="logout-pop-up">
        <ConfomCard
          cardText={"Are you sure you want to save?"}
          listNo={"2"}
          width={"500px"}
          borderRadius={"10px"}
          height={"200px"}
          topBorder={"4px"}
          bottomBorder={"0px"}
          leftBorder={"0px"}
          rightBorder={"0px"}
          backgroudColor={Colors.white}
        />
        <div className="confirmation-button">
          <div
            className="confirm-no"
            onClick={() => {
              onCancel(false);
            }}
          >
            <Button
              name={"Discard"}
              backGroundColor={Colors.blue}
              width={"100px"}
              height={"30px"}
            />
          </div>
          <div className="confirm-yes" onClick={onSave}>
            <Button
              name={"Save"}
              backGroundColor={Colors.blue}
              width={"100px"}
              height={"30px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConformationModal;
