import { put, call } from "redux-saga/effects";
import { WebService } from "../../../axios/webServices";
import { Creators } from "./action";
import { apiEndPoints } from "../../../axios/endPoints";
import { toast } from "react-toastify";
// saga for Adding New Candidate
export function* loadNewCandidatesDataSaga(action) {
  try {
    const fileUpload = action.payload.fileUpload;
    yield put(Creators.loadNewCandidatesDataStart());
    const response = yield call(
      WebService.post,
      apiEndPoints.addNewCandidate,
      fileUpload
    );
    if (response.status >= 200 && response.status < 305) {
      yield put(
        Creators.loadNewCandidatesDataSuccess({
          data: { ...response.data },
        })
      );
    } else {
      toast.error("User either already exists or something went wrong");
      throw response;
    }
  } catch (err) {
    toast.error(err);
    Creators.loadNewCandidatesDataFailure({ errorMessage: "api error" });
  }
}
// saga for Edit existing Candidate
export function* loadEditCandidatesDataSaga(action) {
  try {
    yield put(Creators.loadEditCandidatesDataStart());

    const response = yield WebService.put(
      `${apiEndPoints.updateCandidateDetails}/${action.payload.candidtaeId}`,
      action.payload.payload
    );

    if (response.status >= 200 && response.status < 305) {
      yield put(Creators.loadEditCandidatesDataSuccess(response.data.message));
    }
  } catch (error) {
    console.log(error);
  }
}
