import { put } from "redux-saga/effects";
import { Creators } from "./action";
import { WebService } from "../../../axios/webServices";
import { apiEndPoints } from "../../../axios/endPoints";
import setAccessTokenToHeader from "../../../axios/helper";
//login auth
export function* loginSaga(action) {
  if (action.payload.id_token) {
    try {
      yield put(Creators.loginStart());

      const response = yield WebService.post(apiEndPoints.login, {
        id_token: action.payload.id_token,
        audience: action.payload.audience,
        tenant_id: action.payload.tenant_id,
        email_id: action.payload.email_id,
      });
      if (response.status >= 200 && response.status < 300) {
        yield put(
          Creators.loginSuccess({
            ...response,
          })
        );
      } else {
        Creators.loginFailure({ errorMessage: "api error" });
      }
    } catch (error) {
      Creators.loginFailure({ errorMessage: "saga error" });
    }
  }
}
