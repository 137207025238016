import React, { useEffect } from "react";
import CandidatesDetails from "../candidates-details/CandidatesDetails";
import { useNavigate, useLocation } from "react-router";
// import "./style.css";
import {
  Table,
  TableHead,
  TableBody,
  TableData,
  TableRow,
  TableHeading,
  TableEmailData,
} from "./Table.Styled";
const CandidateTable = ({
  perpage,
  selectAll,
  selectAllCandidates,
  candidatesIds,
  setCandidatesIds,
  paginatedCandidates,
  handleSelect,
  setSelectAll,
  candidatedataformap,
  type,
  tab,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  if (
    candidatesIds.length &&
    candidatesIds.length === paginatedCandidates.length
  ) {
    setSelectAll(true);
  } else {
    setSelectAll(false);
  }
  useEffect(() => {
    setSelectAll(false);
    // setCandidatesIds([]);
  }, []);
  return (
    <Table className="customers">
      <TableHead>
        <TableRow>
          <TableHeading>NAME</TableHeading>
          <TableHeading>EMAIL</TableHeading>
          <TableHeading>EXPERIENCE</TableHeading>
          <TableHeading>DEPARTMENT</TableHeading>
          {type === "New" ? (
            <TableHeading>STATUS</TableHeading>
          ) : (
            <TableHeading>MAIL STATUS</TableHeading>
          )}
          <TableHeading>CONTACT</TableHeading>
          {/* <--------------SCORE-----------------> */}

          {type != "Old" && tab === "aptitudetest" ? (
            <TableHeading style={{ display: type === "New" ? "none" : "" }}>
              APTITUDE SCORE
            </TableHeading>
          ) : type != "Old" && tab === "assignment1" ? (
            <TableHeading style={{ display: type === "New" ? "none" : "" }}>
              ASSIGNMENT 1 SCORE
            </TableHeading>
          ) : type != "Old" && tab === "assignment2" ? (
            <TableHeading style={{ display: type === "New" ? "none" : "" }}>
              ASSIGNMENT 2 SCORE
            </TableHeading>
          ) : (
            <></>
            // <TableHeading style={{ display: type === "New" ? "none" : "" }}>
            //   {" "}
            //   SCORE
            // </TableHeading>
          )}

          {/* <--------------RESULT-----------------> */}
          {type != "Old" && tab === "aptitudetest" ? (
            <TableHeading style={{ display: type === "New" ? "none" : "" }}>
              APTITUDE RESULT
            </TableHeading>
          ) : type != "Old" && tab === "assignment1" ? (
            <TableHeading style={{ display: type === "New" ? "none" : "" }}>
              ASSIGNMENT 1 RESULT
            </TableHeading>
          ) : type != "Old" && tab === "assignment2" ? (
            <TableHeading style={{ display: type === "New" ? "none" : "" }}>
              ASSIGNMENT 2 RESULT
            </TableHeading>
          ) : (
            <></>
            // <TableHeading style={{ display: type === "New" ? "none" : "" }}>
            //   RESULT
            // </TableHeading>
          )}
          {/* <--------------------^^^^---------------> */}
          <TableHeading
            style={{ display: "flex", alignItems: "center", gap: "4px" }}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={(e) => {
                selectAllCandidates(e);
              }}
            />
          </TableHeading>
        </TableRow>
      </TableHead>
      {paginatedCandidates.length ? (
        <TableBody>
          {paginatedCandidates.map((candidate, i) => {
            return (
              <TableRow key={i.toString()}>
                <TableData
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={() => {
                    navigate(`${location.pathname}/candidate-profile/`, {
                      state: { id: candidate.id },
                    });
                  }}
                >
                  {candidate.fullName}
                </TableData>
                <TableEmailData>{candidate.email}</TableEmailData>
                <TableData>{candidate.experience}</TableData>
                <TableData>{candidate.catagory}</TableData>
                <TableData>{candidate.mailType}</TableData>
                <TableData>{candidate.phoneNumber}</TableData>
                {/* <---------------ASSIGNMET SCORE------------> */}
                {type != "Old" && tab === "assignment1" ? (
                  <TableData style={{ display: type === "New" ? "none" : "" }}>
                    {candidate.assignment1Score}
                  </TableData>
                ) : type != "Old" && tab === "assignment2" ? (
                  <TableData style={{ display: type === "New" ? "none" : "" }}>
                    {candidate.assignment2Score}
                  </TableData>
                ) : type != "Old" && tab === "aptitudetest" ? (
                  <TableData style={{ display: type === "New" ? "none" : "" }}>
                    {candidate.score}
                  </TableData>
                ) : (
                  <></>
                )}

                {/* <--------------RESULT-----------------> */}
                {type != "Old" && tab === "assignment1" ? (
                  <TableData style={{ display: type === "New" ? "none" : "" }}>
                    {candidate.assignment1Result}
                  </TableData>
                ) : type != "Old" && tab === "assignment2" ? (
                  <TableData style={{ display: type === "New" ? "none" : "" }}>
                    {candidate.assignment2Result}
                  </TableData>
                ) : type != "Old" && tab === "aptitudetest" ? (
                  <TableData style={{ display: type === "New" ? "none" : "" }}>
                    {candidate.result}
                  </TableData>
                ) : (
                  <></>
                )}

                <TableData>
                  <input
                    id="candidates"
                    value={candidate.id}
                    style={{
                      display: "flex",
                      alignSelf: "flex-end",
                    }}
                    type="checkbox"
                    checked={candidatesIds.includes(candidate.email)}
                    onChange={(e) => {
                      handleSelect(e, candidate.email);
                    }}
                  />
                </TableData>
              </TableRow>
            );
          })}
        </TableBody>
      ) : (
        <div>No record found</div>
      )}
    </Table>
  );
};

export default CandidateTable;
