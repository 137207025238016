import { createReducer } from "reduxsauce";
import { Types } from "./action";
const INITIAL_STATE = {
  loading: false,
  activeLoading: false,
  dactiveLoading: false,
  userData: [],
  errorMessage: "",
};

const loadUserDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: true,
  };
};
const loadUserDataSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    userData: action.payload.userData.data,
  };
};
const loadUserDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: false,
    errorMessage: action.payload.errorMessage,
  };
};
// Reducer for Activatate user
const loadActivateUserStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activeLoading: true,
  };
};
const loadActivateUserSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activeLoading: false,
    // userData: action.payload.userData.data,
  };
};
const loadActivateUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activeLoading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
// Reducer to Deactivate user
const loadDeactivateUserStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dactiveLoading: true,
  };
};
const loadDeactivateUserSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dactiveLoading: false,
    // userData: action.payload.userData.data,
  };
};
const loadDeactivateUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    dactiveLoading: false,
    // errorMessage: action.payload.errorMessage,
  };
};
const HANDLERS = {
  [Types.LOAD_USER_DATA_START]: loadUserDataStart,
  [Types.LOAD_USER_DATA_SUCCESS]: loadUserDataSuccess,
  [Types.LOAD_USER_DATA_FAILURE]: loadUserDataFailure,
  // Handler to Activate user
  [Types.LOAD_ACTIVATE_USER_START]: loadActivateUserStart,
  [Types.LOAD_ACTIVATE_USER_SUCCESS]: loadActivateUserSuccess,
  [Types.LOAD_ACTIVATE_USER_FAILURE]: loadActivateUserFailure,
  // Handler to Activate user
  [Types.LOAD_DEACTIVATE_USER_START]: loadDeactivateUserStart,
  [Types.LOAD_DEACTIVATE_USER_SUCCESS]: loadDeactivateUserSuccess,
  [Types.LOAD_DEACTIVATE_USER_FAILURE]: loadDeactivateUserFailure,
};
export const userDataReducer = createReducer(INITIAL_STATE, HANDLERS);
