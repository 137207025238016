import { put } from "redux-saga/effects";
import { Creators } from "./action";
import { WebService } from "../../../axios/webServices";
import { apiEndPoints } from "../../../axios/endPoints";
import setAccessTokenToHeader from "../../../axios/helper";
import { toast } from "react-toastify";
export function* saveUserSaga(action) {
  const { Username, email, selectedOption } = action.payload;

  const userType = selectedOption == "Admin" ? "Admin" : "HR";

  try {
    yield put(Creators.saveUserDataStart());
    const response = yield WebService.post(apiEndPoints.newUser, {
      fullName: action.payload.Username,
      email: action.payload.email,
      userType: userType,
      // is_active: true,
      // is_staff: true,
      // is_superuser: Admin,
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.saveUserDataSuccess(response.response));
      toast.success("User added successfully");
    } else {
      toast.error("User already exist or Something went wrong ");
      Creators.saveUserDataFailure();
    }
  } catch (error) {
    Creators.saveUserDataFailure(error);
  }
}
// Edit User Profile
export function* editUserSaga(action) {
  try {
    yield put(Creators.editUserDataStart());

    const response = yield WebService.put(
      `${apiEndPoints.editUser}/${action.payload.id}`,
      {
        fullName: action.payload.name,
        email: action.payload.email,
        userType: action.payload.userType,
        // is_active: action.payload.active,
        // is_staff: action.payload.is_staff,
        // is_superuser: action.payload.is_superuser,
      }
    );
    if (response.status >= 200 && response.status < 300) {
      yield put(Creators.editUserDataSuccess(response.response));
      toast.success("User updated successfully");
    } else {
      toast.error("Something went wrong ", response);
      throw response;
    }
  } catch (error) {
    yield put(Creators.editUserDataFailure());
  }
}
