import styled from "styled-components";
import { Colors } from "../../components/constants/constants";

export const AddQuestionContainer = styled.div`
  margin-left: 210px;
  margin-right: 5px;
  background-color: ${Colors.white};
  border-top: 3px solid #0ab5a9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  height: 98vh;
  margin-top: 10px;
`;
export const AddQuestionTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  position: absolute;
  width: calc(100% - 210px);
`;
export const AddQuestionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 600;
`;
export const AddQuestionButtonContainer = styled.div`
  margin-right: 25px;
  display: flex;
  gap: 20px;
`;
export const UploadfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  margin-top: 100px;
  margin-left: 20px;
`;
export const UploadfileHeading = styled.div`
  margin-top: 100px;
`;

export const InputUploadFile = styled.div`
  display: flex;
  flex-direction: column;

  /* margin-top: 100px; */
  width: 50%;
  border: 1px solid ${Colors.tableBorderColor};
`;

export const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 50px;
  width: 500px;
`;
export const AddQuestionLable = styled.div``;
export const AddQuestion = styled.div`
  margin-top: 70px;
  margin-left: 20px;
`;
export const InputSingleQuestion = styled.textarea`
  border: 1px solid rgb(237, 237, 240);
  width: 80%;
  height: 150px;
`;
export const InputFileSelector = styled.input`
  background-color: ${Colors.white};
  width: 100%;
  &:file-selector-button {
    color: red;
  }
`;
export const AddQuestionType = styled.div`
  /* background-color: aqua; */
  position: relative;
`;
export const CategoryDropdown = styled.input`
  border: 1px solid ${Colors.tableBorderColor};
  width: 500px;
`;

export const DropdownIcon = styled.div`
  position: absolute;
  top: 0;
  left: 480px;
`;
export const DropDownContainer = styled.div`
  position: relative;
  width: 500px;
`;
export const DropDownOptions = styled.div`
  background-color: ${Colors.filterBackgroundColor};
  position: absolute;
  top: 28px;
  width: 500px;
  cursor: pointer;
  padding-left: 10px;
  border: 1px solid ${Colors.tableBorderColor};
`;
export const CategorySelection = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const CategoryOptions = styled.div`
  padding: 10px 0px 10px 0px;
`;
export const MultipleQuestionOptions = styled.div``;

export const Answer1 = styled.div`
  display: flex;
  align-items: center;
`;
export const Answer2 = styled.div`
  display: flex;
  align-items: center;
`;
export const Answer3 = styled.div`
  display: flex;
  align-items: center;
`;
export const Answer4 = styled.div`
  display: flex;
  align-items: center;
`;

export const Answer1Input = styled.textarea`
  border: 1px solid ${Colors.tableBorderColor};
  width: 470px;
  margin-left: 20px;
`;
export const Answer2Input = styled.textarea`
  border: 1px solid ${Colors.tableBorderColor};
  width: 470px;
  margin-left: 20px;
  margin-top: 10px;
`;
export const Answer3Input = styled.textarea`
  border: 1px solid ${Colors.tableBorderColor};
  width: 470px;
  margin-left: 20px;
  margin-top: 10px;
`;
export const Answer4Input = styled.textarea`
  border: 1px solid ${Colors.tableBorderColor};
  width: 470px;
  margin-left: 20px;
  margin-top: 10px;
`;
export const NewQuestionType = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;
export const DropDownLable = styled.div`
  margin-top: 30px;
`;
export const AnswerLable = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
`;
export const ErrorLabale = styled.div`
  color: red;
  font-size: 12px;
`;
export const ConfirmationContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1;
`;
export const Tamplate = styled.div`
  margin-right: 20px;
`;
export const InputQuestionImage = styled.input`
  border: 1px solid ${Colors.tableBorderColor};
  width: 80%;
`;
export const ImageInputContainer = styled.div``;

export const SelectFileLabel = styled.div``;
export const AddQuestionTypeContainer = styled.div`
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  width: 80%;
`;
export const AddNewQuestionType = styled.div``;
export const AddNewQuestionTypeInput = styled.input`
  width: 450px;
  border: 1px solid ${Colors.tableBorderColor};
`;
