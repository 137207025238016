import React from "react";
import { CardContainerConform, CardText } from "./ConfomCard.Styled";
const ConfomCard = (props) => {
  const {
    width,
    height,
    cardText,
    borderRadius,
    bottomBorder,
    topBorder,
    rightBorder,
    leftBorder,
    backgroudColor,
    decoration,
    opacity,
    shadow,
  } = props;

  return (
    <>
      <CardContainerConform
        shadow={shadow}
        width={width}
        height={height}
        topBorder={topBorder}
        borderRadius={borderRadius}
        bottomBorder={bottomBorder}
        leftBorder={leftBorder}
        rightBorder={rightBorder}
        backgroudColor={backgroudColor}
      >
        <CardText decoration={decoration} opacity={opacity}>
          {cardText}
        </CardText>
      </CardContainerConform>
    </>
  );
};
export default ConfomCard;
