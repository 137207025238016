import { put, call } from "redux-saga/effects";
import { WebService } from "../../../axios/webServices";
import { Creators } from "./action";
// import { apiEndPoints } from "../../../axios/endPoints";
import { apiEndPoints } from "../../../axios/endPoints";
// import { data, error } from "jquery";
import { toast } from "react-toastify";
export function* loadCandidatesProfileSaga(action) {
  try {
    yield put(Creators.loadCandidatesProfileDataStart());

    const response = yield WebService.get(
      `${apiEndPoints.CandidateDetails}/${action.payload.id}`
    );

    if (response.status >= 200 && response.status < 305) {
      yield put(
        Creators.loadCandidatesProfileDataSuccess({
          data: { ...response.data.data },
        })
      );
    } else {
      Creators.loadUserDataFailure({ errorMessage: "api error" });
    }
  } catch {
    // console.log(error);
  }
}
// Saga for Assignment 1
export function* loadAssignmentFirsteSaga(action) {
  try {
    yield put(Creators.loadAssignmentFirstDetailsStart());
    const response = yield WebService.post(
      `${apiEndPoints.assignment1}/${action.payload.id}`,
      {
        dueDate: action.payload.dueDate,
        Submitdate: action.payload.Submitdate,
        Score: action.payload.Score,
        Result: action.payload.Result,
      }
    );

    // const response = yield WebService.post(
    //   `${apiEndPoints.assignment1}/${action.payload.id}`
    // );

    if (response.status >= 200 && response.status < 305) {
      toast.success("Assignment 1 date updated");
      yield put(Creators.loadAssignmentFirstDetailsSuccess());
    } else {
      Creators.loadUserDataFailure({ errorMessage: "api error" });
    }
  } catch {}
}
// Saga For Assignment2
export function* loadAssignmentSecondeSaga(action) {
  try {
    yield put(Creators.loadAssignmentSecondDetailsStart());

    const response = yield WebService.post(
      `${apiEndPoints.assignment2}/${action.payload.id}`,
      {
        dueDate: action.payload.dueDate,
        Submitdate: action.payload.Submitdate,
        Score: action.payload.Score,
        Result: action.payload.Result,
      }
    );

    if (response.status >= 200 && response.status < 305) {
      toast.success("Assignment 2 date updated");
    }
    //   yield put(
    //     Creators.loadCandidatesProfileDataSuccess({
    //       data: { ...response.data.data },
    //     })
    //   );
    // } else {
    //   console.log("error");
    //   Creators.loadUserDataFailure({ errorMessage: "api error" });
    // }
  } catch (error) {
    console.log(error);
  }
}
