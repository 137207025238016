import React from "react";
import { Colors } from "../constants/constants";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import ConfomCard from "../card/ConfomCard";
const SaveConformation = (text) => {
  console.log();
  const navigate = useNavigate();
  return (
    <div className="logout-pop-up">
      <ConfomCard
        cardText={text.text}
        listNo={"2"}
        width={"500px"}
        borderRadius={"10px"}
        height={"200px"}
        topBorder={"4px"}
        bottomBorder={"0px"}
        leftBorder={"0px"}
        rightBorder={"0px"}
        backgroudColor={Colors.white}
      />
      <div
        className="confirmation-button"
        onClick={() => {
          navigate(-1);
        }}
      >
        <Button
          name={"Ok"}
          backGroundColor={Colors.blue}
          width={"100px"}
          height={"30px"}
        />
      </div>
    </div>
  );
};

export default SaveConformation;
